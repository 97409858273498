import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  Button,
} from 'antd';

const ModalComponent = ({
  isVisible,
  title,
  content,
  onModalClose,
  onModalConfirmed,
  maskClosable,
}) => {
  const [isModalShow, setIsModalShow] = useState(isVisible);
  useEffect(() => {
    setIsModalShow(isVisible);
  }, [isVisible]);
  const handleCloseModal = () => {
    setIsModalShow(false);
    if (onModalClose) onModalClose();
  };
  const handleConfirmModal = () => {
    if (onModalConfirmed) onModalConfirmed();
    else handleCloseModal();
  };
  return (
    <Modal
      visible={isModalShow}
      title={title}
      onCancel={handleCloseModal}
      maskClosable={maskClosable}
      footer={[
        <Button
          key="Accept"
          onClick={handleConfirmModal}
        >
          確認
        </Button>,
      ]}
    >
      {content}
    </Modal>
  );
};

ModalComponent.propTypes = {
  isVisible: PropTypes.bool,
  title: PropTypes.string,
  content: PropTypes.node,
  onModalClose: PropTypes.func,
  onModalConfirmed: PropTypes.func,
  maskClosable: PropTypes.bool,
};

ModalComponent.defaultProps = {
  isVisible: false,
  title: null,
  content: null,
  onModalClose: null,
  onModalConfirmed: null,
  maskClosable: true,
};

export default ModalComponent;
