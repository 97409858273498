import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ActionCreators from '../../ActionCreator';
import CommentManagementPage from './CommentManagementPage';
import {
  reduxStatusSelector,
  reduxErrorSelector,
  commentListSelector,
} from '../../selector';


const CommentManagementPageContainer = ({
  reduxStatus,
  reduxError,
  actions: {
    getCommentListFlowRequest,
    searchFlowRequest,
    updateCommentInfoFlowRequest,
  },
  data,
}) => {
  useEffect(() => {
    getCommentListFlowRequest();
  }, []);

  const [selectedMember, setSelectedMember] = useState(null);
  const [selectedBag, setSelectedBag] = useState(null);
  const [contentFilter, setContentFilter] = useState('');
  const [page, setPage] = useState(0);

  const handleSearch = () => {
    setPage(0);
    searchFlowRequest({
      keyword: contentFilter,
      page: 0,
      commentByUserId: selectedMember ? selectedMember.id : null,
      bagId: selectedBag ? selectedBag.id : null,
    });
  };
  const handleLoadMore = () => {
    const newPage = page + 1;
    setPage(newPage);
    searchFlowRequest({
      keyword: contentFilter,
      page: newPage,
    });
  };

  const onSelectedMemberChanged = (member) => {
    setSelectedMember(member);
  };

  const onSelectedBagChanged = (bag) => {
    setSelectedBag(bag);
  };

  const resetFilterCondition = () => {
    setSelectedMember(null);
    setSelectedBag(null);
    setContentFilter('');
  };

  const onContentFilterChanged = (event) => {
    setContentFilter(event.target.value);
  };

  return (
    <CommentManagementPage
      reduxStatus={reduxStatus}
      reduxError={reduxError}
      data={data}
      selectedMember={selectedMember}
      selectedBag={selectedBag}
      contentFilter={contentFilter}
      onSearch={handleSearch}
      handleLoadMore={handleLoadMore}
      updateCommentInfo={updateCommentInfoFlowRequest}
      handleMemberChanged={onSelectedMemberChanged}
      handleBagChanged={onSelectedBagChanged}
      resetFilterCondition={resetFilterCondition}
      onContentFilterChanged={onContentFilterChanged}
    />
  );
};

CommentManagementPageContainer.propTypes = {
  reduxStatus: PropTypes.string,
  reduxError: PropTypes.string,
  actions: PropTypes.shape({
    getCommentListFlowRequest: PropTypes.func.isRequired,
    searchFlowRequest: PropTypes.func.isRequired,
    updateCommentInfoFlowRequest: PropTypes.func.isRequired,
  }).isRequired,
  data: PropTypes.array,
};

CommentManagementPageContainer.defaultProps = {
  reduxStatus: null,
  reduxError: null,
  data: [],
};

const mapStateToProps = state => ({
  reduxStatus: reduxStatusSelector(state),
  reduxError: reduxErrorSelector(state),
  data: commentListSelector(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    ...ActionCreators,
  }, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CommentManagementPageContainer);
