import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as R from 'ramda';
import ActionCreators from '../../ActionCreator';
import ReduceDepositManagementPage from './ReduceDepositmanagementPage';
import {
  reduxStatusSelector,
  reduxErrorSelector,
  reduceDepositListSelector,
} from '../../selector';

const ReduceDepositManagementPageContainer = ({
  reduxStatus,
  reduxError,
  actions: {
    getReduceDepositListFlowRequest,
    createReduceDepositFlowRequest,
    deleteReduceDepositFlowRequest,
  },
  data,
}) => {
  useEffect(() => {
    getReduceDepositListFlowRequest();
  }, []);

  const search = (value) => {
    getReduceDepositListFlowRequest(value);
  };

  const createReduceDeposit = (createData) => {
    createReduceDepositFlowRequest({
      ownerId: R.pathOr(0, ['bag', 'ownerId'], createData),
      userId: R.pathOr(0, ['user', 'id'], createData),
      bagId: R.pathOr(0, ['bag', 'id'], createData),
    });
  };

  const deleteReduceDeposit = (deleteData) => {
    deleteReduceDepositFlowRequest(R.path(['id'], deleteData));
  };

  return (
    <ReduceDepositManagementPage
      reduxStatus={reduxStatus}
      reduxError={reduxError}
      data={data}
      search={search}
      createReduceDeposit={createReduceDeposit}
      deleteReduceDeposit={deleteReduceDeposit}
    />
  );
};

ReduceDepositManagementPageContainer.propTypes = {
  reduxStatus: PropTypes.string,
  reduxError: PropTypes.string,
  actions: PropTypes.shape({
    getReduceDepositListFlowRequest: PropTypes.func.isRequired,
    createReduceDepositFlowRequest: PropTypes.func.isRequired,
    deleteReduceDepositFlowRequest: PropTypes.func.isRequired,
  }).isRequired,
  data: PropTypes.array,
};

ReduceDepositManagementPageContainer.defaultProps = {
  reduxStatus: null,
  reduxError: null,
  data: [],
};

const mapStateToProps = state => ({
  reduxStatus: reduxStatusSelector(state),
  reduxError: reduxErrorSelector(state),
  data: reduceDepositListSelector(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    ...ActionCreators,
  }, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReduceDepositManagementPageContainer);
