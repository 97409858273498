import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ActionCreators from '../../ActionCreator';
import MarqueeManagementPage from './MarqueeManagementPage';
import {
  reduxStatusSelector,
  reduxErrorSelector,
  MarqueeListSelector,
} from '../../selector';

const MarqueeManagementPageContainer = ({
  reduxStatus,
  reduxError,
  actions: {
    getMarqueeListFlowRequest,
    updateMarqueeInfoFlowRequest,
    resetStatus,
  },
  data,
}) => {
  useEffect(() => {
    if (!data || Object.keys(data).length === 0) getMarqueeListFlowRequest();
    else resetStatus();
    return resetStatus;
  }, []);

  return (
    <MarqueeManagementPage
      reduxStatus={reduxStatus}
      reduxError={reduxError}
      data={data.map(item => ({
        id: item.id,
        text: decodeURIComponent(item.text),
      }))}
      updateMarqueeInfo={updateMarqueeInfoFlowRequest}
    />
  );
};

MarqueeManagementPageContainer.propTypes = {
  reduxStatus: PropTypes.string,
  reduxError: PropTypes.string,
  actions: PropTypes.shape({
    getMarqueeListFlowRequest: PropTypes.func.isRequired,
    deleteMarqueeFlowRequest: PropTypes.func.isRequired,
    updateMarqueeInfoFlowRequest: PropTypes.func.isRequired,
    createMarqueeFlowRequest: PropTypes.func.isRequired,
    resetStatus: PropTypes.func.isRequired,
  }).isRequired,
  data: PropTypes.shape([]),
};

MarqueeManagementPageContainer.defaultProps = {
  reduxStatus: null,
  reduxError: null,
  data: [],
};

const mapStateToProps = state => ({
  reduxStatus: reduxStatusSelector(state),
  reduxError: reduxErrorSelector(state),
  data: MarqueeListSelector(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    ...ActionCreators,
  }, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MarqueeManagementPageContainer);
