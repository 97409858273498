import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';
import ActionCreator from './ActionCreator';
import API from './API';

const {
  getMemberListRequest,
  getMemberListSuccess,
  getMemberListFailure,
  getMemberNumbersRequest,
  getMemberNumbersSuccess,
  getMemberNumbersFailure,
  searchByKeywordRequest,
  searchByKeywordSuccess,
  searchByKeywordFailure,
  updateMemberInfoRequest,
  updateMemberInfoSuccess,
  updateMemberInfoFailure,

  getMemberListFlowRequest,
  getMemberListFlowSuccess,
  getMemberListFlowFailure,
  getMemberNumbersFlowRequest,
  getMemberNumbersFlowSuccess,
  getMemberNumbersFlowFailure,
  searchByKeywordFlowRequest,
  searchByKeywordFlowSuccess,
  searchByKeywordFlowFailure,
  updateMemberInfoFlowRequest,
  updateMemberInfoFlowSuccess,
  updateMemberInfoFlowFailure,
} = ActionCreator;

// ---------- TASKS ----------
function* getMemberList() {
  yield put(getMemberListRequest());
  try {
    const result = yield call(API.getMemberList);
    yield put(getMemberListSuccess(result.data));
    return result;
  } catch (error) {
    yield put(getMemberListFailure(error));
    throw (error);
  }
}

function* getMemberNumbers() {
  yield put(getMemberNumbersRequest());
  try {
    const result = yield call(API.getMemberNumbers);
    yield put(getMemberNumbersSuccess(result.data.count));
    return result;
  } catch (error) {
    yield put(getMemberNumbersFailure(error));
    throw (error);
  }
}

function* searchByKeyword(keyword) {
  yield put(searchByKeywordRequest());
  try {
    const result = yield call(API.getMemberList, {
      where: {
        or: [
          {
            name: {
              like: `%${keyword}%`,
            },
          },
          {
            email: {
              like: `%${keyword}%`,
            },
          },
        ],
      },
    });
    yield put(searchByKeywordSuccess(result.data));
    return result;
  } catch (error) {
    yield put(searchByKeywordFailure(error));
    throw (error);
  }
}

function* updateMemberInfo(data) {
  yield put(updateMemberInfoRequest());
  try {
    const result = yield call(API.updateMemberInfo, data);
    yield put(updateMemberInfoSuccess(result.data));
    return result;
  } catch (error) {
    yield put(updateMemberInfoFailure(error));
    throw (error);
  }
}


// ---------- FLOW ----------
export function* getMemberListFlow({ payload }) {
  try {
    const result = yield call(getMemberList, payload);
    yield put(getMemberListFlowSuccess(result.data));
  } catch (error) {
    yield put(getMemberListFlowFailure(error));
  }
}

export function* getMemberNumbersFlow({ payload }) {
  try {
    const result = yield call(getMemberNumbers, payload);
    yield put(getMemberNumbersFlowSuccess(result.data));
  } catch (error) {
    yield put(getMemberNumbersFlowFailure(error));
  }
}

export function* searchByKeywordFlow({ payload }) {
  try {
    const result = yield call(searchByKeyword, payload);
    yield put(searchByKeywordFlowSuccess(result.data));
  } catch (error) {
    yield put(searchByKeywordFlowFailure(error));
  }
}

export function* updateMemberInfoFlow({ payload }) {
  try {
    const result = yield call(updateMemberInfo, payload);
    yield put(updateMemberInfoFlowSuccess(result.data));
  } catch (error) {
    yield put(updateMemberInfoFlowFailure(error));
  }
}

export default [
  takeLatest(getMemberListFlowRequest, getMemberListFlow),
  takeLatest(getMemberNumbersFlowRequest, getMemberNumbersFlow),
  takeLatest(searchByKeywordFlowRequest, searchByKeywordFlow),
  takeLatest(updateMemberInfoFlowRequest, updateMemberInfoFlow),
];
