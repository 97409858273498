import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';
import * as R from 'ramda';
import ActionCreators from './ActionCreators';
import API from './API';
import axios from '../../lib/configAxios';
import * as LocalStorageUtils from '../../utils/LocalStorageUtils';
import LocalStorageKeys from '../../constants/LocalStorageKeys';

const {
  userSignInRequest,
  userSignInSuccess,
  userSignInFailure,
  userSignOutRequest,
  userSignOutSuccess,
} = ActionCreators;

// ---------- TASKS ----------
function* userSignInTask(payload) {
  try {
    const {
      email,
      password,
    } = payload;
    const result = yield call(API.login, {
      email,
      password,
    });
    return result;
  } catch (error) {
    throw (error);
  }
}

function* userSignOutTask() {
  try {
    const result = yield call(API.logout);
    return result;
  } catch (error) {
    throw (error);
  }
}

// ---------- FLOW ----------
export function* userSignInFlow({ payload }) {
  try {
    const result = yield call(userSignInTask, payload);
    const token = R.pathOr(null, ['data', 'accessToken', 'id'], result);
    const userId = R.pathOr(null, ['data', 'accessToken', 'userId'], result);

    axios.setToken(token);
    LocalStorageUtils.writeData(LocalStorageKeys.userToken, token);
    LocalStorageUtils.writeData(LocalStorageKeys.userId, userId);

    yield put(userSignInSuccess({
      userId,
      token,
    }));
  } catch (error) {
    yield put(userSignInFailure(error));
  }
}

export function* userSignOutFlow() {
  try {
    yield call(userSignOutTask);
  } catch (error) {
    console.error(error); // eslint-disable-line no-console
  }
  try {
    axios.setToken(null);
    LocalStorageUtils.removeData(LocalStorageKeys.userToken);
    LocalStorageUtils.removeData(LocalStorageKeys.userId);
  } catch (error) {
    console.error(error);// eslint-disable-line no-console
  }
  yield put(userSignOutSuccess());
}

export default [
  takeLatest(userSignInRequest, userSignInFlow),
  takeLatest(userSignOutRequest, userSignOutFlow),
];
