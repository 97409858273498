import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import FormTableComponent from '../../../../components/FormTable/FormTableComponent';
import InputDialog from '../../../../components/InputDialog';

const WithdrawInfoComponent = ({
  withdrawInfo,
  updateWithdrawInfo,
  cancelSelectWithdraw,
  deleteWithdraw,
}) => {
  const [showDeleteConfirmDialog, setShowDeleteConfirmDialog] = useState(false);

  const renderTableRows = (renderField, renderSeparator) => [
    renderField({
      key: ['order', 'type', 'name'],
      name: '交易型態',
      disabled: true,
    }),
    renderField({ key: 'orderId', name: '訂單編號', disabled: true }),
    renderField({
      key: 'requestDate',
      name: '發起日期',
      type: 'date',
      disabled: true,
    }),
    renderField({ key: ['order', 'ownerEarnedAmount'], name: '領取金額', disabled: true }),
    renderField({ key: ['user', 'payeeName'], name: '收款人姓名', disabled: true }),
    renderField({ key: ['user', 'email'], name: '收款人信箱', disabled: true }),
    renderField({ key: ['user', 'payeeBankCode'], name: '收款人銀行代碼', disabled: true }),
    renderField({ key: ['user', 'payeeBankBranch'], name: '收款人銀行分行', disabled: true }),
    renderField({ key: ['user', 'payeeBankAccount'], name: '收款人銀行帳號', disabled: true }),
    renderSeparator(1),
    renderField({
      key: 'isRemit',
      name: '是否匯款',
      type: 'select',
      options: [{ id: false, name: '否' }, { id: true, name: '是' }],
    }),
  ];
  return [
    <FormTableComponent
      key="FormTableComponent"
      data={withdrawInfo}
      updateData={updateWithdrawInfo}
      cancelSelection={cancelSelectWithdraw}
      renderTableRows={renderTableRows}
      deleteData={() => setShowDeleteConfirmDialog(true)}
    />,
    <InputDialog
      key="deleteConfirmDialog"
      title="如果真的要刪除，請輸入您的密碼"
      inputProps={{ type: 'password' }}
      isVisible={showDeleteConfirmDialog}
      onSubmit={(value) => {
        setShowDeleteConfirmDialog(false);
        if (value) {
          deleteWithdraw({
            id: R.path(['id'], withdrawInfo),
            password: value,
          });
        }
      }}
    />,
  ];
};

WithdrawInfoComponent.propTypes = {
  withdrawInfo: PropTypes.shape({}),
  updateWithdrawInfo: PropTypes.func.isRequired,
  cancelSelectWithdraw: PropTypes.func.isRequired,
  deleteWithdraw: PropTypes.func.isRequired,
};

WithdrawInfoComponent.defaultProps = {
  withdrawInfo: null,
};

export default WithdrawInfoComponent;
