import api from '../../lib/configAxios';

export default {
  getMemberList: async (filter = { order: 'id DESC' }) => api.get(
    `Users?filter=${encodeURIComponent(JSON.stringify(filter))}`,
  ),

  updateMemberInfo: async data => api.patch(
    `Users/${data.id}`, data,
  ),

  getMemberNumbers: async filter => api.get(
    `Users/count?filter=${encodeURIComponent(JSON.stringify(filter))}`,
  ),
};
