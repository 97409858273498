import * as R from 'ramda';
import { createSelector } from 'reselect';
import { withdrawStateSelector } from '../../lib/rootSelector';

export const reduxStatusSelector = createSelector(
  withdrawStateSelector,
  result => R.path(['reduxStatus'], result),
);

export const reduxErrorSelector = createSelector(
  withdrawStateSelector,
  result => R.path(['error', 'message'], result),
);

export const withdrawListSelector = createSelector(
  withdrawStateSelector,
  result => R.path(['data'], result),
);

export const unRemitNumberSelector = createSelector(
  withdrawStateSelector,
  result => R.path(['unRemitNumber'], result),
);
