import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Upload,
  Icon,
  Button,
} from 'antd';
import API from '../API';

const Uploader = ({
  onFileUploaded,
}) => {
  const [isUploading, setIsUploading] = useState(false);

  const beginUpload = async (file) => {
    setIsUploading(true);

    const formData = new FormData();
    formData.append('file', file);
    const result = await API.uploadFile(formData);
    setIsUploading(false);
    if (result.status === 200) {
      onFileUploaded(result.data);
    }
    return false;
  };

  return (
    <Upload
      accept="image/*"
      fileList={[]}
      beforeUpload={(file) => {
        beginUpload(file);
        return false;
      }}
      disabled={isUploading}
    >
      <Button>
        {isUploading ? <Icon type="loading" /> : <Icon type="upload" /> }
      </Button>
    </Upload>
  );
};

Uploader.propTypes = {
  onFileUploaded: PropTypes.func.isRequired,
};

Uploader.defaultProps = {
};

export default Uploader;
