import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from '@reach/router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  reduxStatusSelector,
  authTokenSelector,
} from '../../selector';
import ActionCreators from '../../ActionCreators';
import Paths from '../../../../routes/Paths';
import LogoutScreen from './LogoutScreen';

const {
  SIGN_IN,
} = Paths;

const LogoutContainer = ({
  actions,
  reduxStatus,
  token,
}) => {
  useEffect(() => {
    const {
      userSignOutRequest,
    } = actions;
    userSignOutRequest();
  }, []);

  if (!token) {
    return (
      <Redirect
        from="."
        to={SIGN_IN}
        noThrow
      />
    );
  }
  return (
    <LogoutScreen reduxStatus={reduxStatus} />
  );
};

LogoutContainer.propTypes = {
  reduxStatus: PropTypes.string,
  token: PropTypes.string,
  actions: PropTypes.shape({
    userSignOutRequest: PropTypes.func.isRequired,
  }).isRequired,
};

LogoutContainer.defaultProps = {
  reduxStatus: null,
  token: null,
};

const mapStateToProps = state => ({
  reduxStatus: reduxStatusSelector(state),
  token: authTokenSelector(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    ...ActionCreators,
  }, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LogoutContainer);
