import React from 'react';
import PropTypes from 'prop-types';

const styles = {
  logo: {
    textAlign: 'center',
    position: 'relative',
    color: 'white',
    fontSize: '40px',
    width: '100%',
    height: '100%',
  },
  logoText: {
    width: '100%',
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    fontStyle: 'italic',
    fontFamily: 'Impact',
    fontWeight: 'bold',
  },
};

const Logo = ({
  style = {},
}) => (
  <div style={{
    ...styles.logo,
    ...style,
  }}
  >
    <div style={styles.logoText}>
      Oh! Phire
    </div>
  </div>
);


Logo.propTypes = {
  style: PropTypes.object,
};

Logo.defaultProps = {
  style: {},
};


export default Logo;
