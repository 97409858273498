import api from '../../lib/configAxios';

export default {
  getReduceDepositList: async filter => api.get(
    `ReduceDeposits?filter=${encodeURIComponent(JSON.stringify(filter))}`,
  ),

  createReduceDeposit: async data => api.post(
    'ReduceDeposits', data,
  ),

  deleteReduceDeposit: async id => api.delete(
    `ReduceDeposits/${id}`,
  ),
};
