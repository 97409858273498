import { combineReducers } from 'redux';
import auth from '../modules/Auth/reducer';
import memberManagement from '../modules/MemberManagement/reducer';
import tagManagement from '../modules/TagManagement/reducer';
import bagManagement from '../modules/BagManagement/reducer';
import orderManagement from '../modules/OrderManagement/reducer';
import contactManagement from '../modules/ContactManagement/reducer';
import subscribeManagement from '../modules/SubscribeManagement/reducer';
import cartManagement from '../modules/CartManagement/reducer';
import themeManagement from '../modules/ThemeManagement/reducer';
import commentManagement from '../modules/CommentManagement/reducer';
import negotiation from '../modules/NegotiationManagement/reducer';
import reduceDepositManagement from '../modules/ReduceDepositManagement/reducer';
import withdrawManagement from '../modules/WithdrawManagement/reducer';
import clothesManagement from '../modules/ClothesManagement/reducer';
import payByTokenManagement from '../modules/PayByTokenManagement/reducer';
import couponManagement from '../modules/CouponManagement/reducer';
import addressManagement from '../modules/AddressManagement/reducer';
import MarqueeManagement from '../modules/MarqueeManagement/reducer';
import IdentificationManagement from '../modules/IdentificationManagement/reducer';

const appReducer = combineReducers({
  auth,
  memberManagement,
  tagManagement,
  bagManagement,
  orderManagement,
  contactManagement,
  subscribeManagement,
  cartManagement,
  themeManagement,
  commentManagement,
  negotiation,
  reduceDepositManagement,
  withdrawManagement,
  clothesManagement,
  payByTokenManagement,
  couponManagement,
  addressManagement,
  MarqueeManagement,
  IdentificationManagement,
});

// NOTE: reset reducers after sign out.
const rootReducer = (state, action) => (appReducer(state, action));

export default rootReducer;
