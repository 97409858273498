import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ActionCreators from '../../ActionCreator';
import DashboardPage from './DashboardPage';
import {
  reduxStatusSelector,
  reduxErrorSelector,
} from '../../selector';

const DashboardPageContainer = ({
  reduxStatus,
  reduxError,
}) => (
  <DashboardPage
    reduxStatus={reduxStatus}
    reduxError={reduxError}
  />
);

DashboardPageContainer.propTypes = {
  reduxStatus: PropTypes.string,
  reduxError: PropTypes.string,
  actions: PropTypes.shape({
  }).isRequired,
};

DashboardPageContainer.defaultProps = {
  reduxStatus: null,
  reduxError: null,
};

const mapStateToProps = state => ({
  reduxStatus: reduxStatusSelector(state),
  reduxError: reduxErrorSelector(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    ...ActionCreators,
  }, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DashboardPageContainer);
