import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from '@reach/router';
import { Menu } from 'antd';
import * as R from 'ramda';
import {
  SIGN_OUT,
  MEMBER_MANAGEMENT,
  TAG_MANAGEMENT,
  BAG_MANAGEMENT,
  ORDER_MANAGEMENT,
  CONTACT,
  SUBSCRIBE,
  CART_MANAGEMENT,
  THEME_MANAGEMENT,
  COMMENT_MANAGEMENT,
  NEGOTIATION_MANAGEMENT,
  WITHDRAW_MANAGEMENT,
  CLOTHES_MANAGEMENT,
  TAPPAT_PAY_BY_TOKEN_MANAGEMENT,
  COUPON_MANAGEMENT,
  ACCESSORIES_MANAGEMENT,
  ESSENCE_MANAGEMENT,
  ADDRESS_MANAGEMENT,
  MARQUEE_MANAGEMENT,
  IDENTIFICATION_MANAGEMENT,
} from './SiderOptionsNamespaces';
import Paths from '../../../routes/Paths';
import Logo from '../../Logo';

const styles = {
  pageChoiceStyle: {
    margin: '12px 0',
    fontSize: 20,
  },
  logo: {
    height: '40px',
    fontSize: '30px',
  },
};

const menus = [
  {
    path: Paths.MARQUEE_MANAGEMENT,
    title: MARQUEE_MANAGEMENT,
  },
  {
    path: Paths.MEMBER_MANAGEMENT,
    title: MEMBER_MANAGEMENT,
  },
  {
    path: Paths.IDENTIFICATION_MANAGEMENT,
    title: IDENTIFICATION_MANAGEMENT,
  },
  {
    path: Paths.BAG_MANAGEMENT,
    title: BAG_MANAGEMENT,
  },
  {
    path: Paths.CLOTHES_MANAGEMENT,
    title: CLOTHES_MANAGEMENT,
  },
  {
    path: Paths.ACCESSORIES_MANAGEMENT,
    title: ACCESSORIES_MANAGEMENT,
  },
  {
    path: Paths.ESSENCE_MANAGEMENT,
    title: ESSENCE_MANAGEMENT,
  },
  {
    path: Paths.THEME_MANAGEMENT,
    title: THEME_MANAGEMENT,
  },
  {
    path: Paths.CART_MANAGEMENT,
    title: CART_MANAGEMENT,
  },
  {
    path: Paths.TAG_MANAGEMENT,
    title: TAG_MANAGEMENT,
  },
  {
    path: Paths.ORDER_MANAGEMENT,
    title: ORDER_MANAGEMENT,
  },
  {
    path: Paths.COMMENT_MANAGEMENT,
    title: COMMENT_MANAGEMENT,
  },
  {
    path: Paths.NEGOTIATION_MANAGEMENT,
    title: NEGOTIATION_MANAGEMENT,
  },
  {
    path: Paths.CONTACT,
    title: CONTACT,
  },
  {
    path: Paths.SUBSCRIBE,
    title: SUBSCRIBE,
  },
  {
    path: Paths.WITHDRAW_MANAGEMENT,
    title: WITHDRAW_MANAGEMENT,
  },
  {
    path: Paths.TAPPAY_PAY_BY_TOKEN_MANAGEMENT,
    title: TAPPAT_PAY_BY_TOKEN_MANAGEMENT,
  },
  {
    path: Paths.COUPON_MANAGEMENT,
    title: COUPON_MANAGEMENT,
  },
  {
    path: Paths.ADDRESS_MANAGEMENT,
    title: ADDRESS_MANAGEMENT,
  },
  {
    path: Paths.SIGN_OUT,
    title: SIGN_OUT,
  },
];


const SiderMenu = ({
  path: currentPath,
}) => {
  const [selectedMenuItem, setSelectedMenuItem] = useState(null);

  // NOTE: Change the menu item when path is changed.
  useEffect(() => {
    const newSelectedMenuItem = R.path(['path'], menus.find(({ path }) => currentPath.startsWith(path)));
    setSelectedMenuItem(newSelectedMenuItem);
  }, [currentPath]);

  return (
    <Menu
      theme="dark"
      selectedKeys={[selectedMenuItem]}
      mode="vertical"
    >
      <Menu.Item
        key="Logo"
        style={styles.pageChoiceStyle}
      >
        <Link to={Paths.DASHBOARD}>
          <Logo style={styles.logo} />
        </Link>
      </Menu.Item>

      {
      menus.map(({ path, title }) => (
        <Menu.Item
          key={path}
          style={styles.pageChoiceStyle}
        >
          <Link to={path}>
            {title}
          </Link>
        </Menu.Item>
      ))
    }
    </Menu>
  );
};

SiderMenu.propTypes = {
  path: PropTypes.string,
};

SiderMenu.defaultProps = {
  path: null,
};

export default SiderMenu;
