import {
  call,
  put,
  takeLatest,
  all,
} from 'redux-saga/effects';
import ActionCreator from './ActionCreator';
import API from './API';

const {
  getThemeListRequest,
  getThemeListSuccess,
  getThemeListFailure,
  createThemeRequest,
  createThemeSuccess,
  createThemeFailure,
  updateThemeRequest,
  updateThemeSuccess,
  updateThemeFailure,
  deleteThemeRequest,
  deleteThemeSuccess,
  deleteThemeFailure,
  getThemeBagListRequest,
  getThemeBagListSuccess,
  getThemeBagListFailure,
  linkThemeBagRequest,
  linkThemeBagSuccess,
  linkThemeBagFailure,
  unlinkThemeBagRequest,
  unlinkThemeBagSuccess,
  unlinkThemeBagFailure,

  getThemeListFlowRequest,
  getThemeListFlowSuccess,
  getThemeListFlowFailure,
  createThemeFlowRequest,
  createThemeFlowSuccess,
  createThemeFlowFailure,
  updateThemeFlowRequest,
  updateThemeFlowSuccess,
  updateThemeFlowFailure,
  deleteThemeFlowRequest,
  deleteThemeFlowSuccess,
  deleteThemeFlowFailure,
  getThemeBagListFlowRequest,
  getThemeBagListFlowSuccess,
  getThemeBagListFlowFailure,
  linkThemeBagFlowRequest,
  linkThemeBagFlowSuccess,
  linkThemeBagFlowFailure,
  unlinkThemeBagFlowRequest,
  unlinkThemeBagFlowSuccess,
  unlinkThemeBagFlowFailure,
} = ActionCreator;


// ---------- TASKS ----------
function* getThemeList() {
  try {
    yield put(getThemeListRequest());
    const result = yield call(API.getThemeList);
    yield put(getThemeListSuccess(result.data));
    return result;
  } catch (error) {
    yield put(getThemeListFailure(error));
    throw (error);
  }
}

function* createTheme(data) {
  try {
    yield put(createThemeRequest());
    const result = yield call(API.createTheme, data);
    yield put(createThemeSuccess(result.data));
    return result;
  } catch (error) {
    yield put(createThemeFailure(error));
    throw (error);
  }
}

function* updateTheme(data) {
  try {
    yield put(updateThemeRequest());
    const {
      resource,
      ...restOfData
    } = data;
    if (resource) {
      Object.assign(restOfData, {
        resourceId: resource.id,
      });
    }

    const result = yield call(API.updateTheme, restOfData);
    yield put(updateThemeSuccess(result.data));
    return result;
  } catch (error) {
    yield put(updateThemeFailure(error));
    throw (error);
  }
}

function* deleteTheme(data) {
  try {
    yield put(deleteThemeRequest());
    const result = yield call(API.deleteTheme, data);
    yield put(deleteThemeSuccess(result.data));
    return result;
  } catch (error) {
    yield put(deleteThemeFailure(error));
    throw (error);
  }
}

function* getThemeBagList(themeId) {
  try {
    yield put(getThemeBagListRequest(themeId));
    const result = yield call(API.getThemeBagList, themeId);
    yield put(getThemeBagListSuccess(result.data));
    return result;
  } catch (error) {
    yield put(getThemeBagListFailure(error));
    throw (error);
  }
}

function* linkThemeBag({
  themeId,
  bags,
}) {
  try {
    yield put(linkThemeBagRequest({
      themeId,
      bags,
    }));
    yield all(bags.map(bag => call(API.linkThemeBag, {
      themeId,
      bagId: bag.id,
    })));
    yield put(linkThemeBagSuccess({
      themeId,
      bags,
    }));
  } catch (error) {
    yield put(linkThemeBagFailure(error));
    throw (error);
  }
}

function* unlinkThemeBag({
  themeId,
  bagId,
}) {
  try {
    yield put(unlinkThemeBagRequest({
      themeId,
      bagId,
    }));
    const result = yield call(API.unlinkThemeBag, {
      themeId,
      bagId,
    });
    yield put(unlinkThemeBagSuccess({
      themeId,
      bagId,
    }));
    return result;
  } catch (error) {
    yield put(unlinkThemeBagFailure(error));
    throw (error);
  }
}

// ---------- FLOW ----------
export function* getThemeListFlow({ payload }) {
  try {
    const result = yield call(getThemeList, payload);
    yield put(getThemeListFlowSuccess({
      data: result.data,
    }));
  } catch (error) {
    yield put(getThemeListFlowFailure(error));
  }
}

export function* createThemeFlow({ payload }) {
  try {
    const result = yield call(createTheme, payload);
    yield put(createThemeFlowSuccess({
      data: result.data,
    }));
    yield put(getThemeListFlowRequest({}));
  } catch (error) {
    yield put(createThemeFlowFailure(error));
  }
}

export function* updateThemeFlow({ payload }) {
  try {
    const result = yield call(updateTheme, payload);
    yield put(updateThemeFlowSuccess({
      data: result.data,
    }));
    yield put(getThemeListFlowRequest({}));
  } catch (error) {
    yield put(updateThemeFlowFailure(error));
  }
}

export function* deleteThemeFlow({ payload }) {
  try {
    const result = yield call(deleteTheme, payload);
    yield put(deleteThemeFlowSuccess({
      data: result.data,
    }));
    yield put(getThemeListFlowRequest({}));
  } catch (error) {
    yield put(deleteThemeFlowFailure(error));
  }
}

export function* getThemeBagListFlow({ payload }) {
  try {
    const result = yield call(getThemeBagList, payload);
    yield put(getThemeBagListFlowSuccess({
      data: result.data,
    }));
  } catch (error) {
    yield put(getThemeBagListFlowFailure(error));
  }
}

export function* linkThemeBagFlow({ payload }) {
  try {
    const result = yield call(linkThemeBag, payload);
    yield put(linkThemeBagFlowSuccess(result));
  } catch (error) {
    yield put(linkThemeBagFlowFailure(error));
  }
}

export function* unlinkThemeBagFlow({ payload }) {
  try {
    const result = yield call(unlinkThemeBag, payload);
    yield put(unlinkThemeBagFlowSuccess({
      data: result.data,
    }));
  } catch (error) {
    yield put(unlinkThemeBagFlowFailure(error));
  }
}

export default [
  takeLatest(getThemeListFlowRequest, getThemeListFlow),
  takeLatest(createThemeFlowRequest, createThemeFlow),
  takeLatest(updateThemeFlowRequest, updateThemeFlow),
  takeLatest(deleteThemeFlowRequest, deleteThemeFlow),
  takeLatest(getThemeBagListFlowRequest, getThemeBagListFlow),
  takeLatest(linkThemeBagFlowRequest, linkThemeBagFlow),
  takeLatest(unlinkThemeBagFlowRequest, unlinkThemeBagFlow),
];
