import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ActionCreators from '../../ActionCreator';
import CouponManagementPage from './CouponManagement';
import {
  reduxStatusSelector,
  reduxErrorSelector,
  couponListSelector,
} from '../../selector';

import { memberListSelector } from '../../../MemberManagement/selector';

const CouponManagementPageContainer = ({
  reduxStatus,
  reduxError,
  couponList,
  memberList,
  actions: {
    getCouponListFlowRequest,
    createCouponFlowRequest,
    updateCouponFlowRequest,
    deleteCouponFlowRequest,
  },
}) => {
  useEffect(() => {
    getCouponListFlowRequest();
  }, []);

  return (
    <CouponManagementPage
      memberList={memberList}
      reduxStatus={reduxStatus}
      reduxError={reduxError}
      couponList={couponList}
      updateCouponInfo={updateCouponFlowRequest}
      deleteCoupon={deleteCouponFlowRequest}
      createCoupon={createCouponFlowRequest}
    />
  );
};

CouponManagementPageContainer.propTypes = {
  reduxStatus: PropTypes.string,
  reduxError: PropTypes.string,
  actions: PropTypes.shape({
    getCouponListFlowRequest: PropTypes.func.isRequired,
    createCouponFlowRequest: PropTypes.func.isRequired,
    updateCouponFlowRequest: PropTypes.func.isRequired,
    deleteCouponFlowRequest: PropTypes.func.isRequired,
  }).isRequired,
  couponList: PropTypes.array,
  memberList: PropTypes.array,
};

CouponManagementPageContainer.defaultProps = {
  reduxStatus: null,
  reduxError: null,
  couponList: [],
  memberList: [],
};

const mapStateToProps = state => ({
  reduxStatus: reduxStatusSelector(state),
  reduxError: reduxErrorSelector(state),
  couponList: couponListSelector(state),
  memberList: memberListSelector(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    ...ActionCreators,
  }, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CouponManagementPageContainer);
