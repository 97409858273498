import * as R from 'ramda';
import { createSelector } from 'reselect';
import { cartManagementStateSelector } from '../../lib/rootSelector';

export const cartReduxStatusSelector = createSelector(
  cartManagementStateSelector,
  result => R.path(['reduxStatus'], result),
);

export const cartReduxErrorSelector = createSelector(
  cartManagementStateSelector,
  result => R.path(['error', 'message'], result),
);


export const cartListSelector = createSelector(
  cartManagementStateSelector,
  result => R.path(['data'], result),
);
