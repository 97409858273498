import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';
import * as R from 'ramda';
import ActionCreator from './ActionCreator';
import API from './API';

const {
  getIdentificationListRequest,
  getIdentificationListSuccess,
  getIdentificationListFailure,
  getIdentificationNumbersRequest,
  getIdentificationNumbersSuccess,
  getIdentificationNumbersFailure,
  searchByKeywordRequest,
  searchByKeywordSuccess,
  searchByKeywordFailure,
  updateIdentificationInfoRequest,
  updateIdentificationInfoSuccess,
  updateIdentificationInfoFailure,

  getIdentificationListFlowRequest,
  getIdentificationListFlowSuccess,
  getIdentificationListFlowFailure,
  getIdentificationNumbersFlowRequest,
  getIdentificationNumbersFlowSuccess,
  getIdentificationNumbersFlowFailure,
  searchByKeywordFlowRequest,
  searchByKeywordFlowSuccess,
  searchByKeywordFlowFailure,
  updateIdentificationInfoFlowRequest,
  updateIdentificationInfoFlowSuccess,
  updateIdentificationInfoFlowFailure,
} = ActionCreator;


const buildFilterClause = (filterCondition = {}) => {
  try {
    const {
      keyword,
      tags,
      page = 0,
      sortOrder,
      ...otherCondition
    } = filterCondition;

    const pageLimit = 10;

    const where = {};


    if (keyword) {
      Object.assign(where, {
        name: {
          like: `%${keyword}%`,
        },
      });
    }


    if (otherCondition && Object.keys(otherCondition).length > 0) {
      Object.assign(where, otherCondition);
    }

    const assignedFilterCondition = {
      limit: pageLimit,
      skip: pageLimit * page,
      order: 'id DESC',
      include: [
        {
          relation: 'cardFront',
          scope: { fields: 'uri' },
        },
        {
          relation: 'cardBack',
          scope: { fields: 'uri' },
        },
        {
          relation: 'video',
          scope: { fields: 'uri' },
        },
      ],
      ...(R.isEmpty(where) ? {} : { where }),
    };

    return assignedFilterCondition;
  } catch (error) {
    // eslint-disable-next-line
    console.error(error);
    return {};
  }
};

// ---------- TASKS ----------
function* getIdentificationList(filterCondition = {}) {
  yield put(getIdentificationListRequest());
  try {
    const result = yield call(API.getIdentificationList, buildFilterClause(filterCondition));
    yield put(getIdentificationListSuccess(result.data));
    return result;
  } catch (error) {
    yield put(getIdentificationListFailure(error));
    throw (error);
  }
}

function* getIdentificationNumbers(filterCondition = {}) {
  yield put(getIdentificationNumbersRequest());
  try {
    const result = yield call(API.getIdentificationNumbers, filterCondition);
    yield put(getIdentificationNumbersSuccess(result.data.count));
    return result;
  } catch (error) {
    yield put(getIdentificationNumbersFailure(error));
    throw (error);
  }
}

function* searchByKeyword(keyword) {
  yield put(searchByKeywordRequest());
  try {
    const result = yield call(API.getIdentificationList, {
      where: {
        or: [
          {
            name: {
              like: `%${keyword}%`,
            },
          },
          {
            email: {
              like: `%${keyword}%`,
            },
          },
        ],
      },
    });
    yield put(searchByKeywordSuccess(result.data));
    return result;
  } catch (error) {
    yield put(searchByKeywordFailure(error));
    throw (error);
  }
}

function* updateIdentificationInfo(data) {
  yield put(updateIdentificationInfoRequest());
  try {
    const result = yield call(API.updateIdentificationInfo, data);
    yield put(updateIdentificationInfoSuccess(result.data));
    return result;
  } catch (error) {
    yield put(updateIdentificationInfoFailure(error));
    throw (error);
  }
}


// ---------- FLOW ----------
export function* getIdentificationListFlow({ payload }) {
  try {
    const result = yield call(getIdentificationList, payload);
    yield put(getIdentificationListFlowSuccess(result.data));
  } catch (error) {
    yield put(getIdentificationListFlowFailure(error));
  }
}

export function* getIdentificationNumbersFlow({ payload }) {
  try {
    const result = yield call(getIdentificationNumbers, payload);
    yield put(getIdentificationNumbersFlowSuccess(result.data));
  } catch (error) {
    yield put(getIdentificationNumbersFlowFailure(error));
  }
}

export function* searchByKeywordFlow({ payload }) {
  try {
    const result = yield call(searchByKeyword, payload);
    yield put(searchByKeywordFlowSuccess(result.data));
  } catch (error) {
    yield put(searchByKeywordFlowFailure(error));
  }
}

export function* updateIdentificationInfoFlow({ payload }) {
  try {
    const result = yield call(updateIdentificationInfo, payload);
    yield put(updateIdentificationInfoFlowSuccess(result.data));
  } catch (error) {
    yield put(updateIdentificationInfoFlowFailure(error));
  }
}

export default [
  takeLatest(getIdentificationListFlowRequest, getIdentificationListFlow),
  takeLatest(getIdentificationNumbersFlowRequest, getIdentificationNumbersFlow),
  takeLatest(searchByKeywordFlowRequest, searchByKeywordFlow),
  takeLatest(updateIdentificationInfoFlowRequest, updateIdentificationInfoFlow),
];
