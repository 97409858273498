import * as R from 'ramda';
import { createSelector } from 'reselect';
import { IdentificationManagementStateSelector } from '../../lib/rootSelector';

export const reduxStatusSelector = createSelector(
  IdentificationManagementStateSelector,
  result => R.path(['reduxStatus'], result),
);

export const reduxErrorSelector = createSelector(
  IdentificationManagementStateSelector,
  result => R.path(['error', 'message'], result),
);


export const identificationListSelector = createSelector(
  IdentificationManagementStateSelector,
  result => R.path(['data'], result),
);

export const identificationNumbersSelector = createSelector(
  IdentificationManagementStateSelector,
  result => R.path(['identificationNumbers'], result),
);
