
const bagLevelId = {
  new: 10,
  classA: 9,
  classB: 8,
  classC: 7,
  classD: 5,
};

const bagLevel = {
  new: '全新(10/10)',
  classA: 'A(9/10)',
  classB: 'B(8/10)',
  classC: 'C(6-7/10)',
  classD: 'D(5/10)',
};

module.exports = {
  bagLevelId,
  bagLevel,
};
