import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from '@reach/router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import LoginScreen from './LoginScreen';
import {
  reduxStatusSelector,
  authTokenSelector,
} from '../../selector';
import ActionCreators from '../../ActionCreators';
import Paths from '../../../../routes/Paths';

const {
  DASHBOARD,
} = Paths;

const LoginContainer = ({
  actions,
  reduxStatus,
  token,
}) => {
  const beginLogin = (email, password) => {
    actions.userSignInRequest({
      email,
      password,
    });
  };
  if (token) {
    return (
      <Redirect
        from="."
        to={DASHBOARD}
        noThrow
      />
    );
  }
  return (
    <LoginScreen
      beginLogin={beginLogin}
      reduxStatus={reduxStatus}
    />
  );
};

LoginContainer.propTypes = {
  reduxStatus: PropTypes.string,
  token: PropTypes.string,
  actions: PropTypes.shape({
    userSignInRequest: PropTypes.func.isRequired,
  }).isRequired,
};

LoginContainer.defaultProps = {
  reduxStatus: null,
  token: null,
};

const mapStateToProps = state => ({
  reduxStatus: reduxStatusSelector(state),
  token: authTokenSelector(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    ...ActionCreators,
  }, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LoginContainer);
