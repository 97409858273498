import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { Button, Modal } from 'antd';
import FormTableComponent from '../../../../components/FormTable/FormTableComponent';
import MemberManagementContainer from '../../../MemberManagement/components/MemberManagementPage';
import BagManagementContainer from '../../../BagManagement/components/BagManagementPage';
import { receiptTypeId } from '../../../../constants/ReceiptType';
import { shippingType, shippingTypeId } from '../../../../constants/ShippingType';
import { zeroCardStatus, zeroCardStatusId } from '../../../../constants/zeroCardStatus';
import Type from '../../../../constants/Type';
import InputDialog from '../../../../components/InputDialog';

const OrderInfoComponent = ({
  orderInfo,
  updateOrderInfo,
  cancelSelectOrder,
  orderStatusList,
  donateOrganizationList,
  receiptTypeList,
  createOrder,
  deleteOrder,
}) => {
  const [showMemberSelector, setShowMemberSelector] = useState(false);
  const [showBagSelector, setShowBagSelector] = useState(false);
  const isCreating = Object.keys(orderInfo || {}).length === 0;
  const [selectReceiptTypeId, setSelectReceiptTypeId] = useState(
    !R.isEmpty(orderInfo) && !R.isNil(orderInfo) ? Number(R.path(['receiptTypeId'], orderInfo))
      : Number(receiptTypeId.eReceipt),
  );

  const [showDeleteConfirmDialog, setShowDeleteConfirmDialog] = useState(false);

  useEffect(() => {
    if (orderInfo !== null) setSelectReceiptTypeId(Number(R.path(['receiptTypeId'], orderInfo)));
  }, [orderInfo]);

  const bagRowMetaData = {
    key: 'bag',
    name: '包款',
    type: 'custom',
    renderChild: (modifiedValue, isModified, handleChange) => [
      <span key="name">
        {modifiedValue ? R.pathOr('(沒有名字)', ['name'], modifiedValue) : R.pathOr('(沒有名字)', ['bag', 'name'], orderInfo)}
      </span>,
      <span key="button"><Button size="small" onClick={() => { setShowBagSelector(true); }}>...</Button></span>,
      <Modal
        key="bagSelector"
        title="選擇包款"
        visible={showBagSelector}
        width="90%"
        onCancel={() => { setShowBagSelector(false); }}
      >
        <BagManagementContainer
          onBagSelected={(bag) => {
            setShowBagSelector(false);
            if (!bag) return;
            handleChange(bag);
          }}
        />
      </Modal>,
    ],
  };

  const userRowMetaData = {
    key: 'user',
    name: '訂購人',
    type: 'custom',
    renderChild: (modifiedValue, isModified, handleChange) => [
      <span key="name">
        {modifiedValue ? R.pathOr('(沒有名字)', ['name'], modifiedValue) : R.pathOr('(沒有名字)', ['user', 'name'], orderInfo)}
        (
        {modifiedValue ? R.pathOr('', ['email'], modifiedValue) : R.pathOr('', ['user', 'email'], orderInfo)}
        )
      </span>,
      <span key="button"><Button size="small" onClick={() => { setShowMemberSelector(true); }}>...</Button></span>,
      <Modal
        key="memberSelector"
        title="選擇會員"
        visible={showMemberSelector}
        onCancel={() => { setShowMemberSelector(false); }}
      >
        <MemberManagementContainer
          onMemberSelected={(member) => {
            setShowMemberSelector(false);
            if (!member) return;
            handleChange(member);
          }}
        />
      </Modal>,
    ],
  };

  const renderTableRows = (renderField, renderSeparator) => [
    renderField({
      key: 'typeId',
      name: '交易型態',
      type: 'select',
      options: [{ id: Type.typeId.rent, name: Type.transactionType.rent }, { id: Type.typeId.buy, name: Type.transactionType.buy }],
    }),
    renderField({
      key: 'commodityTypeId',
      name: '商品類別型態',
      type: 'select',
      options: Object.keys(Type.commodityTypeId)
        .sort((type1, type2) => (Type.commodityTypeId[type1] - Type.commodityTypeId[type2]))
        .map(type => ({
          id: Type.commodityTypeId[type],
          name: Type.commodityType[type],
        })),
    }),
    renderField({ key: 'newId', name: '訂單編號', disabled: true }),
    renderField({ key: ['bag', 'bagNo'], name: '商品編號', disabled: true }),
    renderField({ key: ['bag', 'name'], name: '商品名稱', disabled: true }),
    renderField({ key: 'fromRentOrderId', name: '原租訂單編號', disabled: true }),
    renderField({ key: 'payDepositOrderNo', name: 'TayPay 押金 交易編號', disabled: true }),
    renderField({ key: 'payRentOrBuyOrderNo', name: 'TayPay 租金/買斷 交易編號', disabled: true }),
    renderField({ key: 'cardKey', name: 'TapPay cardKey', disabled: false }),
    renderField({ key: 'cardToken', name: 'TapPay cardToken', disabled: false }),
    renderField(userRowMetaData),
    renderField(bagRowMetaData),
    renderField({ key: 'rentDays', name: '租借天數' }),
    renderField({
      key: 'rentStartDate',
      name: '租期開始日期',
      type: 'date',
    }),
    renderField({
      key: 'rentEndDate',
      name: '租期結束日期',
      type: 'date',
    }),
    renderField({ key: 'delayDays', name: '延遲退還日期' }),
    renderField({
      key: 'shippingType',
      name: '寄送方式',
      type: 'select',
      options: [
        { id: null, name: '' },
        { id: shippingTypeId.postOffice, name: shippingType[shippingTypeId.postOffice] },
        { id: shippingTypeId.sevenEleven, name: shippingType[shippingTypeId.sevenEleven] },
      ],
    }),
    // TODO: need to add 扣款 schema , amount of delayDay
    renderField({ key: 'recipientName', name: '收件人姓名' }),
    renderField({ key: 'recipientAddress', name: '收件人住址' }),
    renderField({ key: 'recipientZipCode', name: '收件人郵遞區號' }),
    renderField({ key: 'recipientPhone', name: '收件人電話號碼' }),
    renderField({
      key: 'sevenElevenMarket',
      name: '取件門市',
    }),
    renderField({
      key: 'sevenElevenAddress',
      name: '門市地址',
    }),
    renderField({ key: 'note', name: '備註' }),
    renderSeparator(1),
    renderField({
      key: 'receiptTypeId',
      name: '發票',
      type: 'select',
      options: receiptTypeList,
      onValueChanged: (value) => {
        setSelectReceiptTypeId(Number(R.pathOr(receiptTypeId.eReceipt, ['target', 'value'], value)));
      },
    }),
    selectReceiptTypeId === receiptTypeId.donateReceipt ? renderField({
      key: 'donateOrganizationId',
      name: '捐贈單位',
      type: 'select',
      options: donateOrganizationList,
    }) : null,
    selectReceiptTypeId === receiptTypeId.taxIdReceipt ? renderField({ key: 'taxId', name: '統一編號' }) : null,
    selectReceiptTypeId === receiptTypeId.taxIdReceipt ? renderField({ key: 'receiptHead', name: '發票抬頭' }) : null,
    selectReceiptTypeId === receiptTypeId.taxIdReceipt ? renderField({ key: 'taxReceiptAddress', name: '統編發票寄送地址' }) : null,
    selectReceiptTypeId === receiptTypeId.eReceipt ? renderField({ key: 'eReceiptAddress', name: '中獎電子發票寄送地址' }) : null,
    selectReceiptTypeId === receiptTypeId.eReceiptMobile ? renderField({ key: 'phoneBarCode', name: '手機載具條碼' }) : null,
    renderField({
      key: 'isBuyInsurance',
      name: '是否購買報值運送',
      type: 'select',
      options: [{ id: false, name: '否' }, { id: true, name: '是' }],
    }),
    renderSeparator(2),
    renderField({ key: 'bagRentAmount', name: '折扣前 包款租金' }),
    renderField({ key: 'bagRentAmountWithDiscount', name: '折扣後 包款租金' }),
    renderField({ key: 'deposit', name: '押金' }),
    renderField({ key: ['bag', 'buyoutAmount'], name: '折扣前 包包買斷金額' }),
    renderField({ key: 'bagBuyAmountWithDiscount', name: '折扣後 包包買斷金額' }),
    renderField({ key: 'valueDeclarationAmount', name: '報值運費' }),
    renderField({
      key: ['userCoupon', 'useDate'],
      name: '使用折扣碼時間',
      type: 'date',
    }),
    renderField({
      key: ['userCoupon', 'coupon', 'couponCode'],
      name: '折扣代碼',
      disabled: true,
    }),
    renderField({
      key: ['userCoupon', 'coupon', 'couponType', 'name'],
      name: '折扣類型',
      disabled: true,
    }),
    renderField({
      key: ['userCoupon', 'coupon', 'percent'],
      name: '折數',
      disabled: true,
    }),
    renderField({
      key: ['userCoupon', 'coupon', 'minus'],
      name: '折抵',
      disabled: true,
    }),
    renderSeparator(3),
    renderField({ key: 'couponAmount', name: '折扣金額', disabled: true }),
    renderField({ key: 'amount', name: '租/買 \n 包支付金額(已扣除折扣金額)' }),
    renderField({ key: 'ohPhireEarnedAmount', name: '公司賺取金額' }),
    renderField({ key: 'ownerEarnedAmount', name: '包款主人總賺取金額' }),
    renderField({ key: ['bag', 'washAmount'], name: '清洗價格' }),
    renderSeparator(4),
    renderField({
      key: 'orderStatusId',
      name: '訂單狀態',
      type: 'select',
      options: orderStatusList.sort((a, b) => (a.order - b.order)),
    }),
    renderField({ key: 'lenderShippingCompany', name: '出貨物流' }),
    renderField({ key: 'lenderSentPackageNo', name: '出貨單號' }),
    renderField({ key: 'renterShippingCompany', name: '歸還物流' }),
    renderField({ key: 'renterSentPackageNo', name: '歸還單號' }),
    // TODO:Any Fee need to be complete at next PR.
    renderField({ key: 'damageDiffAmount', name: '損壞價差金額' }),
    renderField({ key: 'depositReturnAmount', name: '押金刷退金額' }),
    renderField({ key: 'depositTransfer', name: '轉付押金金額' }),
    renderField({ key: 'rentAmountTransfer', name: '轉付租金金額' }),
    renderField({ key: 'delayDeduct', name: '延遲金額' }),
    renderField({ key: 'delayDeductToLender', name: '轉付延遲金額' }),
    renderSeparator(5),
    renderField({ key: ['isShippingRecord'], name: '出貨單', type: 'checkbox' }),
    renderField({
      key: 'shippedDate',
      name: '出貨時間',
      type: 'date',
    }),
    renderField({ key: 'isAbleSentWithdrawRequest', name: '是否可提領' }),
    renderField({ key: ['bag', 'isOhPhireManage'], name: '是否OP管理', type: 'checkbox' }),
    renderField({ key: ['zeroCardInstallment'], name: '零卡分期期數' }),
    renderField({
      key: 'zeroCardStatus',
      name: '零卡狀態',
      type: 'select',
      options: Object.keys(zeroCardStatusId)
        .map(status => ({
          id: zeroCardStatusId[status],
          name: zeroCardStatus[status],
        })),
      disabled: true,
    }),
    renderField({ key: 'opShippingFree', name: '出貨運費' }),
    renderSeparator(6),
    renderField({ key: 'historyReply', name: '客服回覆', type: 'textArea' }),
  ];
  return [
    <FormTableComponent
      key="FormTableComponent"
      data={orderInfo}
      updateData={updateOrderInfo}
      cancelSelection={cancelSelectOrder}
      renderTableRows={renderTableRows}
      createData={createOrder}
      isCreating={isCreating}
      deleteData={() => setShowDeleteConfirmDialog(true)}
    />,
    <InputDialog
      key="deleteConfirmDialog"
      title="如果真的要刪除，請輸入您的密碼"
      inputProps={{ type: 'password' }}
      isVisible={showDeleteConfirmDialog}
      onSubmit={(value) => {
        setShowDeleteConfirmDialog(false);
        if (value) {
          deleteOrder({
            id: R.path(['id'], orderInfo),
            password: value,
          });
        }
      }}
    />,
  ];
};

OrderInfoComponent.propTypes = {
  orderInfo: PropTypes.shape({}),
  updateOrderInfo: PropTypes.func.isRequired,
  cancelSelectOrder: PropTypes.func.isRequired,
  createOrder: PropTypes.func.isRequired,
  deleteOrder: PropTypes.func.isRequired,
  orderStatusList: PropTypes.array,
  donateOrganizationList: PropTypes.array,
  receiptTypeList: PropTypes.array,
};

OrderInfoComponent.defaultProps = {
  orderInfo: null,
  orderStatusList: [],
  donateOrganizationList: [],
  receiptTypeList: [],
};

export default OrderInfoComponent;
