
const couponTypeId = {
  depositType: 1,
  saleType: 2,
  rentType: 3,
};

const couponType = {
  depositType: '押金折扣碼',
  saleType: '售價折扣碼',
  rentType: '租金折扣碼',
};

module.exports = {
  couponTypeId,
  couponType,
};
