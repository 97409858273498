import { createActions } from 'redux-actions';
import { createAsyncActionTypesArray } from '../../utils/createAsyncActionTypes';

export default createActions(
  // ----- TASKS -----
  ...createAsyncActionTypesArray('GET_ORDER_LIST'),
  ...createAsyncActionTypesArray('UPDATE_ORDER_INFO'),
  ...createAsyncActionTypesArray('CREATE_ORDER'),
  ...createAsyncActionTypesArray('DELETE_ORDER'),
  ...createAsyncActionTypesArray('SEARCH'),
  ...createAsyncActionTypesArray('GET_ORDER_STATUS_LIST'),
  ...createAsyncActionTypesArray('GET_DONATE_ORGANIZATION_LIST'),
  ...createAsyncActionTypesArray('GET_RECEIPT_TYPE_LIST'),
  ...createAsyncActionTypesArray('GET_ORDER_NUMBERS'),
  ...createAsyncActionTypesArray('FILTER_ORDER_BY_KEYWORD'),

  // ----- FLOWS -----
  ...createAsyncActionTypesArray('GET_ORDER_LIST_FLOW'),
  ...createAsyncActionTypesArray('UPDATE_ORDER_INFO_FLOW'),
  ...createAsyncActionTypesArray('CREATE_ORDER_FLOW'),
  ...createAsyncActionTypesArray('DELETE_ORDER_FLOW'),
  ...createAsyncActionTypesArray('SEARCH_FLOW'),
  ...createAsyncActionTypesArray('GET_ORDER_STATUS_LIST_FLOW'),
  ...createAsyncActionTypesArray('GET_RECEIPT_TYPE_LIST_FLOW'),
  ...createAsyncActionTypesArray('GET_DONATE_ORGANIZATION_LIST_FLOW'),
  ...createAsyncActionTypesArray('SEARCH_FLOW'),
  ...createAsyncActionTypesArray('GET_ORDER_NUMBERS_FLOW'),
  ...createAsyncActionTypesArray('FILTER_ORDER_BY_KEYWORD_FLOW'),

  'RESET_STATUS',

  { prefix: 'ORDER_MANAGEMENT' },
);
