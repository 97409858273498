import { handleActions, combineActions } from 'redux-actions';
import update from 'immutability-helper';
import * as R from 'ramda';
import ActionCreator from './ActionCreator';
import ReduxStatus from '../../constants/ReduxStatus';

const {
  getOrderStatusListFlowRequest,
  getOrderStatusListSuccess,
  getOrderStatusListFailure,

  getOrderListFlowRequest,
  getOrderListFlowSuccess,
  getOrderListFailure,

  getReceiptTypeListFlowRequest,
  getReceiptTypeListSuccess,
  getReceiptTypeListFailure,

  searchFlowRequest,
  searchFlowSuccess,
  searchFlowFailure,

  getDonateOrganizationListSuccess,
  getDonateOrganizationListFailure,
  getDonateOrganizationListFlowRequest,

  updateOrderInfoRequest,
  updateOrderInfoSuccess,
  updateOrderInfoFailure,

  createOrderFlowRequest,
  createOrderSuccess,
  createOrderFailure,

  deleteOrderFlowRequest,
  deleteOrderSuccess,
  deleteOrderFailure,

  getOrderNumbersFlowRequest,
  getOrderNumbersFlowSuccess,
  getOrderNumbersFlowFailure,
} = ActionCreator;

const initialState = {
  reduxStatus: ReduxStatus.STATUS_INITIAL,
  error: null,
  data: [],
  orderStatuses: [],
  donateOrganizations: [],
  receiptTypes: [],
};

export default handleActions({
  // request
  [combineActions(
    getOrderListFlowRequest,
    searchFlowRequest,
    updateOrderInfoRequest,
    createOrderFlowRequest,
    deleteOrderFlowRequest,
    getOrderNumbersFlowRequest,
    getOrderStatusListFlowRequest,
    getDonateOrganizationListFlowRequest,
    getReceiptTypeListFlowRequest,
  )]: state => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_LOADING },
    error: { $set: initialState.error },
  }),

  // success
  [combineActions(
    getOrderListFlowSuccess,
    searchFlowSuccess,
  )]: (state, { payload }) => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_NORMAL },
    data: R.pathOr(0, ['condition', 'page'], payload) === 0
      ? { $set: payload.data }
      : { $push: payload.data },
  }),
  [getOrderStatusListSuccess]: (state, { payload }) => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_NORMAL },
    orderStatuses: { $set: payload },
    error: { $set: initialState.error },
  }),
  [getDonateOrganizationListSuccess]: (state, { payload }) => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_NORMAL },
    donateOrganizations: { $set: payload },
    error: { $set: initialState.error },
  }),
  [getReceiptTypeListSuccess]: (state, { payload }) => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_NORMAL },
    receiptTypes: { $set: payload },
    error: { $set: initialState.error },
  }),
  [updateOrderInfoSuccess]: (state, { payload }) => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_NORMAL },
    data: {
      $set: (state.data || []).map((order) => {
        if (order.id === payload.id) return payload;
        return order;
      }),
    },
  }),
  [createOrderSuccess]: (state, { payload }) => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_NORMAL },
    data: {
      $set: [...R.pathOr([], ['data'], state), payload],
    },
  }),
  [deleteOrderSuccess]: (state, { payload }) => {
    const {
      id,
    } = payload;
    return update(state, {
      reduxStatus: { $set: ReduxStatus.STATUS_NORMAL },
      data: {
        $set: R.pathOr([], ['data'], state).map((bag) => {
          if (bag.id === id) return null;
          return bag;
        }).filter(d => d),
      },
    });
  },
  [getOrderNumbersFlowSuccess]: (state, { payload }) => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_NORMAL },
    orderNumber: { $set: payload },
  }),

  // failure
  [combineActions(
    getOrderListFailure,
    searchFlowFailure,
    updateOrderInfoFailure,
    createOrderFailure,
    deleteOrderFailure,
    getOrderStatusListFailure,
    getDonateOrganizationListFailure,
    getReceiptTypeListFailure,
    getOrderNumbersFlowFailure,
  )]: (state, { payload }) => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_ERROR },
    error: { $set: payload },
  }),
}, initialState);
