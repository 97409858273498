import api from '../../lib/configAxios';

export default {

  getContactList: async (filter = { limit: 10, order: 'id DESC' }) => api.get(
    `Contacts?filter=${encodeURIComponent(JSON.stringify(filter))}`,
  ),

  updateContactInfo: async data => api.patch(
    `Contacts/${data.id}`, data,
  ),

  deleteContact: async data => api.delete(
    `Contacts/${data.id}`,
  ),

  getUnReplyNumbers: async filter => api.get(
    `Contacts/count?where=${encodeURIComponent(JSON.stringify(filter))}`,
  ),
};
