import * as R from 'ramda';
import { createSelector } from 'reselect';
import { authStateSelector } from '../../lib/rootSelector';

export const authTokenSelector = createSelector(
  authStateSelector,
  result => R.path(['data', 'token'], result),
);
export const reduxStatusSelector = createSelector(
  authStateSelector,
  result => R.path(['reduxStatus'], result),
);
export const memberIdSelector = createSelector(
  authStateSelector,
  result => R.path(['data', 'userId'], result),
);
