import api from '../../lib/configAxios';

export default {

  getAddressList: () => api.get(
    'SendAddresses',
  ),
  filterOrderByKeyword: () => api.get(
    'SendAddresses',
  ),
  getOrderNumbers: async filter => api.get(
    `Orders/count?where=${encodeURIComponent(JSON.stringify(filter))}`,
  ),
  getOrderStatusList: () => api.get(
    'OrderStatuses',
  ),
  getDonateOrganizationList: () => api.get(
    'DonateOrganizations',
  ),
  getReceiptTypeList: () => api.get(
    'ReceiptTypes',
  ),
  updateAddressInfo: async data => api.patch(
    `SendAddresses/${data.id}`, data,
  ),
  createOrder: async data => api.post(
    'SendAddresses', data,
  ),
  deleteOrder: async data => api.post(
    'SendAddresses/verifyAndDelete', data,
  ),
};
