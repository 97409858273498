import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { Radio, Table } from 'antd';
import ReduxStatusVisualizedComponent from '../../../../components/ReduxStatusVisualizedComponent';
import NegotiationInfoComponent from '../NegotiationInfoComponent';

const styles = {
  mainContainer: {
    margin: 20,
  },
  table: {
    margin: 20,
  },
};
const NegotiationManagementPage = ({
  memberId,
  reduxStatus,
  data,
  updateNegotiationInfo,
}) => {
  const [selectedNegotiation, setSelectedNegotiation] = useState(null);
  const [isRadioDeal, setRadioIsDeal] = useState('0');

  useEffect(() => {
    setSelectedNegotiation(null);
  }, [data]);

  const getRowKey = ({ id, name }) => (`${id}.${name}`);

  const onRow = record => ({
    onClick: () => {
      setSelectedNegotiation(record);
    },
  });

  const getDealString = (isDeal) => {
    if (isDeal === null) {
      return '';
    }
    if (isDeal) {
      return '接受';
    }
    return '不接受';
  };

  const columns = [
    {
      title: '議價ID',
      dataIndex: 'id',
      width: 150,
    },
    {
      title: '包款',
      dataIndex: ['bag', 'name'],
    },
    {
      title: '議價價格',
      dataIndex: 'amount',
    },
    {
      title: 'FromUser',
      dataIndex: ['fromUser', 'name'],
    },
    {
      title: 'ToUser',
      dataIndex: ['toUser', 'name'],
    },
    {
      title: '是否接受議價',
      dataIndex: 'isAccept',
      render: isAccept => getDealString(isAccept),
    },
    {
      title: '審核結果',
      dataIndex: 'isDeal',
      render: isDeal => getDealString(isDeal),
    },
  ];

  const getTargetDataSource = () => {
    if (isRadioDeal === '0') {
      return R.filter(item => R.path(['isAccept'], item) === null, data);
    }

    if (isRadioDeal === '1') {
      return R.filter(item => (
        R.path(['isAccept'], item) === false
        && R.path(['toUserId'], item) === memberId
        && R.path(['isDeal'], item) === null
      ), data);
    }

    if (isRadioDeal === '2') {
      return R.filter(
        item => R.path(['isDeal'], item) === null
         && R.path(['isAccept'], item) === true,
        data,
      );
    }
    return R.filter(item => R.path(['isDeal'], item) !== null, data);
  };

  const countUnExamineNumber = () => (
    R.filter(
      item => R.path(['isDeal'], item) === null
       && R.path(['isAccept'], item) === true,
      data,
    ).length
  );

  return (
    <ReduxStatusVisualizedComponent
      reduxStatus={reduxStatus}
    >
      <div style={styles.mainContainer}>
        <Radio.Group
          defaultValue="0"
          buttonStyle="solid"
          onChange={event => setRadioIsDeal(event.target.value)}
        >
          <Radio.Button value="0">包主對Oh!Phire提出議價</Radio.Button>
          <Radio.Button value="1">OhPhire已否決之議價</Radio.Button>
          <Radio.Button value="2">未審核議價</Radio.Button>
          <Radio.Button value="3">已審核議價</Radio.Button>
        </Radio.Group>
        <br />
        <br />
        {` 未審核筆數: ${countUnExamineNumber()}`}
        <Table
          onRow={onRow}
          style={styles.table}
          columns={columns}
          dataSource={getTargetDataSource()}
          rowKey={getRowKey}
          pagination={false}
        />

        <NegotiationInfoComponent
          negotiationInfo={selectedNegotiation}
          updateNegotiationInfo={updateNegotiationInfo}
          cancelSelectNegotiation={() => setSelectedNegotiation(null)}
        />
      </div>
    </ReduxStatusVisualizedComponent>
  );
};

NegotiationManagementPage.propTypes = {
  updateNegotiationInfo: PropTypes.func.isRequired,
  reduxStatus: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  memberId: PropTypes.oneOf([
    PropTypes.string,
    PropTypes.number,
  ]),
};

NegotiationManagementPage.defaultProps = {
  memberId: null,
};

export default NegotiationManagementPage;
