import { handleActions } from 'redux-actions';
import update from 'immutability-helper';
import ActionCreators from './ActionCreators';
import ReduxStatus from '../../constants/ReduxStatus';

const {
  userSignInRequest,
  userSignInSuccess,
  userSignInFailure,
  userSignOutRequest,
  userSignOutSuccess,
  userSignOutFailure,
} = ActionCreators;

const initialState = {
  reduxStatus: ReduxStatus.STATUS_INITIAL,
  error: null,
  data: {
    userId: null,
    token: null,
  },
};

export default handleActions({
  [userSignInRequest]: state => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_LOADING },
    error: { $set: initialState.error },
  }),
  [userSignInSuccess]: (state, { payload }) => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_NORMAL },
    data: { $set: payload },
  }),
  [userSignInFailure]: (state, { payload }) => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_ERROR },
    error: { $set: payload },
  }),


  [userSignOutRequest]: state => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_LOADING },
    error: { $set: initialState.error },
  }),
  [userSignOutSuccess]: state => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_NORMAL },
    data: { $set: initialState.data },
  }),
  [userSignOutFailure]: (state, { payload }) => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_ERROR },
    error: { $set: payload },
  }),

}, initialState);
