import { createActions } from 'redux-actions';
import { createAsyncActionTypesArray } from '../../utils/createAsyncActionTypes';

export default createActions(
  // ----- TASKS -----
  ...createAsyncActionTypesArray('GET_BAG_LIST'),
  ...createAsyncActionTypesArray('GET_MODIFIED_BUY_AMOUNT_REQUEST_NUMBER'),
  ...createAsyncActionTypesArray('GET_BAG_NUMBERS'),
  ...createAsyncActionTypesArray('UPDATE_BAG_INFO'),
  ...createAsyncActionTypesArray('DELETE_BAG'),
  ...createAsyncActionTypesArray('CREATE_BAG'),
  ...createAsyncActionTypesArray('JUDGE_MODIFY_BUY_AMOUNT'),

  // ----- FLOWS -----
  ...createAsyncActionTypesArray('GET_BAG_LIST_FLOW'),
  ...createAsyncActionTypesArray('GET_BAG_NUMBERS_FLOW'),
  ...createAsyncActionTypesArray('GET_MODIFIED_BUY_AMOUNT_REQUEST_NUMBER_FLOW'),
  ...createAsyncActionTypesArray('UPDATE_BAG_INFO_FLOW'),
  ...createAsyncActionTypesArray('DELETE_BAG_FLOW'),
  ...createAsyncActionTypesArray('CREATE_BAG_FLOW'),
  ...createAsyncActionTypesArray('JUDGE_MODIFY_BUY_AMOUNT_FLOW'),

  'RESET_STATUS',

  { prefix: 'CLOTHES_MANAGEMENT' },
);
