import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ActionCreators from '../../ActionCreator';
import SubscribeManagementPage from './SubscribeManagementPage';
import {
  reduxStatusSelector,
  reduxErrorSelector,
  subscribeListSelector,
} from '../../selector';

const SubscribeManagementPageContainer = ({
  reduxStatus,
  reduxError,
  actions: {
    updateSubscribeInfoFlowRequest,
    getSubscribeListFlowRequest,
    getMemberSubscribeListFlowRequest,
    searchFlowRequest,
    deleteSubscribeFlowRequest,
    resetStatus,
    sendNoticeToSubscriberFlowRequest,
  },
  data,
}) => {
  useEffect(() => {
    getSubscribeListFlowRequest();
    return resetStatus;
  }, []);

  const [searchKeyword, setSearchKeyword] = useState(null);
  const [selectType, setSelectType] = useState('unMember');
  const [page, setPage] = useState(0);

  const handleSearch = (keyword) => {
    setSearchKeyword(keyword);
    setPage(0);
    searchFlowRequest({
      keyword,
      page: 0,
    });
  };
  const handleLoadMore = () => {
    const newPage = page + 1;
    setPage(newPage);
    searchFlowRequest({
      keyword: searchKeyword,
      page: newPage,
      selectType,
      isSubscribe: true,
    });
  };

  const handleUnMemberSubscribe = () => {
    getSubscribeListFlowRequest();
    setSelectType('unMember');
    setPage(0);
  };

  const handleMemberSubscribe = () => {
    getMemberSubscribeListFlowRequest();
    setSelectType('member');
    setPage(0);
  };

  return (
    <SubscribeManagementPage
      reduxStatus={reduxStatus}
      reduxError={reduxError}
      data={data}
      updateSubscribeInfo={updateSubscribeInfoFlowRequest}
      deleteSubscribe={deleteSubscribeFlowRequest}
      search={handleSearch}
      handleLoadMore={handleLoadMore}
      sendNoticeToSubscriber={sendNoticeToSubscriberFlowRequest}
      onClickUnMemberSubscribe={handleUnMemberSubscribe}
      onClickMemberSubscribe={handleMemberSubscribe}
      selectType={selectType}
    />
  );
};

SubscribeManagementPageContainer.propTypes = {
  reduxStatus: PropTypes.string,
  reduxError: PropTypes.string,
  actions: PropTypes.shape({
    getSubscribeListFlowRequest: PropTypes.func.isRequired,
    updateSubscribeInfoFlowRequest: PropTypes.func.isRequired,
    deleteSubscribeFlowRequest: PropTypes.func.isRequired,
    resetStatus: PropTypes.func.isRequired,
    searchFlowRequest: PropTypes.func.isRequired,
    sendNoticeToSubscriberFlowRequest: PropTypes.func.isRequired,
    getMemberSubscribeListFlowRequest: PropTypes.func.isRequired,
  }).isRequired,
  data: PropTypes.array,
};

SubscribeManagementPageContainer.defaultProps = {
  reduxStatus: null,
  reduxError: null,
  data: [],
};

const mapStateToProps = state => ({
  reduxStatus: reduxStatusSelector(state),
  reduxError: reduxErrorSelector(state),
  data: subscribeListSelector(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    ...ActionCreators,
  }, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SubscribeManagementPageContainer);
