import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Table, Input } from 'antd';
import IdentificationInfoComponent from './IdentificationInfoComponent';
import { passbaseStatus, passbaseStatusId } from '../../../../constants/PassbaseStatus';
import ReduxStatusVisualizedComponent from '../../../../components/ReduxStatusVisualizedComponent';

const { Search } = Input;

const styles = {
  mainContainer: {
    margin: 20,
  },
  searchBarStyle: {
    margin: '30px 0',
    width: 300,
  },
};

const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
  },
  {
    title: '姓名',
    dataIndex: 'name',
  },
  {
    title: 'E-mail',
    dataIndex: 'email',
  },
  {
    title: '狀態',
    dataIndex: 'status',
    render: (status) => {
      if (status === passbaseStatusId.initial) return passbaseStatus.initial;
      if (status === passbaseStatusId.accept) return passbaseStatus.accept;
      if (status === passbaseStatusId.reject) return passbaseStatus.reject;
      if (status === passbaseStatusId.processing) return passbaseStatus.processing;
      return null;
    },
  },
  {
    title: '通過',
    dataIndex: 'isIdentified',
    render: isIdentified => (isIdentified ? '是' : '否'),
  },
];

const IdentificationManagementPage = ({
  reduxStatus,
  reduxError,
  data,
  identificationNumbers,
  searchByKeyword,
  updateIdentificationInfo,
  onIdentificationSelected,
}) => {
  const [selectedIdentification, setSelectedIdentification] = useState(null);

  useEffect(() => {
    setSelectedIdentification(null);
  }, [data]);

  const onRow = record => ({
    onClick: () => {
      if (onIdentificationSelected) onIdentificationSelected(record);
      else setSelectedIdentification(record);
    },
  });

  return (
    <div style={styles.mainContainer} key="baseContainer">

      <Search
        placeholder="搜尋"
        onSearch={value => searchByKeyword(value)}
        enterButton
        size="large"
        style={styles.searchBarStyle}
      />

      <ReduxStatusVisualizedComponent
        reduxStatus={reduxStatus}
        showModalOnError
        modalFailureContent={reduxError}
      >
        {identificationNumbers ? `目前需認證會員: ${identificationNumbers}` : null}
        <Table
          columns={columns}
          dataSource={data}
          rowKey="id"
          onRow={onRow}
        />
      </ReduxStatusVisualizedComponent>

      <IdentificationInfoComponent
        identificationInfo={selectedIdentification}
        updateIdentificationInfo={updateIdentificationInfo}
        cancelSelectIdentification={() => setSelectedIdentification(null)}
      />

    </div>
  );
};

IdentificationManagementPage.propTypes = {
  reduxStatus: PropTypes.string.isRequired,
  reduxError: PropTypes.string,
  data: PropTypes.array,
  identificationNumbers: PropTypes.number,
  searchByKeyword: PropTypes.func.isRequired,
  updateIdentificationInfo: PropTypes.func.isRequired,
  onIdentificationSelected: PropTypes.func,
};

IdentificationManagementPage.defaultProps = {
  data: [],
  identificationNumbers: null,
  reduxError: null,
  onIdentificationSelected: null,
};

export default IdentificationManagementPage;
