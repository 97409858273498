import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { passbaseStatus, passbaseStatusId } from '../../../../constants/PassbaseStatus';
import FormTableComponent from '../../../../components/FormTable/FormTableComponent';

const MemberInfoComponent = ({
  memberInfo,
  updateMemberInfo,
  cancelSelectMember,
}) => {
  const renderTableRows = (renderField, renderSeparator) => [
    R.pathOr(false, ['isActive'], memberInfo) && (
    <tr key="isActive">
      <td />
      <td>已認證</td>
    </tr>
    ),
    renderField({ key: 'email', name: 'E-mail', disabled: true }),
    renderField({ key: 'name', name: '名稱' }),
    renderField({ key: 'communityName', name: '社群名字' }),
    renderField({ key: 'phone', name: '電話號碼' }),
    renderField({ key: 'birthday', name: '生日', type: 'date' }),
    renderField({
      key: 'gender',
      name: '性別',
      type: 'select',
      options: [{ id: 'm', name: '男' }, { id: 'f', name: '女' }, { id: '', name: '其他/不願透露' }],
    }),
    renderField({
      key: 'isMaster',
      name: '是否為時尚達人',
      type: 'select',
      options: [{ id: 'false', name: '否' }, { id: 'true', name: '是' }],
    }),
    renderField({
      key: 'isSubscribe',
      name: '訂閱',
      type: 'select',
      options: [{ id: 'false', name: '否' }, { id: 'true', name: '是' }],
    }),
    renderField({
      key: 'isBusinessPartner',
      name: '是否為合作廠商',
      type: 'select',
      options: [{ id: null, name: '' }, { id: 'false', name: '否' }, { id: 'true', name: '是' }],
    }),
    renderSeparator(1),
    renderField({ key: 'payeeName', name: '付款人姓名' }),
    renderField({ key: 'payeePhone', name: '付款人電話號碼' }),
    renderField({ key: 'payeeZipCode', name: '付款人郵遞區號' }),
    renderField({ key: 'payeeAddress', name: '付款住址' }),
    renderField({ key: 'payeeBankCode', name: '付款銀行代碼' }),
    renderField({ key: 'payeeBankAccount', name: '付款銀行帳號' }),
    renderField({ key: 'payeeBankBranch', name: '付款銀行分行' }),
    renderSeparator(2),
    renderField({ key: 'isPostReceive', name: '是否用郵寄收件' }),
    renderField({ key: 'isSevenElevenReceive', name: '是否用超商收件' }),
    renderSeparator(3),
    renderField({ key: 'recipientName', name: '收件人姓名' }),
    renderField({ key: 'recipientPhone', name: '收件人電話號碼' }),
    renderField({ key: 'recipientZipCode', name: '收件人郵遞區號' }),
    renderField({ key: 'recipientAddress', name: '收件人住址' }),
    renderSeparator(4),
    renderField({ key: 'selectStore', name: '選擇超商' }),
    renderField({ key: 'sevenElevenMarket', name: '超商門市' }),
    renderSeparator(5),
    renderField({ key: 'isVerified', name: '是否通過passbase驗證', type: 'checkbox' }),
    renderField({
      key: 'passbaseStatus',
      name: 'passbase狀態',
      type: 'select',
      options: [
        { id: null, name: null },
        { id: passbaseStatusId.accept, name: passbaseStatus.accept },
        { id: passbaseStatusId.initial, name: passbaseStatus.initial },
        { id: passbaseStatusId.maybeLate, name: passbaseStatus.maybeLate },
        { id: passbaseStatusId.processing, name: passbaseStatus.processing },
        { id: passbaseStatusId.reject, name: passbaseStatus.reject },
      ],
    }),
  ];
  return (
    <FormTableComponent
      data={memberInfo}
      updateData={updateMemberInfo}
      cancelSelection={cancelSelectMember}
      renderTableRows={renderTableRows}
    />
  );
};

MemberInfoComponent.propTypes = {
  memberInfo: PropTypes.shape({}),
  updateMemberInfo: PropTypes.func.isRequired,
  cancelSelectMember: PropTypes.func.isRequired,
};

MemberInfoComponent.defaultProps = {
  memberInfo: null,
};

export default MemberInfoComponent;
