import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { Button } from 'antd/lib/index';
import ReduxStatus from '../../../../constants/ReduxStatus';
import ReduxStatusVisualizedComponent from '../../../../components/ReduxStatusVisualizedComponent';
import ModalComponent from '../../../../components/ModalComponent';

const styles = {
  mainContainer: {
    margin: 20,
  },
  itemContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: 500,
    height: 'fit-content',
    margin: 10,
  },
  title: {
    width: 200,
    marginRight: 10,
  },
  input: {
    width: 900,
  },
};

const PayByTokenManagementPage = ({
  onTapPayPayByToken,
  reduxStatus,
  reduxError,
}) => {
  const [cardKey, setCardKey] = useState('');
  const [cardToken, setCardToken] = useState('');
  const [orderId, setOrderId] = useState('');
  const [amount, setAmount] = useState('');

  useEffect(() => {
    if (reduxStatus === ReduxStatus.STATUS_NORMAL) {
      setCardKey('');
      setCardToken('');
      setOrderId('');
      setAmount('');
    }
  }, [reduxStatus]);

  const handleSend = () => {
    if (R.isEmpty(cardKey) || R.isEmpty(cardToken) || R.isEmpty(orderId) || R.isEmpty(amount)) {
      return;
    }

    onTapPayPayByToken({
      cardKey, cardToken, orderId, amount,
    });
  };

  return (

    <div style={styles.mainContainer}>
      <ReduxStatusVisualizedComponent
        reduxStatus={reduxStatus}
        showModalOnError
        modalFailureContent={reduxError}
      >
        <ModalComponent
          title="申請扣款"
          isVisible={reduxStatus === ReduxStatus.STATUS_NORMAL}
          content="扣款成功"
        />
        <div style={styles.itemContainer}>
          <div style={styles.title}>CardKey</div>
          <input
            style={styles.input}
            type="field"
            onChange={event => setCardKey(event.target.value)}
            value={cardKey}
          />
        </div>
        <div style={styles.itemContainer}>
          <div style={styles.title}> CardToken</div>
          <input
            style={styles.input}
            type="field"
            onChange={event => setCardToken(event.target.value)}
            value={cardToken}
          />
        </div>
        <div style={styles.itemContainer}>
          <div style={styles.title}>OrderId</div>
          <input
            style={styles.input}
            type="field"
            onChange={event => setOrderId(event.target.value)}
            value={orderId}
          />
        </div>
        <div style={styles.itemContainer}>
          <div style={styles.title}>金額</div>
          <input
            style={styles.input}
            type="field"
            onChange={event => setAmount(event.target.value)}
            value={amount}
          />
        </div>
        <Button key="send" type="submit" onClick={() => handleSend()}>送出</Button>
      </ReduxStatusVisualizedComponent>
    </div>
  );
};

PayByTokenManagementPage.propTypes = {
  onTapPayPayByToken: PropTypes.func.isRequired,
  reduxStatus: PropTypes.string,
  reduxError: PropTypes.string,
};

PayByTokenManagementPage.defaultProps = {
  reduxStatus: null,
  reduxError: null,
};

export default PayByTokenManagementPage;
