import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'antd';
import * as R from 'ramda';
import FormTableComponent from '../../../../components/FormTable/FormTableComponent';
import BagTagComponent from './BagTagComponent';
import TagManagementContainer from '../../../TagManagement/components/TagManagementPage';
import MemberManagementContainer from '../../../MemberManagement/components/MemberManagementPage';
import Uploader from '../../../ResourceUploader/components/Uploader';
import BagStatus from '../../../../constants/BagStatus';
import {
  hotLevel,
  hotLevelId,
} from '../../../../constants/BagHotLevel';
import {
  bagLevel,
  bagLevelId,
} from '../../../../constants/BagLevel';
import Type from '../../../../constants/Type';
import InputDialog from '../../../../components/InputDialog';

const styles = {
  imageItemContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: 'fit-content',
    height: 'fit-content',
  },
  imageContainer: {
    position: 'relative',
  },
  deleteImageButton: {
    position: 'absolute',
    outline: 'none',
    top: 10,
    right: 10,
  },
  image: {
    width: 120,
    height: 120,
    objectFit: 'cover',
    borderRadius: 5,
    cursor: 'pointer',
    display: 'inline-block',
    verticalAlign: 'middle',
  },
  uploader: {
    display: 'inline-block',
    verticalAlign: 'middle',
    margin: 5,
  },
  modal: {
    minWidth: 'fit-content',
  },
  button: {
    margin: '0px 5px',
  },
  inputBox: {
    border: '1px solid #33333333',
    borderRadius: 5,
    outline: 'none',
    minWidth: 300,
    padding: 5,
  },
};

const BagInfoComponent = ({
  bagInfo,
  updateBagInfo,
  deleteBag,
  createBag,
  cancelSelectBag,
  onJudeModifyBuyAmount,
}) => {
  const [showTagSelector, setShowTagSelector] = useState(false);
  const [showMemberSelector, setShowMemberSelector] = useState(false);
  const [showDeleteConfirmDialog, setShowDeleteConfirmDialog] = useState(false);

  const isCreating = Object.keys(bagInfo || {}).length === 0;

  const renderImageChild = (data = {}) => (
    <div style={styles.imageItemContainer}>
      <div style={styles.imageContainer}>
        <Button
          style={styles.deleteImageButton}
          icon="delete"
          disabled={R.isNil(R.pathOr(R.pathOr(null, [data.key, 'uri'], bagInfo), ['modifiedValue', 'uri'], data))
          || R.isEmpty(R.pathOr(R.pathOr(null, [data.key, 'uri'], bagInfo), ['modifiedValue', 'uri'], data))}
          onClick={() => {
            const {
              handleChange,
            } = data;
            handleChange({ id: null, uri: '' });
          }}
        />
        <img
          src={R.pathOr(R.pathOr(null, [data.key, 'uri'], bagInfo), ['modifiedValue', 'uri'], data)}
          style={styles.image}
          alt=""
        />
      </div>
      <div style={styles.uploader}>
        <Uploader
          onFileUploaded={(result) => {
            const {
              handleChange,
            } = data;
            handleChange(result);
          }}
        />
      </div>
    </div>
  );

  const renderDefaultOrderChild = (data = {}) => {
    const {
      handleChange,
    } = data;

    // The old data for defaultOrder is null, null is order ahead than 1 for order.
    // In web order list is set desc, so set the boundary for it to convert form 1 start.
    const orderBoundary = 10000;

    const convertOrder = (event) => {
      const inputValue = event.target.value;
      handleChange(orderBoundary - inputValue);
    };

    const renderConvertOrder = () => {
      const orderValue = R.pathOr(R.pathOr(null, [data.key], bagInfo), ['modifiedValue'], data);
      if (orderValue === null) {
        return '';
      }
      return (orderBoundary - orderValue);
    };

    return (
      <input
        style={styles.inputBox}
        value={renderConvertOrder()}
        type="text"
        onChange={(event) => { convertOrder(event); }}
      />
    );
  };

  const tagRowMetaData = {
    key: 'tags',
    name: '籤標',
    type: 'custom',
    renderChild: (modifiedValue, isModified, handleChange) => [
      ...(([
        ...R.pathOr([], ['tags'], bagInfo),
        ...Object.keys(modifiedValue || {})
          .map(key => (R.pathOr(null, [key, 'action'], modifiedValue) === 'add' ? R.pathOr(null, [key], modifiedValue) : null))
          .filter(d => d),
      ]).map(({
        id,
        name,
      }) => ((R.pathOr(null, [id, 'action'], modifiedValue) === 'remove') ? null : (
        <BagTagComponent
          key={id}
          tagName={name}
          onClick={() => {
            const {
              [id]: target,
              ...restOfModifiedValue
            } = modifiedValue || {};
            if (R.pathOr(null, [id, 'action'], modifiedValue) === 'add') {
              handleChange(R.isEmpty(restOfModifiedValue) ? null : restOfModifiedValue);
            } else {
              handleChange({
                ...restOfModifiedValue,
                [id]: {
                  action: 'remove',
                },
              });
            }
          }}
        />
      )))),
      <BagTagComponent
        key="add"
        tagName="+"
        onClick={() => { setShowTagSelector(true); }}
      />,
      <Modal
        key="tagSelector"
        title="選擇標籤"
        visible={showTagSelector}
        onCancel={() => { setShowTagSelector(false); }}
      >
        {
          showTagSelector
          && (
          <TagManagementContainer
            onTagSelected={(tag) => {
              setShowTagSelector(false);
              const id = R.pathOr(null, ['id'], tag);
              if (!id) return;
              const {
                [id]: target,
                ...restOfModifiedValue
              } = modifiedValue || {};
              if (R.pathOr(null, [id, 'action'], modifiedValue) === 'remove') {
                handleChange(R.isEmpty(restOfModifiedValue) ? null : restOfModifiedValue);
              } else {
                handleChange({
                  ...restOfModifiedValue,
                  [id]: {
                    action: 'add',
                    ...tag,
                  },
                });
              }
            }}
          />
          )
        }
      </Modal>,
    ],
  };


  const ownerRowMetaData = {
    key: 'owner',
    name: '包包主人',
    type: 'custom',
    renderChild: (modifiedValue, isModified, handleChange) => [
      <span key="name">
        {modifiedValue ? R.pathOr('(沒有名字)', ['name'], modifiedValue) : R.pathOr('(沒有名字)', ['owner', 'name'], bagInfo)}
        (
        {modifiedValue ? R.pathOr('', ['email'], modifiedValue) : R.pathOr('', ['owner', 'email'], bagInfo)}
        )
      </span>,
      <span key="button"><Button size="small" onClick={() => { setShowMemberSelector(true); }}>...</Button></span>,
      <Modal
        key="memberSelector"
        title="選擇會員"
        visible={showMemberSelector}
        onCancel={() => { setShowMemberSelector(false); }}
        style={styles.modal}
      >
        <MemberManagementContainer
          onMemberSelected={(member) => {
            setShowMemberSelector(false);
            if (!member) return;
            handleChange(member);
          }}
        />
      </Modal>,
    ],
  };


  const renderModifiedBuyAmountChild = {
    key: 'modifiedBuyoutAmountRequest',
    name: '修改售價',
    disabled: true,
    type: 'custom',
    renderChild: () => [
      <span key="modifiedBuyout">
        {R.pathOr('未提出修改需求', ['modifiedBuyoutAmountRequest'], bagInfo)}
      </span>,
      R.pathOr(null, ['modifiedBuyoutAmountRequest'], bagInfo) ? (
        <span key="accept-button">
          <Button
            size="small"
            style={styles.button}
            onClick={() => {
              onJudeModifyBuyAmount(
                R.pathOr(null, ['id'], bagInfo),
                true,
              );
            }}
          >
            同意修改
          </Button>
          <Button
            size="small"
            style={styles.button}
            onClick={() => {
              onJudeModifyBuyAmount(
                R.pathOr(null, ['id'], bagInfo),
                false,
              );
            }}
          >
            拒絕修改
          </Button>
        </span>
      ) : null,
    ],
  };
  const getHotLevelOptions = () => {
    const reorderHotLevel = Object.keys(hotLevelId)
      .sort((level1, level2) => (hotLevelId[level1] - hotLevelId[level2]))
      .map(level => ({
        id: hotLevelId[level],
        name: hotLevel[level],
      }));

    reorderHotLevel.unshift({ id: null, name: '' });
    return reorderHotLevel;
  };

  const getBagLevelOptions = () => {
    const reorderBagLevel = Object.keys(bagLevelId)
      .map(level => ({
        id: bagLevelId[level],
        name: bagLevel[level],
      }));

    reorderBagLevel.unshift({ id: null, name: '' });
    return reorderBagLevel;
  };

  const renderTableRows = (renderField, renderSeparator) => [
    renderField({ key: 'name', name: '名稱' }),
    renderField({ key: 'bagNo', name: '編號' }),
    renderField(tagRowMetaData),
    renderField({
      key: 'typeId',
      name: '交易型態',
      type: 'select',
      options: Object.keys(Type.typeId)
        .sort((type1, type2) => (Type.typeId[type1] - Type.typeId[type2]))
        .map(type => ({
          id: Type.typeId[type],
          name: Type.transactionType[type],
        })),
    }),
    renderField({
      key: 'commodityTypeId',
      name: '商品類別型態',
      type: 'select',
      options: Object.keys(Type.commodityTypeId)
        .sort((type1, type2) => (Type.commodityTypeId[type1] - Type.commodityTypeId[type2]))
        .map(type => ({
          id: Type.commodityTypeId[type],
          name: Type.commodityType[type],
        })),
    }),
    renderSeparator(1),
    renderField({ key: 'rentCount', name: '被租次數' }),
    renderField({ key: 'rentMonths', name: '被租月份' }),
    renderField({ key: 'rentAmountPerDay', name: '日租金' }),
    renderField({ key: 'buyoutAmount', name: '買斷價格' }),
    renderField({ key: 'deposit', name: '押金' }),
    renderField({ key: 'depositOrigin', name: '押金 (包主定的)' }),
    renderField({ key: 'originalPrice', name: '包包原價 (包主定的)' }),
    renderField({ key: 'washAmount', name: '清洗價格' }),
    renderSeparator(2),
    renderField({ key: 'height', name: '長' }),
    renderField({ key: 'width', name: '寬' }),
    renderField({ key: 'depth', name: '高' }),
    renderField({ key: 'sizeStandard', name: '尺寸標準' }),
    renderField({ key: 'sizeNumber', name: '尺寸' }),
    renderField({
      key: 'newLevel',
      name: '新舊度',
      type: 'select',
      options: getBagLevelOptions(),
    }),
    renderField({
      key: 'hotLevel',
      name: '熱門程度',
      type: 'select',
      options: getHotLevelOptions(),
    }),
    renderField({ key: 'purchaseYear', name: '購買年份' }),
    renderField({ key: 'isHasWarrantyCard', name: '是否有保卡', type: 'checkbox' }),
    renderField({ key: 'isHasDustCover', name: '是否有防塵套', type: 'checkbox' }),
    renderField({ key: 'isHasOriginBox', name: '是否有原廠盒', type: 'checkbox' }),
    renderField({ key: 'isHasProve', name: '是否有購買證明', type: 'checkbox' }),
    renderField({ key: 'description', name: '商品描述', type: 'textArea' }),
    renderSeparator(3),
    renderField({
      key: 'defaultOrder',
      name: '自訂順序',
      type: 'custom',
      renderChild: (modifiedValue, isModified, handleChange) => renderDefaultOrderChild({
        modifiedValue,
        isModified,
        handleChange,
        key: 'defaultOrder',
      }),
    }),
    renderField({
      key: 'bagStatusId',
      name: '目前狀態',
      type: 'select',
      options: Object.keys(BagStatus.bagStatusId)
        .sort((status1, status2) => (BagStatus.bagStatusId[status1] - BagStatus.bagStatusId[status2]))
        .map(status => ({
          id: BagStatus.bagStatusId[status],
          name: BagStatus.bagStatus[status],
        })),
    }),
    renderField({ key: 'isOhPhireManage', name: '是否OP管理', type: 'checkbox' }),
    renderField(renderModifiedBuyAmountChild),
    renderField({ key: 'inStockNumber', name: '剩餘數量' }),
    renderSeparator(4),
    renderField({
      key: 'frontPic',
      name: '照片(前)',
      type: 'custom',
      renderChild: (modifiedValue, isModified, handleChange) => renderImageChild({
        modifiedValue,
        isModified,
        handleChange,
        key: 'frontPic',
      }),
    }),
    renderField({
      key: 'backPic',
      name: '照片(後)',
      type: 'custom',
      renderChild: (modifiedValue, isModified, handleChange) => renderImageChild({
        modifiedValue,
        isModified,
        handleChange,
        key: 'backPic',
      }),
    }),
    renderField({
      key: 'sidePic',
      name: '照片(側)',
      type: 'custom',
      renderChild: (modifiedValue, isModified, handleChange) => renderImageChild({
        modifiedValue,
        isModified,
        handleChange,
        key: 'sidePic',
      }),
    }),
    renderField({
      key: 'bottomPic',
      name: '照片(底)',
      type: 'custom',
      renderChild: (modifiedValue, isModified, handleChange) => renderImageChild({
        modifiedValue,
        isModified,
        handleChange,
        key: 'bottomPic',
      }),
    }),
    renderField({
      key: 'zipperPic',
      name: '照片(拉鏈)',
      type: 'custom',
      renderChild: (modifiedValue, isModified, handleChange) => renderImageChild({
        modifiedValue,
        isModified,
        handleChange,
        key: 'zipperPic',
      }),
    }),
    renderSeparator(5),
    renderField({
      key: 'contractDeadLine',
      name: '合約結束日',
      type: 'date',
    }),
    renderField({
      key: 'createTime',
      name: '建立時間',
      type: 'dateTime',
    }),
    renderField({
      key: 'releaseDate',
      name: '上架日',
      type: 'dateTime',
    }),
    renderSeparator(6),
    renderField(ownerRowMetaData),
    renderField({
      key: ['negotiator', 'name'],
      name: '目前議價人',
      type: 'input',
      disabled: true,
    }),
  ];

  return [
    <FormTableComponent
      key="FormTableComponent"
      data={bagInfo}
      updateData={updateBagInfo}
      cancelSelection={cancelSelectBag}
      renderTableRows={renderTableRows}
      deleteData={() => setShowDeleteConfirmDialog(true)}
      createData={createBag}
      isCreating={isCreating}
    />,
    <InputDialog
      key="deleteConfirmDialog"
      title="如果真的要刪除，請輸入您的密碼"
      inputProps={{ type: 'password' }}
      isVisible={showDeleteConfirmDialog}
      onSubmit={(value) => {
        setShowDeleteConfirmDialog(false);
        if (value) {
          deleteBag({
            id: R.path(['id'], bagInfo),
            password: value,
          });
        }
      }}
    />,
  ];
};

BagInfoComponent.propTypes = {
  bagInfo: PropTypes.shape({}),
  updateBagInfo: PropTypes.func.isRequired,
  deleteBag: PropTypes.func.isRequired,
  createBag: PropTypes.func.isRequired,
  cancelSelectBag: PropTypes.func.isRequired,
};

BagInfoComponent.defaultProps = {
  bagInfo: null,
};

export default BagInfoComponent;
