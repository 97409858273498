import api from '../../lib/configAxios';

export default {

  getCommentList: async (filter = { limit: 10, order: 'id DESC' }) => api.get(
    `Comments?filter=${encodeURIComponent(JSON.stringify(filter))}`,
  ),

  updateCommentInfo: async data => api.patch(
    `Comments/${data.id}`, data,
  ),

};
