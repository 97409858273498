import { handleActions, combineActions } from 'redux-actions';
import update from 'immutability-helper';
import ActionCreator from './ActionCreator';
import ReduxStatus from '../../constants/ReduxStatus';

const {
  resetStatus,
  getIdentificationListRequest,
  getIdentificationListSuccess,
  getIdentificationListFailure,
  getIdentificationNumbersRequest,
  getIdentificationNumbersSuccess,
  getIdentificationNumbersFailure,
  searchByKeywordRequest,
  searchByKeywordSuccess,
  searchByKeywordFailure,
  updateIdentificationInfoRequest,
  updateIdentificationInfoSuccess,
  updateIdentificationInfoFailure,
} = ActionCreator;

const initialState = {
  reduxStatus: ReduxStatus.STATUS_INITIAL,
  error: null,
  data: [],
};

export default handleActions({
  [combineActions(
    getIdentificationListRequest,
    getIdentificationNumbersRequest,
    searchByKeywordRequest,
    updateIdentificationInfoRequest,
  )]: state => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_LOADING },
    error: { $set: initialState.error },
  }),
  [combineActions(
    getIdentificationListSuccess,
    searchByKeywordSuccess,
  )]: (state, { payload }) => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_NORMAL },
    data: { $set: payload },
  }),
  [combineActions(
    getIdentificationListFailure,
    getIdentificationNumbersFailure,
    searchByKeywordFailure,
    updateIdentificationInfoFailure,
  )]: (state, { payload }) => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_ERROR },
    error: { $set: payload },
  }),
  [getIdentificationNumbersSuccess]: (state, { payload }) => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_NORMAL },
    identificationNumbers: { $set: payload },
  }),
  [updateIdentificationInfoSuccess]: (state, { payload }) => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_NORMAL },
    data: {
      $set: (state.data || []).map((identification) => {
        if (identification.id === payload.id) return payload;
        return identification;
      }),
    },
  }),
  [resetStatus]: state => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_INITIAL },
  }),
}, initialState);
