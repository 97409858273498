import api from '../../lib/configAxios';

export default {

  getBagList: async (filter = { limit: 10, order: 'id DESC' }) => api.get(
    `Bags?filter=${encodeURIComponent(JSON.stringify(filter))}`,
  ),

  getBagNumbers: async filter => api.get(
    `Bags/count?where=${encodeURIComponent(JSON.stringify(filter))}`,
  ),

  updateBagInfo: async data => api.patch(
    `Bags/${data.id}`, data,
  ),

  deleteBag: async data => api.post(
    'Bags/verifyAndDelete', data,
  ),

  createBag: async data => api.post(
    'Bags', data,
  ),

  linkBagTag: async ({ bagId, tagId }) => api.put(
    `Bags/${bagId}/tags/rel/${tagId}`,
  ),

  unlinkBagTag: async ({ bagId, tagId }) => api.delete(
    `Bags/${bagId}/tags/rel/${tagId}`,
  ),

  modifiedBuyoutAmountJudge: async ({ bagId, ...data }) => api.post(
    `Bags/${bagId}/modifiedBuyoutAmountJudge`, data,
  ),
};
