import api from '../../lib/configAxios';

export default {

  getThemeList: async () => api.get(
    `Themes?filter=${encodeURIComponent(JSON.stringify({
      include: 'resource',
      order: 'order asc',
    }))}`,
  ),

  createTheme: async data => api.post(
    'Themes', data,
  ),

  updateTheme: async data => api.patch(
    `Themes/${data.id}`, data,
  ),

  deleteTheme: async data => api.post(
    'Themes/verifyAndDelete', data,
  ),

  getThemeBagList: async id => api.get(
    `Themes/${id}/bags?filter=${encodeURIComponent(JSON.stringify({
      fields: ['name', 'id'],
    }))}`,
  ),

  linkThemeBag: async ({
    themeId,
    bagId,
  }) => api.put(
    `Themes/${themeId}/bags/rel/${bagId}`,
  ),

  unlinkThemeBag: async ({
    themeId,
    bagId,
  }) => api.delete(
    `Themes/${themeId}/bags/rel/${bagId}`,
  ),
};
