import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ActionCreators from '../../ActionCreator';
import WithdrawManagementPage from './WithdrawManagementPage';

import {
  reduxStatusSelector,
  reduxErrorSelector,
  withdrawListSelector,
  unRemitNumberSelector,
} from '../../selector';

const WithdrawManagementPageContainer = ({
  actions: {
    searchFlowRequest,
    updateWithdrawInfoFlowRequest,
    deleteWithdrawFlowRequest,
    getWithdrawListFlowRequest,
    getUnRemitNumbersFlowRequest,
  },
  data,
  reduxError,
  reduxStatus,
  unRemitNumber,
}) => {
  const [searchKeyword, setSearchKeyword] = useState(null);
  const [page, setPage] = useState(0);

  useEffect(() => {
    getWithdrawListFlowRequest({ page });
    getUnRemitNumbersFlowRequest();
  }, []);

  const handleSearch = (keyword) => {
    setSearchKeyword(keyword);
    setPage(0);
    searchFlowRequest({
      keyword,
      page: 0,
    });
  };

  const handleLoadMore = () => {
    const newPage = page + 1;
    setPage(newPage);
    searchFlowRequest({
      keyword: searchKeyword,
      page: newPage,
    });
  };

  return (
    <WithdrawManagementPage
      search={handleSearch}
      reduxStatus={reduxStatus}
      reduxError={reduxError}
      data={data}
      updateWithdrawInfo={updateWithdrawInfoFlowRequest}
      deleteWithdraw={deleteWithdrawFlowRequest}
      handleLoadMore={handleLoadMore}
      unRemitNumber={unRemitNumber}
    />
  );
};

WithdrawManagementPageContainer.propTypes = {
  actions: PropTypes.shape({
    searchFlowRequest: PropTypes.func.isRequired,
    updateWithdrawInfoFlowRequest: PropTypes.func.isRequired,
    deleteWithdrawFlowRequest: PropTypes.func.isRequired,
    getWithdrawListFlowRequest: PropTypes.func.isRequired,
    getUnRemitNumbersFlowRequest: PropTypes.func.isRequired,
  }).isRequired,
  reduxStatus: PropTypes.string,
  data: PropTypes.array,
  reduxError: PropTypes.string,
  unRemitNumber: PropTypes.number,
};

WithdrawManagementPageContainer.defaultProps = {
  data: [],
  reduxStatus: null,
  reduxError: '',
  unRemitNumber: null,
};

const mapStateToProps = state => ({
  data: withdrawListSelector(state),
  reduxStatus: reduxStatusSelector(state),
  reduxError: reduxErrorSelector(state),
  unRemitNumber: unRemitNumberSelector(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    ...ActionCreators,
  }, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WithdrawManagementPageContainer);
