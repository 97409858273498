import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';
import * as R from 'ramda';
import API from './API';
import ActionCreator from './ActionCreator';
import { receiptTypeId } from '../../constants/ReceiptType';

const {
  getOrderStatusListRequest,
  getOrderStatusListSuccess,
  getOrderStatusListFailure,
  getOrderStatusListFlowRequest,
  getOrderStatusListFlowSuccess,
  getOrderStatusListFlowFailure,
  getReceiptTypeListRequest,
  getReceiptTypeListSuccess,
  getReceiptTypeListFailure,
  getReceiptTypeListFlowRequest,
  getReceiptTypeListFlowSuccess,
  getReceiptTypeListFlowFailure,
  getOrderListRequest,
  getOrderListSuccess,
  getOrderListFailure,
  getOrderListFlowRequest,
  getOrderListFlowSuccess,
  getOrderListFlowFailure,
  getDonateOrganizationListRequest,
  getDonateOrganizationListSuccess,
  getDonateOrganizationListFailure,
  getDonateOrganizationListFlowRequest,
  getDonateOrganizationListFlowSuccess,
  getDonateOrganizationListFlowFailure,
  searchFlowRequest,
  searchFlowSuccess,
  searchFlowFailure,
  filterOrderByKeywordFlowRequest,
  filterOrderByKeywordRequest,
  updateOrderInfoFlowRequest,
  updateOrderInfoFlowFailure,
  updateOrderInfoFlowSuccess,
  updateOrderInfoRequest,
  updateOrderInfoFailure,
  updateOrderInfoSuccess,

  createOrderFlowRequest,
  createOrderFlowFailure,
  createOrderFlowSuccess,
  createOrderRequest,
  createOrderFailure,
  createOrderSuccess,

  deleteOrderFlowRequest,
  deleteOrderFlowFailure,
  deleteOrderFlowSuccess,
  deleteOrderRequest,
  deleteOrderFailure,
  deleteOrderSuccess,

  getOrderNumbersRequest,
  getOrderNumbersSuccess,
  getOrderNumbersFailure,
  getOrderNumbersFlowRequest,
  getOrderNumbersFlowSuccess,
  getOrderNumbersFlowFailure,
} = ActionCreator;


// ---------- NORMAL FUNCTIONS ----------
const buildFilterClause = (filterCondition = {}) => {
  try {
    const {
      keyword,
      page = 0,
      ...otherCondition
    } = filterCondition;

    const pageLimit = 10;

    const where = {};
    if (keyword) {
      Object.assign(where, {
        id: keyword,
      });
    }

    if (otherCondition && Object.keys(otherCondition).length > 0) {
      Object.assign(where, otherCondition);
    }

    const assignedFilterCondition = {
      limit: pageLimit,
      skip: pageLimit * page,
      order: 'id DESC',
      where: {
        // due to isHiddenThisOrder is the new state in db.
        or: [
          { isHiddenThisOrder: null },
          { isHiddenThisOrder: false },
        ],
      },
      include: [
        {
          relation: 'userCoupon',
          scope: {
            fields: ['useDate', 'endDate', 'couponId'],
            include: [
              {
                relation: 'coupon',
                scope: {
                  fields: ['percent', 'minus', 'isActive', 'endDate', 'couponCode', 'couponTypeId'],
                  include: [{ relation: 'couponType' }],
                },

              },
            ],
          },
        },
        {
          relation: 'donateOrganization',
        },
        {
          relation: 'user',
          scope: { fields: ['name', 'email'] },
        },
        {
          relation: 'bag',
          scope: { fields: ['name', 'buyoutAmount', 'isOhPhireManage', 'washAmount', 'bagNo'] },
        },
      ],
      ...(R.isEmpty(where) ? {} : { where }),
    };

    return assignedFilterCondition;
  } catch (error) {
    // eslint-disable-next-line
    console.error(error);
    return {};
  }
};


// Task
export function* getOrderList(filterCondition = {}) {
  try {
    yield put(getOrderListRequest());
    const result = yield call(API.getOrderList, buildFilterClause(filterCondition));
    yield put(getOrderListSuccess(result.data));
    return result;
  } catch (error) {
    yield put(getOrderListFailure(error));
    throw (error);
  }
}

export function* filterOrderByKeyword(filterCondition = {}) {
  try {
    yield put(filterOrderByKeywordRequest());
    const result = yield call(API.filterOrderByKeyword, filterCondition);
    yield put(getOrderListSuccess(result.data));
    return result;
  } catch (error) {
    yield put(getOrderListFailure(error));
    throw (error);
  }
}

function* getOrderStatusList() {
  yield put(getOrderStatusListRequest());
  try {
    const result = yield call(API.getOrderStatusList);
    yield put(getOrderStatusListSuccess(result.data));
    return result;
  } catch (error) {
    yield put(getOrderStatusListFailure(error));
    throw (error);
  }
}

function* getOrderNumbers() {
  try {
    yield put(getOrderNumbersRequest());
    const result = yield call(API.getOrderNumbers, { orderStatusId: { eq: 5 } });
    yield put(getOrderNumbersSuccess(result.data));
    return result;
  } catch (error) {
    yield put(getOrderNumbersFailure(error));
    throw (error);
  }
}

function* getDonateOrganizationList() {
  yield put(getDonateOrganizationListRequest());
  try {
    const result = yield call(API.getDonateOrganizationList);

    // add default Data

    result.data.unshift({ id: 0, name: '不指定' });

    yield put(getDonateOrganizationListSuccess(result.data));
    return result;
  } catch (error) {
    yield put(getDonateOrganizationListFailure(error));
    throw (error);
  }
}

function* getReceiptTypeList() {
  yield put(getReceiptTypeListRequest());
  try {
    const result = yield call(API.getReceiptTypeList);
    yield put(getReceiptTypeListSuccess(result.data));
    return result;
  } catch (error) {
    yield put(getReceiptTypeListFailure(error));
    throw (error);
  }
}

function* updateOrderInfo(data) {
  yield put(updateOrderInfoRequest());
  try {
    const {
      id: orderId,
      user,
      bag,
      donateOrganizationId: donateOrgId,
      ...restOfData
    } = (data || {});

    // if donateOrganization.id === '0', it is 不指定, then we assign 'null',
    if (donateOrgId) {
      Object.assign(restOfData,
        { donateOrganizationId: donateOrgId !== '0' ? donateOrgId : null });
    }
    if (user) Object.assign(restOfData, { userId: user.id });
    if (bag) Object.assign(restOfData, { bagId: bag.id });


    // NOTE: Updating order info
    if (!R.isEmpty(restOfData)) {
      yield call(API.updateOrderInfo, {
        ...restOfData,
        id: orderId,
      });
    }

    const result = yield call(API.getOrderList, buildFilterClause({ keyword: orderId }));
    yield put(updateOrderInfoSuccess(result.data[0]));

    return result;
  } catch (error) {
    yield put(updateOrderInfoFailure(error));
    throw (error);
  }
}

function* createOrder(data) {
  yield put(createOrderRequest());
  try {
    const {
      user,
      bag,
      donateOrganizationId: donateOrgId,
      receiptTypeId: selectedReceiptTypeId,
      ...restOfData
    } = data;

    if (donateOrgId) {
      Object.assign(restOfData,
        { donateOrganizationId: donateOrgId !== '0' ? donateOrgId : null });
    }
    if (user) Object.assign(restOfData, { userId: user.id });
    if (bag) Object.assign(restOfData, { bagId: bag.id });

    // id admin use the default receiptType
    if (!selectedReceiptTypeId) Object.assign(restOfData, { receiptTypeId: receiptTypeId.eReceipt });

    const {
      data: {
        id: orderId,
      } = {},
    } = yield call(API.createOrder, restOfData) || {};

    const result = yield call(API.getOrderList, buildFilterClause({ keyword: orderId }));

    yield put(createOrderSuccess(result.data[0]));
    return result;
  } catch (error) {
    yield put(createOrderFailure(error));
    throw (error);
  }
}

function* deleteOrder(data) {
  yield put(deleteOrderRequest());
  try {
    const result = yield call(API.deleteOrder, data);
    yield put(deleteOrderSuccess(data));
    return result;
  } catch (error) {
    yield put(deleteOrderFailure(error));
    throw (error);
  }
}


// Flow
export function* searchFlow({ payload }) {
  try {
    const result = yield call(getOrderList, payload);
    yield put(searchFlowSuccess({
      condition: payload,
      data: result.data,
    }));
  } catch (error) {
    yield put(searchFlowFailure(error));
  }
}

export function* filterOrderByKeywordFlow({ payload }) {
  try {
    const result = yield call(filterOrderByKeyword, payload);
    yield put(searchFlowSuccess({
      condition: payload,
      data: result.data,
    }));
  } catch (error) {
    yield put(searchFlowFailure(error));
  }
}

export function* getOrderNumbersFlow({ payload }) {
  try {
    const result = yield call(getOrderNumbers, payload);
    yield put(getOrderNumbersFlowSuccess(result.data.count));
  } catch (error) {
    yield put(getOrderNumbersFlowFailure(error));
  }
}

export function* getOrderListFlow({ payload }) {
  try {
    const result = yield call(getOrderList, payload);
    yield put(getOrderListFlowSuccess({
      condition: payload,
      data: result.data,
    }));
  } catch (error) {
    yield put(getOrderListFlowFailure(error));
  }
}

export function* getOrderStatusListFlow({ payload }) {
  try {
    const result = yield call(getOrderStatusList, payload);
    yield put(getOrderStatusListFlowSuccess(result.data));
  } catch (error) {
    yield put(getOrderStatusListFlowFailure(error));
  }
}

export function* getDonateOrganizationListFlow({ payload }) {
  try {
    const result = yield call(getDonateOrganizationList, payload);
    yield put(getDonateOrganizationListFlowSuccess(result.data));
  } catch (error) {
    yield put(getDonateOrganizationListFlowFailure(error));
  }
}

export function* getReceiptTypeListFlow({ payload }) {
  try {
    const result = yield call(getReceiptTypeList, payload);
    yield put(getReceiptTypeListFlowSuccess(result.data));
  } catch (error) {
    yield put(getReceiptTypeListFlowFailure(error));
  }
}
export function* updateOrderInfoFlow({ payload }) {
  try {
    const result = yield call(updateOrderInfo, payload);
    yield put(updateOrderInfoFlowSuccess(result.data));
  } catch (error) {
    yield put(updateOrderInfoFlowFailure(error));
  }
}

export function* createOrderFlow({ payload }) {
  try {
    yield call(createOrder, payload);
    yield put(createOrderFlowSuccess(payload));
  } catch (error) {
    yield put(createOrderFlowFailure(error));
  }
}

export function* deleteOrderFlow({ payload }) {
  try {
    yield call(deleteOrder, payload);
    yield put(deleteOrderFlowSuccess(payload));
  } catch (error) {
    yield put(deleteOrderFlowFailure(error));
  }
}

export default [
  takeLatest(getOrderListFlowRequest, getOrderListFlow),
  takeLatest(searchFlowRequest, searchFlow),
  takeLatest(filterOrderByKeywordFlowRequest, filterOrderByKeywordFlow),
  takeLatest(getOrderStatusListFlowRequest, getOrderStatusListFlow),
  takeLatest(getReceiptTypeListFlowRequest, getReceiptTypeListFlow),
  takeLatest(getDonateOrganizationListFlowRequest, getDonateOrganizationListFlow),
  takeLatest(updateOrderInfoFlowRequest, updateOrderInfoFlow),
  takeLatest(createOrderFlowRequest, createOrderFlow),
  takeLatest(deleteOrderFlowRequest, deleteOrderFlow),
  takeLatest(getOrderNumbersFlowRequest, getOrderNumbersFlow),
];
