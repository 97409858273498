import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Input,
} from 'antd';
import ModalComponent from './ModalComponent';

const InputDialog = ({
  inputProps = {},
  onSubmit,
  ...otherProps
}) => {
  const [inputValue, setInputValue] = useState('');
  const onModalClose = () => {
    setInputValue('');
    onSubmit('');
  };
  const onModalConfirmed = (event) => {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }
    setInputValue('');
    onSubmit(inputValue);
  };
  return (
    <ModalComponent
      {...otherProps}
      onModalClose={onModalClose}
      onModalConfirmed={onModalConfirmed}
      content={(
        <form onSubmit={onModalConfirmed}>
          <Input
            ref={ref => ref && ref.focus()}
            {...inputProps}
            value={inputValue}
            onChange={event => setInputValue(event.target.value)}
          />
        </form>
      )}
    />
  );
};

InputDialog.propTypes = {
  onSubmit: PropTypes.func,
  inputProps: PropTypes.shape({}),
};

InputDialog.defaultProps = {
  onSubmit: () => {},
  inputProps: {},
};

export default InputDialog;
