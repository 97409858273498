module.exports = [
  {
    name: '新品上架優先',
    order: 'createTime desc',
  }, {
    name: '租金由低至高',
    order: 'rentAmountPerDay asc',
  }, {
    name: '租金由高至低',
    order: 'rentAmountPerDay desc',
  }, {
    name: '買斷價由低至高',
    order: 'buyoutAmount asc',
  }, {
    name: '買斷價由高至低',
    order: 'buyoutAmount desc',
  },
];
