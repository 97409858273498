import api from '../../lib/configAxios';

export default {

  getOrderList: async filter => api.get(
    `Orders?filter=${encodeURIComponent(JSON.stringify(filter))}`,
  ),
  filterOrderByKeyword: async filter => api.post(
    'Orders/filterByKeyword', filter,
  ),
  getOrderNumbers: async filter => api.get(
    `Orders/count?where=${encodeURIComponent(JSON.stringify(filter))}`,
  ),
  getOrderStatusList: () => api.get(
    'OrderStatuses',
  ),
  getDonateOrganizationList: () => api.get(
    'DonateOrganizations',
  ),
  getReceiptTypeList: () => api.get(
    'ReceiptTypes',
  ),
  updateOrderInfo: async data => api.patch(
    `Orders/${data.id}`, data,
  ),
  createOrder: async data => api.post(
    'Orders', data,
  ),
  deleteOrder: async data => api.post(
    'Orders/verifyAndDelete', data,
  ),
};
