import { createActions } from 'redux-actions';
import { createAsyncActionTypesArray } from '../../utils/createAsyncActionTypes';

export default createActions(
  // ----- TASKS -----
  ...createAsyncActionTypesArray('GET_TAG_LIST'),
  ...createAsyncActionTypesArray('GET_TAG_CATEGORY_LIST'),
  ...createAsyncActionTypesArray('UPDATE_TAG_INFO'),
  ...createAsyncActionTypesArray('DELETE_TAG'),
  ...createAsyncActionTypesArray('CREATE_TAG'),

  // ----- FLOWS -----
  ...createAsyncActionTypesArray('GET_TAG_LIST_FLOW'),
  ...createAsyncActionTypesArray('UPDATE_TAG_INFO_FLOW'),
  ...createAsyncActionTypesArray('DELETE_TAG_FLOW'),
  ...createAsyncActionTypesArray('CREATE_TAG_FLOW'),

  'RESET_STATUS',

  { prefix: 'TAG_MANAGEMENT' },
);
