import React from 'react';

const renderSeparator = id => (
  <tr key={id}>
    <td colSpan="2">
      <hr />
    </td>
  </tr>
);

export default renderSeparator;
