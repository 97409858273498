import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { Button } from 'antd';
import BagStatus from '../../../../constants/BagStatus';
import BagSortOrder from '../../../../constants/BagSortOrder';
import Type from '../../../../constants/Type';

const styles = {
  mainContainer: {
    margin: 20,
  },
  table: {
    margin: '10px 0px',
  },
  categoryColumnStyle: {
    width: 50,
    padding: 10,
    borderBottom: '1px solid #33333333',
  },
  optionsColumnStyle: {
    borderBottom: '1px solid #33333333',
  },
  inputBox: {
    width: '100%',
    height: 50,
    padding: 10,
    fontSize: 16,
    outline: 'none',
    borderRadius: 5,
    border: '1px solid #33333333',
  },
  tag: {
    margin: 5,
    display: 'inline-block',
  },
  checkbox: {
    margin: 5,
  },
};

const BagSearchPanel = ({
  handleSearchConditionChange,
  tags,
  condition,
}) => {
  // NOTE: checkBoxRefs is a double layered array of refs.
  //       The first layer is to distinguish the category of tags. The second layer contains each refs of checkboxes.
  //       Therefore the `checkBoxRefs` would look like in this shape:
  //       [
  //           [checkbox1, checkbox2, checkbox3, checkbox4, ...],
  //           [checkbox1, checkbox2, checkbox3, checkbox4, ...],
  //           ...
  //       ]
  const checkBoxRefs = [];
  const bagStatusOptionBoxRefs = [];
  const bagTypeOptionBoxRefs = [];
  const sortOrderOptionBoxRefs = [];
  const inputBoxRef = useRef(null);
  const inputBoxOwnerRef = useRef(null);
  const [isSelectedModifiedBuyoutAmount, setIsSelectedModifiedBuyoutAmount] = useState(false);
  const [isSelectedDefaultOrder, setIsSelectedDefaultOrder] = useState(false);

  const submitFilter = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const newKeyword = R.pathOr(null, ['current', 'value'], inputBoxRef);
    const newOwnerId = R.pathOr(null, ['current', 'value'], inputBoxOwnerRef);
    const newSelectedTags = checkBoxRefs
      .map(tagRefs => tagRefs
        .map(ref => (ref.checked ? ref.value : null))
        .filter(d => d))
      .filter(d => !R.isEmpty(d));
    const newSelectedBagStatus = parseInt(((bagStatusOptionBoxRefs.find(ref => ref.checked) || {}).value) || 0, 10);
    const newSelectedBagType = parseInt(((bagTypeOptionBoxRefs.find(ref => ref.checked) || {}).value) || 0, 10);
    const newSelectedSortOrder = parseInt(((sortOrderOptionBoxRefs.find(ref => ref.checked) || {}).value) || 0, 10);

    handleSearchConditionChange({
      ...(newKeyword ? { keyword: newKeyword } : {}),
      ...(newOwnerId ? { ownerId: newOwnerId } : {}),
      ...(R.isEmpty(newSelectedTags) ? {} : { tags: newSelectedTags }),
      ...(newSelectedBagStatus === 0 ? {} : { bagStatus: newSelectedBagStatus }),
      ...(newSelectedBagType === 0 ? {} : { bagType: newSelectedBagType }),
      ...(newSelectedSortOrder === 0 ? {} : { sortOrder: newSelectedSortOrder }),
      ...(isSelectedModifiedBuyoutAmount ? { modifiedBuyoutAmountRequest: { neq: null } } : { }),
      ...(isSelectedDefaultOrder ? { defaultOrder: { neq: null } } : { }),
    });
  };

  const selectedTags = R.pathOr([], ['tags'], condition).flat();
  const selectedBagStatus = R.pathOr(0, ['bagStatus'], condition);
  const selectedBagType = R.pathOr(0, ['bagType'], condition);
  const selectedSortOrder = R.pathOr(0, ['sortOrder'], condition);

  return (
    <div style={styles.mainContainer}>
      <form onSubmit={submitFilter}>
        <input
          ref={inputBoxRef}
          placeholder="搜尋包包名字"
          style={styles.inputBox}
          defaultValue={R.pathOr('', ['keyword'], condition)}
        />
        <input
          ref={inputBoxOwnerRef}
          placeholder="輸入包主id搜尋"
          style={styles.inputBox}
          defaultValue={R.pathOr('', ['ownerId'], condition)}
        />

        <table style={styles.table}>
          <tbody>
            {/* Render the bag tag selector */}
            {
              tags.map(({
                id: tagCategoryId,
                name: tagCategoryName,
                tags: tagItems,
              }) => {
                // Prepare the first layer of array (see the description of checkBoxRefs)
                const refArray = [];
                checkBoxRefs.push(refArray);
                return (
                  <tr key={tagCategoryId}>
                    <td style={styles.categoryColumnStyle}>{tagCategoryName}</td>
                    <td style={styles.optionsColumnStyle}>
                      {
                        tagItems.map(({
                          name: tagName,
                          id: tagId,
                        }) => {
                          const defaultChecked = !!selectedTags.find(selectedTag => `${selectedTag}` === `${tagId}`);
                          return (
                            <span style={styles.tag} key={`${tagId}_${defaultChecked}`}>
                              <input
                                type="checkbox"
                                style={styles.checkbox}
                              // Put the ref to the second-layered array (see the description of checkBoxRefs)
                                ref={ref => refArray.push(ref)}
                                value={tagId}
                                defaultChecked={defaultChecked}
                              />
                              {tagName}
                            </span>
                          );
                        })
                      }
                    </td>
                  </tr>
                );
              })
            }

            {/* Render the bag status selector */}
            <tr key="bagStatusOptions">
              <td style={styles.categoryColumnStyle}>狀態</td>
              <td style={styles.optionsColumnStyle}>
                <fieldset id="bagStatusOption">
                  {
                    [
                      [0, '顯示全部'],
                      ...(Object.keys(BagStatus.bagStatusId)
                        .sort((status1, status2) => (BagStatus.bagStatusId[status1] - BagStatus.bagStatusId[status2]))
                        .map(status => ([BagStatus.bagStatusId[status], BagStatus.bagStatus[status]]))),
                    ].map(([value, text]) => {
                      const defaultChecked = `${selectedBagStatus}` === `${value}`;
                      return (
                        <span style={styles.tag} key={`${value}_${defaultChecked}`}>
                          <input
                            type="radio"
                            value={value}
                            name="bagStatusOption"
                            style={styles.checkbox}
                            ref={(ref) => { bagStatusOptionBoxRefs.push(ref); }}
                            defaultChecked={defaultChecked}
                          />
                          {text}
                        </span>
                      );
                    })
                  }
                </fieldset>
              </td>
            </tr>

            {/* Render the bag type selector */}
            <tr key="bagTypeOptions">
              <td style={styles.categoryColumnStyle}>類型</td>
              <td style={styles.optionsColumnStyle}>
                <fieldset id="bagTypeOptions">
                  {
                    [
                      [0, '顯示全部'],
                      ...(Object.keys(Type.typeId)
                        .map(type => ([Type.typeId[type], Type.transactionType[type]]))),
                    ].map(([value, text]) => {
                      const defaultChecked = `${selectedBagType}` === `${value}`;
                      return (
                        <span style={styles.tag} key={`${text}_${value}_${defaultChecked}`}>
                          <input
                            type="radio"
                            value={value}
                            name="bagTypeOptions"
                            style={styles.checkbox}
                            ref={(ref) => { bagTypeOptionBoxRefs.push(ref); }}
                            defaultChecked={defaultChecked}
                          />
                          {text}
                        </span>
                      );
                    })
                  }
                </fieldset>
              </td>
            </tr>


            {/* Render the sort order selector */}
            <tr key="sortOrderOptions">
              <td style={styles.categoryColumnStyle}>排序</td>
              <td style={styles.optionsColumnStyle}>
                <fieldset id="sortOrderOptions">
                  {
                    BagSortOrder.map(({
                      name,
                      order,
                    }, id) => {
                      const defaultChecked = `${selectedSortOrder}` === `${id}`;
                      return (
                        <span style={styles.tag} key={`${name}_${order}_${defaultChecked}`}>
                          <input
                            type="radio"
                            value={id}
                            name="sortOrderOptions"
                            style={styles.checkbox}
                            ref={(ref) => { sortOrderOptionBoxRefs.push(ref); }}
                            defaultChecked={defaultChecked}
                          />
                          {name}
                        </span>
                      );
                    })
                  }
                </fieldset>
              </td>
            </tr>

            <tr key="modifiedBuyoutAmount">
              <td style={styles.categoryColumnStyle}>修改要求</td>
              <td style={styles.optionsColumnStyle}>
                <fieldset id="modifiedBuyoutAmount">
                  <span style={styles.tag}>
                    <input
                      type="checkbox"
                      value={isSelectedModifiedBuyoutAmount}
                      style={styles.checkbox}
                      onClick={() => setIsSelectedModifiedBuyoutAmount(!isSelectedModifiedBuyoutAmount)}
                      defaultChecked={isSelectedModifiedBuyoutAmount}
                    />
                    是
                  </span>
                </fieldset>
              </td>
            </tr>

            <tr key="defaultOrder">
              <td style={styles.categoryColumnStyle}>自訂順序</td>
              <td style={styles.optionsColumnStyle}>
                <fieldset id="defaultOrder">
                  <span style={styles.tag}>
                    <input
                      type="checkbox"
                      value={isSelectedDefaultOrder}
                      style={styles.checkbox}
                      onClick={() => setIsSelectedDefaultOrder(!isSelectedDefaultOrder)}
                      defaultChecked={isSelectedDefaultOrder}
                    />
                    是
                  </span>
                </fieldset>
              </td>
            </tr>

          </tbody>
        </table>

        <button type="submit" hidden>搜尋</button>
        <Button type="submit" onClick={submitFilter}>搜尋</Button>
      </form>
    </div>
  );
};

BagSearchPanel.propTypes = {
  handleSearchConditionChange: PropTypes.func.isRequired,
  tags: PropTypes.array,
  condition: PropTypes.shape({}),
};

BagSearchPanel.defaultProps = {
  tags: [],
  condition: {},
};

export default BagSearchPanel;
