export const SIGN_OUT = '登出';
export const MARQUEE_MANAGEMENT = '跑馬燈管理';
export const MEMBER_MANAGEMENT = '會員管理';
export const IDENTIFICATION_MANAGEMENT = '身份認證管理';
export const TAG_MANAGEMENT = '標籤管理';
export const BAG_MANAGEMENT = '包包管理';
export const ORDER_MANAGEMENT = '訂單管理';
export const CONTACT = '聯絡資料';
export const SUBSCRIBE = '訂閱管理';
export const CART_MANAGEMENT = '購物車管理';
export const THEME_MANAGEMENT = '主題活動管理';
export const COMMENT_MANAGEMENT = '評論管理';
export const NEGOTIATION_MANAGEMENT = '議價管理';
export const REDUCE_DEPOSIT_MANAGEMENT = '減免押金管理';
export const WITHDRAW_MANAGEMENT = '提領管理';
export const CLOTHES_MANAGEMENT = '衣服管理';
export const ACCESSORIES_MANAGEMENT = '配件管理';
export const ESSENCE_MANAGEMENT = '保養管理';
export const TAPPAT_PAY_BY_TOKEN_MANAGEMENT = 'TapPay payByToken';
export const COUPON_MANAGEMENT = '優惠券管理';
export const ADDRESS_MANAGEMENT = '地址管理';
export default {};
