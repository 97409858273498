import React from 'react';
import { Router } from '@reach/router';
import Paths from './Paths';
import { commodityTypeId } from '../constants/Type';
import GeneralLayout from '../components/layouts/GeneralLayout';
import LoginContainer from '../modules/Auth/components/LoginScreen';
import LogoutContainer from '../modules/Auth/components/LogoutScreen';
import DashboardContainer from '../modules/Dashboard/components/DashboardPage';
import MemberManagementContainer from '../modules/MemberManagement/components/MemberManagementPage';
import TagManagementContainer from '../modules/TagManagement/components/TagManagementPage';
import BagManagementContainer from '../modules/BagManagement/components/BagManagementPage';
import OrderManagementContainer from '../modules/OrderManagement/components/OrderManagementPage';
import ContactManagementContainer from '../modules/ContactManagement/components/ContactManagementPage';
import SubscribeManagementContainer from '../modules/SubscribeManagement/components/SubscribeManagementPage';
import CartManagementContainer from '../modules/CartManagement/components/CartManegementPage';
import ThemeManagementContainer from '../modules/ThemeManagement/components/ThemeManagementPage';
import CommentManagementContainer from '../modules/CommentManagement/components/CommentManagementPage';
import NegotiationManagementContainer from '../modules/NegotiationManagement/components/NegotiationManagementPage';
import ReduceDepositManagementContainer from '../modules/ReduceDepositManagement/components/ReduceDepositmanagementPage';
import WithdrawManagementContainer from '../modules/WithdrawManagement/components/WithdrawManagementPage';
import PayByTokenContainer from '../modules/PayByTokenManagement/componenets/PayByTokenManagementPage';
import CouponManagementContainer from '../modules/CouponManagement/components/CouponManagement';
import AddressManagementContainer from '../modules/AddressManagement/components/AddressManagementPage';
import MarqueeManagementContainer from '../modules/MarqueeManagement/components/MarqueeManagementPage';
import IdentificationManagementContainer from '../modules/IdentificationManagement/components/IdentificationManagementPage';

const {
  DASHBOARD,
  SIGN_IN,
  SIGN_OUT,
  MEMBER_MANAGEMENT,
  TAG_MANAGEMENT,
  BAG_MANAGEMENT,
  ORDER_MANAGEMENT,
  CONTACT,
  SUBSCRIBE,
  CART_MANAGEMENT,
  THEME_MANAGEMENT,
  COMMENT_MANAGEMENT,
  NEGOTIATION_MANAGEMENT,
  REDUCE_DEPOSIT_MANAGEMENT,
  WITHDRAW_MANAGEMENT,
  CLOTHES_MANAGEMENT,
  TAPPAY_PAY_BY_TOKEN_MANAGEMENT,
  COUPON_MANAGEMENT,
  ACCESSORIES_MANAGEMENT,
  ESSENCE_MANAGEMENT,
  ADDRESS_MANAGEMENT,
  MARQUEE_MANAGEMENT,
  IDENTIFICATION_MANAGEMENT,
} = Paths;

const RouterList = () => (
  <Router>

    <GeneralLayout
      path={DASHBOARD}
      Child={DashboardContainer}
    />

    <GeneralLayout
      path={IDENTIFICATION_MANAGEMENT}
      Child={IdentificationManagementContainer}
    />

    <GeneralLayout
      path={MEMBER_MANAGEMENT}
      Child={MemberManagementContainer}
    />

    <GeneralLayout
      path={TAG_MANAGEMENT}
      Child={TagManagementContainer}
    />

    <GeneralLayout
      path={BAG_MANAGEMENT}
      Child={BagManagementContainer}
      commodityType={commodityTypeId.bag}
    />

    <GeneralLayout
      path={THEME_MANAGEMENT}
      Child={ThemeManagementContainer}
    />

    <GeneralLayout
      path={ORDER_MANAGEMENT}
      Child={OrderManagementContainer}
    />

    <GeneralLayout
      path={CART_MANAGEMENT}
      Child={CartManagementContainer}
    />

    <GeneralLayout
      path={COMMENT_MANAGEMENT}
      Child={CommentManagementContainer}
    />

    <GeneralLayout
      path={NEGOTIATION_MANAGEMENT}
      Child={NegotiationManagementContainer}
    />

    <LogoutContainer
      path={SIGN_OUT}
    />

    <LoginContainer
      path={SIGN_IN}
      default
    />

    <GeneralLayout
      path={CONTACT}
      Child={ContactManagementContainer}
    />

    <GeneralLayout
      path={SUBSCRIBE}
      Child={SubscribeManagementContainer}
    />

    <GeneralLayout
      path={REDUCE_DEPOSIT_MANAGEMENT}
      Child={ReduceDepositManagementContainer}
    />

    <GeneralLayout
      path={WITHDRAW_MANAGEMENT}
      Child={WithdrawManagementContainer}
    />
    <GeneralLayout
      path={TAPPAY_PAY_BY_TOKEN_MANAGEMENT}
      Child={PayByTokenContainer}
    />

    <GeneralLayout
      path={COUPON_MANAGEMENT}
      Child={CouponManagementContainer}
    />

    <GeneralLayout
      path={CLOTHES_MANAGEMENT}
      Child={BagManagementContainer}
      commodityType={commodityTypeId.clothes}
    />

    <GeneralLayout
      path={ACCESSORIES_MANAGEMENT}
      Child={BagManagementContainer}
      commodityType={commodityTypeId.accessories}
    />

    <GeneralLayout
      path={ESSENCE_MANAGEMENT}
      Child={BagManagementContainer}
      commodityType={commodityTypeId.essence}
    />

    <GeneralLayout
      path={ADDRESS_MANAGEMENT}
      Child={AddressManagementContainer}
    />

    <GeneralLayout
      path={MARQUEE_MANAGEMENT}
      Child={MarqueeManagementContainer}
    />

  </Router>
);

export default RouterList;
