import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';

const styles = {
  bagTag: {
    padding: 5,
    margin: 5,
    borderRadius: 3,
  },
};

const BagTagComponent = ({
  tagName,
  onClick,
}) => (
  <Button style={styles.bagTag} onClick={onClick} type="button">
    {tagName}
  </Button>
);

BagTagComponent.propTypes = {
  tagName: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

BagTagComponent.defaultProps = {
  tagName: '',
};

export default BagTagComponent;
