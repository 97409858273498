import {
  fork,
  put,
  take,
} from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';
import { navigate } from '@reach/router';
import Paths from '../../routes/Paths';
import axios from '../../lib/configAxios';
import ActionCreators from './ActionCreators';
import * as LocalStorageUtils from '../../utils/LocalStorageUtils';
import LocalStorageKeys from '../../constants/LocalStorageKeys';
import AuthActionCreators from '../Auth/ActionCreators';

const {
  initAppRequest,
  initAppSuccess,
  initAppFailure,
} = ActionCreators;
const {
  userSignInSuccess,
} = AuthActionCreators;

export function* initAppController() {
  // NOTE: We can do the initialization here
  yield put(initAppRequest());
  try {
    // NOTE: Restore the user login status
    const token = LocalStorageUtils.readData(LocalStorageKeys.userToken, null);
    const userId = LocalStorageUtils.readData(LocalStorageKeys.userId, null);
    if (token && userId) {
      axios.setToken(token);
      yield put(userSignInSuccess({
        userId,
        token,
      }));
    } else {
      navigate(Paths.SIGN_IN);
    }

    yield put(initAppSuccess());
  } catch (error) {
    yield put(initAppFailure());
  }
}

export function* authenticationErrorController() {
  // NOTE: Register a listener for authentication error (for example, token expired)
  //       and auto sign-out when this kind of error take place.
  const authenticationErrorChannel = eventChannel((emit) => {
    axios.setAuthenticationErrorHandler(() => emit(''));
    return () => {};
  });
  yield take(authenticationErrorChannel);
  navigate(Paths.SIGN_OUT);
}

export default [
  fork(initAppController),
  fork(authenticationErrorController),
];
