import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ActionCreators from '../../ActionCreator';
import AddressManagementPage from './AddressManagementPage';

import {
  addressListSelector,
  reduxStatusSelector,
  reduxErrorSelector,
} from '../../selector';

const AddressManagementPageContainer = ({
  actions: {
    updateAddressInfoFlowRequest,
    getAddressListFlowRequest,
  },
  data,
  reduxError,
  reduxStatus,
}) => {
  useEffect(() => {
    getAddressListFlowRequest();
  }, []);


  return (
    <AddressManagementPage
      reduxStatus={reduxStatus}
      reduxError={reduxError}
      data={data}
      updateAddressInfo={updateAddressInfoFlowRequest}
    />
  );
};

AddressManagementPageContainer.propTypes = {
  actions: PropTypes.shape({
    getAddressListFlowRequest: PropTypes.func.isRequired,
    updateAddressInfoFlowRequest: PropTypes.func.isRequired,
  }).isRequired,
  reduxStatus: PropTypes.string,
  data: PropTypes.array,
  reduxError: PropTypes.string,
};

AddressManagementPageContainer.defaultProps = {
  data: [],
  reduxStatus: null,
  reduxError: '',
};

const mapStateToProps = state => ({
  data: addressListSelector(state),
  reduxStatus: reduxStatusSelector(state),
  reduxError: reduxErrorSelector(state),

});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    ...ActionCreators,
  }, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddressManagementPageContainer);
