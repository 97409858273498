import api from '../../lib/configAxios';

export default {

  getMarqueeList: async () => api.get(
    '/Marquees',
  ),

  updateMarqueeInfo: async data => api.patch(
    'Marquees/1', {
      text: encodeURIComponent(data.text),
    },
  ),
};
