import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { Button, Modal } from 'antd';
import FormTableComponent from '../../../../components/FormTable/FormTableComponent';
import MemberManagementContainer from '../../../MemberManagement/components/MemberManagementPage';
import BagManagementContainer from '../../../BagManagement/components/BagManagementPage';

const ReduceDepositInfoComponent = ({
  reduceDepositInfo,
  cancelSelectReduceDeposit,
  createReduceDeposit,
  deleteReduceDeposit,
}) => {
  const [showMemberSelector, setShowMemberSelector] = useState(false);
  const [showBagSelector, setShowBagSelector] = useState(false);
  const isCreating = Object.keys(reduceDepositInfo || {}).length === 0;

  const bagRowMetaData = {
    key: 'bag',
    name: '包款',
    type: 'custom',
    renderChild: (modifiedValue, isModified, handleChange) => [
      <span key="name">
        {modifiedValue ? R.pathOr('(沒有名字)', ['name'], modifiedValue) : R.pathOr('(沒有名字)', ['bag', 'name'], reduceDepositInfo)}
      </span>,
      <span key="button"><Button size="small" onClick={() => { setShowBagSelector(true); }}>...</Button></span>,
      <Modal
        key="bagSelector"
        title="選擇包款"
        visible={showBagSelector}
        width="90%"
        onCancel={() => { setShowBagSelector(false); }}
      >
        <BagManagementContainer
          onBagSelected={(bag) => {
            setShowBagSelector(false);
            if (!bag) return;
            handleChange(bag);
          }}
        />
      </Modal>,
    ],
  };

  const userRowMetaData = {
    key: 'user',
    name: '減免押金User',
    type: 'custom',
    renderChild: (modifiedValue, isModified, handleChange) => [
      <span key="name">
        {modifiedValue ? R.pathOr('(沒有名字)', ['name'], modifiedValue) : R.pathOr('(沒有名字)', ['user', 'name'], reduceDepositInfo)}
        (
        {modifiedValue ? R.pathOr('', ['email'], modifiedValue) : R.pathOr('', ['user', 'email'], reduceDepositInfo)}
        )
      </span>,
      <span key="button"><Button size="small" onClick={() => { setShowMemberSelector(true); }}>...</Button></span>,
      <Modal
        key="memberSelector"
        title="選擇會員"
        visible={showMemberSelector}
        onCancel={() => { setShowMemberSelector(false); }}
      >
        <MemberManagementContainer
          onMemberSelected={(member) => {
            setShowMemberSelector(false);
            if (!member) return;
            handleChange(member);
          }}
        />
      </Modal>,
    ],
  };

  const renderTableRows = renderField => [
    renderField({ key: 'id', name: '減免押金ID', disabled: true }),
    renderField(userRowMetaData),
    renderField(bagRowMetaData),
  ];
  return (
    <FormTableComponent
      key="FormTableComponent"
      data={reduceDepositInfo}
      updateData={() => {}}
      cancelSelection={cancelSelectReduceDeposit}
      renderTableRows={renderTableRows}
      createData={createReduceDeposit}
      isCreating={isCreating}
      deleteData={deleteReduceDeposit}
    />
  );
};

ReduceDepositInfoComponent.propTypes = {
  reduceDepositInfo: PropTypes.shape({}),
  cancelSelectReduceDeposit: PropTypes.func.isRequired,
  createReduceDeposit: PropTypes.func.isRequired,
  deleteReduceDeposit: PropTypes.func.isRequired,
};

ReduceDepositInfoComponent.defaultProps = {
  reduceDepositInfo: null,
};

export default ReduceDepositInfoComponent;
