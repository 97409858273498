import { handleActions, combineActions } from 'redux-actions';
import update from 'immutability-helper';
import ReduxStatus from '../../constants/ReduxStatus';
import ActionCreator from './ActionCreator';

const {
  getNegotiationListRequest,
  getNegotiationListSuccess,
  getNegotiationListFailure,
  submitNegotiationRequest,
  submitNegotiationSuccess,
  submitNegotiationFailure,
  dealNegotiationFlowRequest,
  dealNegotiationSuccess,
  dealNegotiationFailure,
  resetStatus,
} = ActionCreator;

const initialState = {
  reduxStatus: ReduxStatus.STATUS_INITIAL,
  error: null,
  data: [],
};

export default handleActions({
  [resetStatus]: state => update(state, {
    reduxStatus: { $set: initialState.reduxStatus },
  }),

  [combineActions(
    getNegotiationListRequest,
    submitNegotiationRequest,
    dealNegotiationFlowRequest,
  )]: state => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_LOADING },
    error: { $set: null },
  }),

  [combineActions(
    getNegotiationListFailure,
    submitNegotiationFailure,
    dealNegotiationFailure,
  )]: (state, { payload }) => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_ERROR },
    error: { $set: payload },
  }),

  [getNegotiationListSuccess]: (state, { payload }) => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_NORMAL },
    data: { $set: payload.data },
    error: { $set: null },
  }),

  [combineActions(
    submitNegotiationSuccess,
    dealNegotiationSuccess,
  )]: state => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_NORMAL },
  }),

}, initialState);
