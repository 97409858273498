import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Table, Button, Input, Modal,
} from 'antd';
import * as R from 'ramda';
import ReduxStatusVisualizedComponent from '../../../../components/ReduxStatusVisualizedComponent';
import CommentInfoComponent from './CommentInfoComponent';
import MemberManagementContainer from '../../../MemberManagement/components/MemberManagementPage';
import BagManagementContainer from '../../../BagManagement/components/BagManagementPage';

const styles = {
  mainContainer: {
    margin: 20,
  },
  searchBarStyle: {
    margin: '10px 0',
    width: 300,
  },
  loadMoreButton: {
    margin: '20px 0',
    width: '100%',
  },
  searchContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    justifyContent: 'start',
  },
  operateContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'start',
  },
  buttonMargin: {
    marginLeft: 10,
  },
  buttonMarginBottom: {
    marginBottom: 5,
  },
};

const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    width: 150,
  },
  {
    title: '評論者',
    dataIndex: ['commentByUser', 'name'],
    width: 150,
  },
  {
    title: '包款',
    dataIndex: ['bag', 'name'],
    width: 150,
  },
  {
    title: '評論',
    dataIndex: 'content',
    width: 150,
  },
  {
    title: '是否顯示',
    dataIndex: 'isActive',
    width: 150,
    render: isActive => (isActive ? '是' : '否'),
  },
];

const CommentManagementPage = ({
  reduxStatus,
  reduxError,
  data,
  contentFilter,
  onSearch,
  onContentFilterChanged,
  handleLoadMore,
  updateCommentInfo,
  handleMemberChanged,
  handleBagChanged,
  resetFilterCondition,

  selectedMember,
  selectedBag,
}) => {
  const [selectedComment, setSelectedComment] = useState(null);


  useEffect(() => {
    setSelectedComment(null);
  }, [data]);

  const onRow = record => ({
    onClick: () => {
      setSelectedComment(record);
    },
  });

  const getRowKey = ({ id, name }) => (`${id}.${name}`);
  const [showMemberSelector, setShowMemberSelector] = useState(false);
  const [showBagSelector, setShowBagSelector] = useState(false);

  return (
    <div style={styles.mainContainer} key="baseContainer">

      <Modal
        key="memberSelector"
        title="選擇會員"
        width="90%"
        visible={showMemberSelector}
        onCancel={() => { setShowMemberSelector(false); }}
      >
        <MemberManagementContainer
          onMemberSelected={(member) => {
            setShowMemberSelector(false);
            if (!member) return;
            handleMemberChanged(member);
          }}
        />
      </Modal>

      <Modal
        key="bagSelector"
        title="選擇包包"
        width="90%"
        visible={showBagSelector}
        onCancel={() => { setShowBagSelector(false); }}
      >
        <BagManagementContainer
          onBagSelected={(Bag) => {
            setShowBagSelector(false);
            if (!Bag) return;
            handleBagChanged(Bag);
          }}
        />
      </Modal>

      <div style={styles.searchContainer}>
        <Button type="danger" ghost onClick={resetFilterCondition}>清除搜尋條件</Button>

        <div style={styles.operateContainer}>
          <Input
            placeholder="搜尋評論內容"
            value={contentFilter}
            onChange={event => onContentFilterChanged(event)}
            style={styles.searchBarStyle}
          />
          <Button
            style={styles.buttonMargin}
            onClick={() => setShowMemberSelector(true)}
            key="selectMember"
          >
            {R.isNil(selectedMember) ? '選擇會員' : `會員:${R.pathOr('', ['name'], selectedMember)}`}
          </Button>
          <Button
            style={styles.buttonMargin}
            key="selectBag"
            onClick={() => setShowBagSelector(true)}
          >
            {R.isNil(selectedBag) ? '選擇包包' : `包包${R.pathOr('', ['name'], selectedBag)}`}
          </Button>
        </div>
        <Button
          key="search"
          type="primary"
          icon="search"
          onClick={onSearch}
          style={styles.buttonMarginBottom}
        >
          Search
        </Button>

      </div>

      <ReduxStatusVisualizedComponent
        reduxStatus={reduxStatus}
        showModalOnError
        modalFailureContent={reduxError}
      >
        <Table
          rowKey={getRowKey}
          columns={columns}
          childrenColumnName="Comments"
          dataSource={data}
          expandRowByClick
          onRow={onRow}
          pagination={false}
        />
        <CommentInfoComponent
          commentInfo={selectedComment}
          updateCommentInfo={updateCommentInfo}
          cancelSelectComment={() => setSelectedComment(null)}
        />
        <Button
          style={styles.loadMoreButton}
          onClick={handleLoadMore}
          type="primary"
        >
          載入更多評論資料
        </Button>
      </ReduxStatusVisualizedComponent>

    </div>
  );
};

CommentManagementPage.propTypes = {
  reduxStatus: PropTypes.string.isRequired,
  reduxError: PropTypes.string,
  data: PropTypes.array,
  handleLoadMore: PropTypes.func.isRequired,
  handleMemberChanged: PropTypes.func.isRequired,
  updateCommentInfo: PropTypes.func.isRequired,
  handleBagChanged: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  contentFilter: PropTypes.string,
  resetFilterCondition: PropTypes.func.isRequired,
  selectedMember: PropTypes.object,
  selectedBag: PropTypes.object,
  onContentFilterChanged: PropTypes.func.isRequired,
};

CommentManagementPage.defaultProps = {
  data: [],
  reduxError: null,
  selectedMember: null,
  selectedBag: null,
  contentFilter: '',
};

export default CommentManagementPage;
