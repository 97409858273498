import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ActionCreators from '../../ActionCreator';
import ThemeBagManagementPage from './ThemeBagManagementPage';
import {
  reduxStatusSelector,
  reduxErrorSelector,
  themeBagListSelector,
} from '../../selector';

const ThemeBagManagementPageContainer = ({
  reduxStatus,
  reduxError,
  actions: {
    getThemeBagListFlowRequest,
    linkThemeBagFlowRequest,
    unlinkThemeBagFlowRequest,
    resetStatus,
  },
  themeId,
  data,
}) => {
  useEffect(() => {
    if (themeId) getThemeBagListFlowRequest(themeId);
    return resetStatus;
  }, []);

  const linkThemeBags = (bags) => {
    linkThemeBagFlowRequest({
      bags,
      themeId,
    });
  };

  const unlinkThemeBag = (bagId) => {
    unlinkThemeBagFlowRequest({
      themeId,
      bagId,
    });
  };

  return (
    <ThemeBagManagementPage
      reduxStatus={reduxStatus}
      reduxError={reduxError}
      linkThemeBags={linkThemeBags}
      unlinkThemeBag={unlinkThemeBag}
      data={data}
    />
  );
};

ThemeBagManagementPageContainer.propTypes = {
  reduxStatus: PropTypes.string,
  reduxError: PropTypes.string,
  actions: PropTypes.shape({
    getThemeBagListFlowRequest: PropTypes.func.isRequired,
    linkThemeBagFlowRequest: PropTypes.func.isRequired,
    unlinkThemeBagFlowRequest: PropTypes.func.isRequired,
    resetStatus: PropTypes.func.isRequired,
  }).isRequired,
  data: PropTypes.array,
  themeId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

ThemeBagManagementPageContainer.defaultProps = {
  reduxStatus: null,
  reduxError: null,
  data: [],
  themeId: null,
};

const mapStateToProps = state => ({
  reduxStatus: reduxStatusSelector(state),
  reduxError: reduxErrorSelector(state),
  data: themeBagListSelector(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    ...ActionCreators,
  }, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ThemeBagManagementPageContainer);
