import React from 'react';
import PropTypes from 'prop-types';
import MemberManagementPage from '../../../MemberManagement/components/MemberManagementPage/MemberManagementPage';

const CartManagementPage = ({
  reduxStatus,
  reduxError,
  searchByKeyword,
  updateMemberInfo,
  getCartListFlowRequest,
  data,
  cartList,
  onMemberSelected,
}) => (
  <MemberManagementPage
    reduxStatus={reduxStatus}
    reduxError={reduxError}
    data={data}
    searchByKeyword={searchByKeyword}
    updateMemberInfo={updateMemberInfo}
    onMemberSelected={onMemberSelected}
    type="cartInfo"
    cartList={cartList}
    fetchCartInfo={getCartListFlowRequest}
  />
);

CartManagementPage.propTypes = {
  reduxStatus: PropTypes.string,
  reduxError: PropTypes.string,
  getCartListFlowRequest: PropTypes.func.isRequired,
  searchByKeyword: PropTypes.func.isRequired,
  updateMemberInfo: PropTypes.func.isRequired,
  data: PropTypes.array,
  cartList: PropTypes.array,
  onMemberSelected: PropTypes.func,
};

CartManagementPage.defaultProps = {
  reduxStatus: null,
  reduxError: null,
  data: [],
  cartList: [],
  onMemberSelected: null,
};

export default CartManagementPage;
