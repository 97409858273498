import { createActions } from 'redux-actions';
import { createAsyncActionTypesArray } from '../../utils/createAsyncActionTypes';

export default createActions(
  // ----- TASKS -----
  ...createAsyncActionTypesArray('GET_ORDER_LIST'),
  ...createAsyncActionTypesArray('UPDATE_WITHDRAW_INFO'),
  ...createAsyncActionTypesArray('DELETE_WITHDRAW'),
  ...createAsyncActionTypesArray('SEARCH'),
  ...createAsyncActionTypesArray('GET_ORDER_STATUS_LIST'),
  ...createAsyncActionTypesArray('GET_DONATE_ORGANIZATION_LIST'),
  ...createAsyncActionTypesArray('GET_RECEIPT_TYPE_LIST'),
  ...createAsyncActionTypesArray('GET_WITHDRAW_LIST'),
  ...createAsyncActionTypesArray('GET_UN_REMIT_NUMBERS'),

  // ----- FLOWS -----
  ...createAsyncActionTypesArray('GET_ORDER_LIST_FLOW'),
  ...createAsyncActionTypesArray('UPDATE_WITHDRAW_INFO_FLOW'),
  ...createAsyncActionTypesArray('DELETE_WITHDRAW_FLOW'),
  ...createAsyncActionTypesArray('SEARCH_FLOW'),
  ...createAsyncActionTypesArray('GET_ORDER_STATUS_LIST_FLOW'),
  ...createAsyncActionTypesArray('GET_RECEIPT_TYPE_LIST_FLOW'),
  ...createAsyncActionTypesArray('GET_DONATE_ORGANIZATION_LIST_FLOW'),
  ...createAsyncActionTypesArray('SEARCH_FLOW'),
  ...createAsyncActionTypesArray('GET_WITHDRAW_LIST_FLOW'),
  ...createAsyncActionTypesArray('GET_UN_REMIT_NUMBERS_FLOW'),

  'RESET_STATUS',

  { prefix: 'WITHDRAW_MANAGEMENT' },
);
