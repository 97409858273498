import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ActionCreators from '../../ActionCreator';
import TagManagementPage from './TagManagementPage';
import {
  reduxStatusSelector,
  reduxErrorSelector,
  tagListSelector,
} from '../../selector';

const TagManagementPageContainer = ({
  reduxStatus,
  reduxError,
  actions: {
    getTagListFlowRequest,
    updateTagInfoFlowRequest,
    deleteTagFlowRequest,
    createTagFlowRequest,
    resetStatus,
  },
  data,
  onTagSelected,
}) => {
  useEffect(() => {
    if (!data || Object.keys(data).length === 0) getTagListFlowRequest();
    else resetStatus();
    return resetStatus;
  }, []);

  return (
    <TagManagementPage
      reduxStatus={reduxStatus}
      reduxError={reduxError}
      data={data}
      updateTagInfo={updateTagInfoFlowRequest}
      deleteTag={deleteTagFlowRequest}
      createTag={createTagFlowRequest}
      onTagSelected={onTagSelected}
    />
  );
};

TagManagementPageContainer.propTypes = {
  reduxStatus: PropTypes.string,
  reduxError: PropTypes.string,
  actions: PropTypes.shape({
    getTagListFlowRequest: PropTypes.func.isRequired,
    deleteTagFlowRequest: PropTypes.func.isRequired,
    updateTagInfoFlowRequest: PropTypes.func.isRequired,
    createTagFlowRequest: PropTypes.func.isRequired,
    resetStatus: PropTypes.func.isRequired,
  }).isRequired,
  data: PropTypes.shape({}),
  onTagSelected: PropTypes.func,
};

TagManagementPageContainer.defaultProps = {
  reduxStatus: null,
  reduxError: null,
  data: {},
  onTagSelected: null,
};

const mapStateToProps = state => ({
  reduxStatus: reduxStatusSelector(state),
  reduxError: reduxErrorSelector(state),
  data: tagListSelector(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    ...ActionCreators,
  }, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TagManagementPageContainer);
