import { handleActions, combineActions } from 'redux-actions';
import update from 'immutability-helper';
import * as R from 'ramda';
import ActionCreator from './ActionCreator';
import ReduxStatus from '../../constants/ReduxStatus';

const {
  getCommentListFlowRequest,
  getCommentListFlowSuccess,
  getCommentListFlowFailure,

  searchFlowRequest,
  searchFlowSuccess,
  searchFlowFailure,

  updateCommentInfoRequest,
  updateCommentInfoSuccess,
  updateCommentInfoFailure,
} = ActionCreator;

const initialState = {
  reduxStatus: ReduxStatus.STATUS_INITIAL,
  error: null,
  data: [],
};

export default handleActions({
  [combineActions(
    getCommentListFlowRequest,
    updateCommentInfoRequest,
    searchFlowRequest,
  )]: state => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_LOADING },
    error: { $set: initialState.error },
  }),

  [combineActions(
    getCommentListFlowSuccess,
    searchFlowSuccess,
  )]: (state, { payload }) => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_NORMAL },
    data: R.pathOr(0, ['condition', 'page'], payload) === 0
      ? { $set: payload.data }
      : { $push: payload.data },
  }),
  [updateCommentInfoSuccess]: (state, { payload }) => {
    const {
      id,
    } = payload;
    return update(state, {
      reduxStatus: { $set: ReduxStatus.STATUS_NORMAL },
      data: {
        $set: R.pathOr([], ['data'], state).map((comment) => {
          if (comment.id === id) return payload;
          return comment;
        }),
      },
    });
  },

  [combineActions(
    getCommentListFlowFailure,
    searchFlowFailure,
    updateCommentInfoFailure,
  )]: (state, { payload }) => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_ERROR },
    error: { $set: payload },
  }),
}, initialState);
