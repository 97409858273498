import React from 'react';
import Logo from '../../../../components/Logo';

const styles = {
  mainContainer: {
    margin: 20,
  },
};

const DashboardPage = () => (
  <div style={styles.mainContainer} key="baseContainer">
    <Logo style={{
      width: 300,
      height: 300,
      color: 'black',
      margin: '0 auto',
    }}
    />
  </div>
);

DashboardPage.propTypes = {
};

DashboardPage.defaultProps = {
};

export default DashboardPage;
