import { createActions } from 'redux-actions';
import { createAsyncActionTypesArray } from '../../utils/createAsyncActionTypes';

export default createActions(
  // ----- TASKS -----
  ...createAsyncActionTypesArray('GET_COMMENT_LIST'),
  ...createAsyncActionTypesArray('SEARCH'),
  ...createAsyncActionTypesArray('UPDATE_COMMENT_INFO'),

  // ----- FLOWS -----
  ...createAsyncActionTypesArray('GET_COMMENT_LIST_FLOW'),
  ...createAsyncActionTypesArray('SEARCH_FLOW'),
  ...createAsyncActionTypesArray('UPDATE_COMMENT_INFO_FLOW'),

  { prefix: 'COMMENT_MANAGEMENT' },
);
