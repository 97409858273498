import React from 'react';
import PropTypes from 'prop-types';
import FormTableComponent from '../../../../components/FormTable/FormTableComponent';

const MarqueeInfoComponent = ({
  data,
  updateMarqueeInfo,
  cancelSelectMarquee,
}) => {
  const renderTableRows = renderField => [
    renderField({ key: 'text', name: '跑馬燈文字', type: 'textArea' }),
  ];
  return (
    <FormTableComponent
      data={data}
      updateData={updateMarqueeInfo}
      cancelSelection={cancelSelectMarquee}
      renderTableRows={renderTableRows}
      // eslint-disable-next-line no-alert
      deleteData={() => { alert('無法刪除請修改文字即可'); }}
      createData={() => {}}
      isCreating={false}
    />
  );
};

MarqueeInfoComponent.propTypes = {
  data: PropTypes.shape({}),
  updateMarqueeInfo: PropTypes.func.isRequired,
  cancelSelectMarquee: PropTypes.func.isRequired,
};

MarqueeInfoComponent.defaultProps = {
  data: null,
};

export default MarqueeInfoComponent;
