
const hotLevelId = {
  explosion: 1,
  hot: 2,
  general: 3,
};

const hotLevel = {
  explosion: '爆紅包款',
  hot: '熱門包款',
  general: '普通包款',
};

module.exports = {
  hotLevelId,
  hotLevel,
};
