import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';
import ActionCreator from './ActionCreator';
import API from './API';

const {
  getNegotiationListRequest,
  getNegotiationListSuccess,
  getNegotiationListFailure,
  submitNegotiationRequest,
  submitNegotiationSuccess,
  submitNegotiationFailure,
  getNegotiationListFlowRequest,
  getNegotiationListFlowSuccess,
  getNegotiationListFlowFailure,
  submitNegotiationFlowRequest,
  submitNegotiationFlowSuccess,
  submitNegotiationFlowFailure,
  dealNegotiationFlowRequest,
  dealNegotiationFlowSuccess,
  dealNegotiationFlowFailure,
  dealNegotiationRequest,
  dealNegotiationSuccess,
  dealNegotiationFailure,
} = ActionCreator;

// ---------- TASKS ----------
export function* getNegotiationList() {
  yield put(getNegotiationListRequest());

  try {
    const result = (yield call(API.getNegotiationList, {
      include: [
        {
          relation: 'bag',
        },
        {
          relation: 'fromUser',
          scope: {
            fields: 'name',
          },
        },
        {
          relation: 'toUser',
          scope: {
            fields: 'name',
          },
        },
      ],
    }));
    yield put(getNegotiationListSuccess({
      data: result.data,
    }));
    return result;
  } catch (error) {
    yield put(getNegotiationListFailure(error));
    throw error;
  }
}

export function* submitNegotiation(data) {
  yield put(submitNegotiationRequest());
  try {
    yield call(API.submitNegotiation, data);
    yield put(submitNegotiationSuccess());
  } catch (error) {
    yield put(submitNegotiationFailure(error));
    throw error;
  }
}

export function* dealNegotiation(data) {
  yield put(dealNegotiationRequest());
  try {
    const checkExistedAndGetBool = (source) => {
      if (typeof source === 'undefined') {
        return undefined;
      }
      if (source === 'null' || source === null || source === '') {
        return null;
      }
      return source === 'true';
    };

    const {
      isAccept,
      isDeal,
      id,
      ...restOfData
    } = data;

    yield call(API.dealNegotiation, {
      isAccept: checkExistedAndGetBool(isAccept),
      isDeal: checkExistedAndGetBool(isDeal),
      id,
      ...restOfData,
    });

    yield put(dealNegotiationSuccess());
  } catch (error) {
    yield put(dealNegotiationFailure(error));
    throw error;
  }
}

// ---------- FLOWS ----------

export function* getNegotiationListFlow() {
  try {
    const result = yield call(getNegotiationList);
    yield put(getNegotiationListFlowSuccess(result));
  } catch (error) {
    yield put(getNegotiationListFlowFailure(error));
  }
}

export function* submitNegotiationFlow({ payload }) {
  try {
    const result = yield call(submitNegotiation, payload);
    yield put(submitNegotiationFlowSuccess(result));
    yield put(getNegotiationListFlowRequest());
  } catch (error) {
    yield put(submitNegotiationFlowFailure(error));
  }
}

export function* dealNegotiationFlow({ payload }) {
  try {
    const result = yield call(dealNegotiation, payload);
    yield put(dealNegotiationFlowSuccess(result));
    yield put(getNegotiationListFlowRequest());
  } catch (error) {
    yield put(dealNegotiationFlowFailure(error));
  }
}

export default [
  takeLatest(getNegotiationListFlowRequest, getNegotiationListFlow),
  takeLatest(submitNegotiationFlowRequest, submitNegotiationFlow),
  takeLatest(dealNegotiationFlowRequest, dealNegotiationFlow),
];
