import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ActionCreators from '../../ActionCreator';
import BagManagementPage from './BagManagementPage';
import {
  reduxStatusSelector,
  reduxErrorSelector,
  bagListSelector,
  bagNumberSelector,
  modifiedBagNumberSelector,
} from '../../selector';

const BagManagementPageContainer = ({
  reduxStatus,
  reduxError,
  actions: {
    updateBagInfoFlowRequest,
    deleteBagFlowRequest,
    createBagFlowRequest,
    judgeModifyBuyAmountFlowRequest,
    getBagNumbersFlowRequest,
    getModifiedBuyAmountRequestNumberFlowRequest,
    resetStatus,
  },
  data,
  bagNumbers,
  onBagSelected,
  modifiedBagNumbers,
  commodityType,
}) => {
  useEffect(() => {
    getBagNumbersFlowRequest();
    getModifiedBuyAmountRequestNumberFlowRequest();
    return resetStatus;
  }, []);

  const handleJudgeModifyBuyAmountRequest = (bagId, judge) => {
    judgeModifyBuyAmountFlowRequest({
      bagId, judge,
    });
  };

  const handelDeleteBag = (bag) => {
    deleteBagFlowRequest(bag);
    getBagNumbersFlowRequest();
  };

  const handelCreateBag = (bag) => {
    createBagFlowRequest(bag);
    getBagNumbersFlowRequest();
  };

  return (
    <BagManagementPage
      reduxStatus={reduxStatus}
      reduxError={reduxError}
      data={data}
      bagNumbers={bagNumbers}
      modifiedBagNumbers={modifiedBagNumbers}
      updateBagInfo={updateBagInfoFlowRequest}
      deleteBag={handelDeleteBag}
      createBag={handelCreateBag}
      onBagSelected={onBagSelected}
      onJudeModifyBuyAmount={handleJudgeModifyBuyAmountRequest}
      commodityType={commodityType}
    />
  );
};

BagManagementPageContainer.propTypes = {
  reduxStatus: PropTypes.string,
  reduxError: PropTypes.string,
  actions: PropTypes.shape({
    deleteBagFlowRequest: PropTypes.func.isRequired,
    updateBagInfoFlowRequest: PropTypes.func.isRequired,
    createBagFlowRequest: PropTypes.func.isRequired,
    resetStatus: PropTypes.func.isRequired,
    getBagNumbersFlowRequest: PropTypes.func.isRequired,
    judgeModifyBuyAmountFlowRequest: PropTypes.func.isRequired,
    getModifiedBuyAmountRequestNumberFlowRequest: PropTypes.func.isRequired,
  }).isRequired,
  data: PropTypes.array,
  bagNumbers: PropTypes.number,
  modifiedBagNumbers: PropTypes.number,
  onBagSelected: PropTypes.func,
  commodityType: PropTypes.number,
};

BagManagementPageContainer.defaultProps = {
  reduxStatus: null,
  reduxError: null,
  data: [],
  bagNumbers: null,
  modifiedBagNumbers: null,
  onBagSelected: null,
  commodityType: 1,
};

const mapStateToProps = state => ({
  reduxStatus: reduxStatusSelector(state),
  reduxError: reduxErrorSelector(state),
  data: bagListSelector(state),
  bagNumbers: bagNumberSelector(state),
  modifiedBagNumbers: modifiedBagNumberSelector(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    ...ActionCreators,
  }, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BagManagementPageContainer);
