import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import FormTableComponent from '../../../../components/FormTable/FormTableComponent';

const styles = {
  image: {
    width: 120,
    height: 120,
    margin: 5,
  },
};


const CommentInfoComponent = ({
  commentInfo,
  updateCommentInfo,
  cancelSelectComment,
}) => {
  const renderTableRows = renderField => [
    renderField({ key: 'isActive', name: '是否顯示', type: 'checkbox' }),
    renderField({
      key: 'commentByUser',
      name: '評論者',
      type: 'custom',
      renderChild: modifiedValue => [
        <span key="name">
          {modifiedValue ? R.pathOr('(沒有名字)', ['name'], modifiedValue) : R.pathOr('(沒有名字)', ['commentByUser', 'name'], commentInfo)}
          (
          {modifiedValue ? R.pathOr('', ['email'], modifiedValue) : R.pathOr('', ['commentByUser', 'email'], commentInfo)}
          )
        </span>,
      ],
    }),
    renderField({
      key: 'bag',
      name: '包款',
      type: 'custom',
      renderChild: modifiedValue => [
        <span key="name">
          {modifiedValue ? R.pathOr('(沒有名字)', ['name'], modifiedValue) : R.pathOr('(沒有名字)', ['bag', 'name'], commentInfo)}
        </span>,
      ],
    }),
    renderField({ key: 'content', name: '評論' }),
    renderField({
      key: 'resources',
      name: '評論相片',
      type: 'custom',
      renderChild: () => R.pathOr([], ['resources'], commentInfo).map(resource => (
        <img
          key={R.pathOr('0', ['id'], resource)}
          src={R.pathOr('0', ['uri'], resource)}
          style={styles.image}
          alt=""
        />
      )),
    }),
  ];

  return (
    <FormTableComponent
      data={commentInfo}
      updateData={updateCommentInfo}
      cancelSelection={cancelSelectComment}
      renderTableRows={renderTableRows}
    />
  );
};

CommentInfoComponent.propTypes = {
  commentInfo: PropTypes.shape({}),
  updateCommentInfo: PropTypes.func.isRequired,
  cancelSelectComment: PropTypes.func.isRequired,
};

CommentInfoComponent.defaultProps = {
  commentInfo: null,
};

export default CommentInfoComponent;
