import { handleActions, combineActions } from 'redux-actions';
import update from 'immutability-helper';
import ActionCreator from './ActionCreator';
import ReduxStatus from '../../constants/ReduxStatus';

const {
  resetStatus,
  getThemeListRequest,
  getThemeListSuccess,
  getThemeListFailure,
  createThemeRequest,
  createThemeSuccess,
  createThemeFailure,
  updateThemeRequest,
  updateThemeSuccess,
  updateThemeFailure,
  deleteThemeRequest,
  deleteThemeSuccess,
  deleteThemeFailure,
  getThemeBagListRequest,
  getThemeBagListSuccess,
  getThemeBagListFailure,
  linkThemeBagSuccess,
  unlinkThemeBagSuccess,
} = ActionCreator;

const initialState = {
  reduxStatus: ReduxStatus.STATUS_INITIAL,
  error: null,
  data: [],
  themeBags: [],
};

export default handleActions({
  [combineActions(
    getThemeListRequest,
    createThemeRequest,
    updateThemeRequest,
    deleteThemeRequest,
    getThemeBagListRequest,
  )]: state => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_LOADING },
    error: { $set: initialState.error },
  }),
  [combineActions(
    getThemeListSuccess,
  )]: (state, { payload }) => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_NORMAL },
    data: { $set: payload },
  }),
  [combineActions(
    createThemeSuccess,
    updateThemeSuccess,
    deleteThemeSuccess,
  )]: state => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_NORMAL },
  }),
  [combineActions(
    getThemeBagListSuccess,
  )]: (state, { payload }) => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_NORMAL },
    themeBags: { $set: payload },
  }),
  [combineActions(
    getThemeListFailure,
    createThemeFailure,
    updateThemeFailure,
    deleteThemeFailure,
    getThemeBagListFailure,
  )]: (state, { payload }) => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_ERROR },
    error: { $set: payload },
  }),

  [resetStatus]: state => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_INITIAL },
  }),

  [linkThemeBagSuccess]: (state, { payload }) => update(state, {
    themeBags: {
      $set: [
        ...(state.themeBags || []),
        ...(payload.bags.map((bag) => {
          if ((state.themeBags || []).find(({ id }) => `${id}` === `${bag.id}`)) return null;
          return bag;
        })).filter(d => d),
      ],
    },
  }),

  [unlinkThemeBagSuccess]: (state, { payload }) => update(state, {
    themeBags: {
      $set: (state.themeBags || []).map((bag) => {
        if (bag.id === payload.bagId) return null;
        return bag;
      }).filter(d => d),
    },
  }),
}, initialState);
