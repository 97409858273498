import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';
import ActionCreator from './ActionCreator';
import API from './API';

const {
  getCartListFlowRequest,
  getCartListFlowSuccess,
  getCartListFlowFailure,
  getCartListRequest,
  getCartListSuccess,
  getCartListFailure,
} = ActionCreator;

// ---------- TASKS ----------

function* getCartList(userId) {
  yield put(getCartListRequest());
  try {
    const result = yield call(API.getCartList, {
      where: { userId },
      include: [
        {
          relation: 'bag',
          scope: { fields: ['name', 'bagNo'] },
        },
      ],
    });
    yield put(getCartListSuccess(result.data));
    return result;
  } catch (error) {
    yield put(getCartListFailure(error));
    throw (error);
  }
}


// ---------- FLOW ----------

export function* getCartListFlow({ payload }) {
  try {
    const result = yield call(getCartList, payload);
    yield put(getCartListFlowSuccess(result.data));
  } catch (error) {
    yield put(getCartListFlowFailure(error));
  }
}

export default [
  takeLatest(getCartListFlowRequest, getCartListFlow),
];
