import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';
import * as R from 'ramda';
import ActionCreator from './ActionCreator';
import API from './API';

const {
  getMarqueeListRequest,
  getMarqueeListSuccess,
  getMarqueeListFailure,
  updateMarqueeInfoRequest,
  updateMarqueeInfoSuccess,
  updateMarqueeInfoFailure,

  getMarqueeListFlowRequest,
  getMarqueeListFlowSuccess,
  getMarqueeListFlowFailure,
  updateMarqueeInfoFlowRequest,
  updateMarqueeInfoFlowSuccess,
  updateMarqueeInfoFlowFailure,
} = ActionCreator;

// ---------- TASKS ----------
function* getMarqueeList() {
  yield put(getMarqueeListRequest());
  try {
    const result = yield call(API.getMarqueeList);
    yield put(getMarqueeListSuccess(result.data));
    return result;
  } catch (error) {
    yield put(getMarqueeListFailure(error));
    throw (error);
  }
}

function* updateMarqueeInfo(data) {
  yield put(updateMarqueeInfoRequest());
  try {
    const result = yield call(API.updateMarqueeInfo, data);
    result.data.isMarquee = true;
    yield put(updateMarqueeInfoSuccess(result.data));
    return result;
  } catch (error) {
    yield put(updateMarqueeInfoFailure(error));
    throw (error);
  }
}


// ---------- FLOW ----------
export function* getMarqueeListFlow({ payload }) {
  try {
    const Marquee = yield call(getMarqueeList, payload);
    yield put(getMarqueeListFlowSuccess(
      R.pathOr([], ['data'], Marquee),
    ));
  } catch (error) {
    yield put(getMarqueeListFlowFailure(error));
  }
}

export function* updateMarqueeInfoFlow({ payload }) {
  try {
    const result = yield call(updateMarqueeInfo, payload);
    yield put(updateMarqueeInfoFlowSuccess(result.data));
  } catch (error) {
    yield put(updateMarqueeInfoFlowFailure(error));
  }
}

export default [
  takeLatest(getMarqueeListFlowRequest, getMarqueeListFlow),
  takeLatest(updateMarqueeInfoFlowRequest, updateMarqueeInfoFlow),
];
