import { createActions } from 'redux-actions';
import { createAsyncActionTypesArray } from '../../utils/createAsyncActionTypes';

export default createActions(
  // ----- TASKS -----
  ...createAsyncActionTypesArray('GET_NEGOTIATION_LIST'),
  ...createAsyncActionTypesArray('SUBMIT_NEGOTIATION'),
  ...createAsyncActionTypesArray('DEAL_NEGOTIATION'),

  // ----- FLOWS -----
  ...createAsyncActionTypesArray('GET_NEGOTIATION_LIST_FLOW'),
  ...createAsyncActionTypesArray('SUBMIT_NEGOTIATION_FLOW'),
  ...createAsyncActionTypesArray('DEAL_NEGOTIATION_FLOW'),

  // ----- STATUS -----
  'RESET_STATUS',

  { prefix: 'NEGOTIATION' },
);
