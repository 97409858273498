import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';

const styles = {
  themeBagItem: {
    padding: 5,
    margin: 5,
    borderRadius: 3,
  },
};

const ThemeBagItemComponent = ({
  name,
  onClick,
}) => (
  <Button style={styles.themeBagItem} onClick={onClick} type="button">
    {name}
  </Button>
);

ThemeBagItemComponent.propTypes = {
  name: PropTypes.string,
  onClick: PropTypes.func,
};

ThemeBagItemComponent.defaultProps = {
  name: '',
  onClick: null,
};

export default ThemeBagItemComponent;
