import React from 'react';
import PropTypes from 'prop-types';
import FormTableComponent from '../../../components/FormTable/FormTableComponent';

const NegotiationInfoComponent = ({
  negotiationInfo,
  updateNegotiationInfo,
  cancelSelectNegotiation,
}) => {
  const renderTableRows = renderField => [
    renderField({ key: ['fromUser', 'name'], name: '議價方', disabled: true }),
    renderField({ key: 'amount', name: '金額', disabled: true }),
    renderField({ key: 'fromMessage', name: '議價方訊息' }),
    renderField({ key: ['toUser', 'name'], name: '回覆方', disabled: true }),
    renderField({ key: 'toMessage', name: '回覆方訊息' }),
    renderField({ key: 'adminMessage', name: 'OhPhire訊息' }),
    renderField({ key: ['bagId'], name: 'bagID', disabled: true }),
    renderField({ key: ['bag', 'name'], name: '包款', disabled: true }),
    renderField({
      key: 'isAccept',
      name: '是否接受雙方之議價',
      type: 'select',
      options: [{ id: null, name: '' }, { id: false, name: '否' }, { id: true, name: '是' }],
    }),
    renderField({
      key: 'isDeal',
      name: 'OhPhire審核結果',
      type: 'select',
      options: [{ id: null, name: '' }, { id: false, name: '否' }, { id: true, name: '是' }],
    }),
  ];

  return [
    <FormTableComponent
      key="FormTableComponent"
      data={negotiationInfo}
      updateData={updateNegotiationInfo}
      cancelSelection={cancelSelectNegotiation}
      renderTableRows={renderTableRows}
    />,
  ];
};

NegotiationInfoComponent.propTypes = {
  negotiationInfo: PropTypes.shape({}),
  updateNegotiationInfo: PropTypes.func.isRequired,
  cancelSelectNegotiation: PropTypes.func.isRequired,
};

NegotiationInfoComponent.defaultProps = {
  bagInfo: null,
};

export default NegotiationInfoComponent;
