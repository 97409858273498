import React from 'react';
import PropTypes from 'prop-types';
import FormTableComponent from '../../../../components/FormTable/FormTableComponent';


const SubscribeInfoComponent = ({
  subscribeInfo,
  updateSubscribeInfo,
  deleteSubscribe,
  cancelSelectSubscribe,
}) => {
  const isCreating = Object.keys(subscribeInfo || {}).length === 0;

  const renderTableRows = renderField => [
    renderField({ key: 'email', name: 'E-mail' }),
  ];

  return (
    <FormTableComponent
      data={subscribeInfo}
      updateData={updateSubscribeInfo}
      cancelSelection={cancelSelectSubscribe}
      renderTableRows={renderTableRows}
      deleteData={deleteSubscribe}
      isCreating={isCreating}
    />
  );
};

SubscribeInfoComponent.propTypes = {
  subscribeInfo: PropTypes.shape({}),
  updateSubscribeInfo: PropTypes.func.isRequired,
  deleteSubscribe: PropTypes.func.isRequired,
  cancelSelectSubscribe: PropTypes.func.isRequired,
};

SubscribeInfoComponent.defaultProps = {
  subscribeInfo: null,
};

export default SubscribeInfoComponent;
