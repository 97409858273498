import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ActionCreators from '../../ActionCreator';
import MemberManagementPage from './MemberManagementPage';
import {
  reduxStatusSelector,
  reduxErrorSelector,
  memberListSelector,
  memberNumbersSelector,
} from '../../selector';

const MemberManagementPageContainer = ({
  reduxStatus,
  reduxError,
  actions: {
    getMemberListFlowRequest,
    getMemberNumbersFlowRequest,
    searchByKeywordFlowRequest,
    updateMemberInfoFlowRequest,
    resetStatus,
  },
  data,
  memberNumbers,
  onMemberSelected,
}) => {
  useEffect(() => {
    if (!data || data.length === 0) {
      getMemberListFlowRequest();
      getMemberNumbersFlowRequest();
    } else resetStatus();
    return resetStatus;
  }, []);

  return (
    <MemberManagementPage
      reduxStatus={reduxStatus}
      reduxError={reduxError}
      data={data}
      memberNumbers={memberNumbers}
      searchByKeyword={searchByKeywordFlowRequest}
      updateMemberInfo={updateMemberInfoFlowRequest}
      onMemberSelected={onMemberSelected}
    />
  );
};

MemberManagementPageContainer.propTypes = {
  reduxStatus: PropTypes.string,
  reduxError: PropTypes.string,
  actions: PropTypes.shape({
    getMemberListFlowRequest: PropTypes.func.isRequired,
    searchByKeywordFlowRequest: PropTypes.func.isRequired,
    updateMemberInfoFlowRequest: PropTypes.func.isRequired,
    getMemberNumbersFlowRequest: PropTypes.func.isRequired,
    resetStatus: PropTypes.func.isRequired,
  }).isRequired,
  data: PropTypes.array,
  onMemberSelected: PropTypes.func,
  memberNumbers: PropTypes.number,
};

MemberManagementPageContainer.defaultProps = {
  reduxStatus: null,
  reduxError: null,
  data: [],
  onMemberSelected: null,
  memberNumbers: null,
};

const mapStateToProps = state => ({
  reduxStatus: reduxStatusSelector(state),
  reduxError: reduxErrorSelector(state),
  data: memberListSelector(state),
  memberNumbers: memberNumbersSelector(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    ...ActionCreators,
  }, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MemberManagementPageContainer);
