import { handleActions, combineActions } from 'redux-actions';
import update from 'immutability-helper';
import * as R from 'ramda';
import ActionCreator from './ActionCreator';
import ReduxStatus from '../../constants/ReduxStatus';

const {
  resetStatus,
  getCouponTypeListFlowRequest,
  getCouponTypeListSuccess,
  getCouponTypeListFailure,

  createCouponFlowRequest,
  createCouponSuccess,
  createCouponFailure,

  getCouponListFlowRequest,
  getCouponListSuccess,
  getCouponListFailure,

  updateCouponRequest,
  updateCouponSuccess,
  updateCouponFailure,

  deleteCouponFlowRequest,
  deleteCouponSuccess,
  deleteCouponFailure,
} = ActionCreator;

const initialState = {
  reduxStatus: ReduxStatus.STATUS_INITIAL,
  error: null,
  couponTypes: [],
  coupons: [],
};

export default handleActions({
  [combineActions(
    getCouponTypeListFlowRequest,
    getCouponListFlowRequest,
    updateCouponRequest,
    deleteCouponFlowRequest,
    createCouponFlowRequest,
  )]: state => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_LOADING },
    error: { $set: initialState.error },
  }),

  // Success

  [createCouponSuccess]: state => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_NORMAL },
  }),

  [getCouponTypeListSuccess]: (state, { payload }) => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_NORMAL },
    couponTypes: { $set: payload },
  }),

  [getCouponListSuccess]: (state, { payload }) => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_NORMAL },
    coupons: { $set: payload },
  }),

  [updateCouponSuccess]: (state, { payload }) => {
    const {
      id,
    } = payload;

    return update(state, {
      reduxStatus: { $set: ReduxStatus.STATUS_NORMAL },
      coupons: {
        $set: R.pathOr([], ['coupons'], state).map((coupon) => {
          if (coupon.id === id) return payload;
          return coupon;
        }),
      },
    });
  },

  [deleteCouponSuccess]: (state, { payload }) => {
    const {
      id,
    } = payload;
    return update(state, {
      reduxStatus: { $set: ReduxStatus.STATUS_NORMAL },
      coupons: {
        $set: R.pathOr([], ['coupons'], state).map((bag) => {
          if (bag.id === id) return null;
          return bag;
        }).filter(d => d),
      },
    });
  },

  // Failure

  [combineActions(
    getCouponListFailure,
    updateCouponFailure,
    getCouponTypeListFailure,
    deleteCouponFailure,
    createCouponFailure,
  )]: (state, { payload }) => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_ERROR },
    error: { $set: payload },
  }),


  [resetStatus]: state => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_INITIAL },
  }),
}, initialState);
