import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';
import * as R from 'ramda';
import ActionCreator from './ActionCreator';
import API from './API';

const {
  getReduceDepositListRequest,
  getReduceDepositListSuccess,
  getReduceDepositListFailure,
  getReduceDepositListFlowRequest,
  getReduceDepositListFlowSuccess,
  getReduceDepositListFlowFailure,

  createReduceDepositRequest,
  createReduceDepositSuccess,
  createReduceDepositFailure,
  createReduceDepositFlowRequest,
  createReduceDepositFlowSuccess,
  createReduceDepositFlowFailure,

  deleteReduceDepositRequest,
  deleteReduceDepositSuccess,
  deleteReduceDepositFailure,
  deleteReduceDepositFlowRequest,
  deleteReduceDepositFlowSuccess,
  deleteReduceDepositFlowFailure,
} = ActionCreator;

// ---------- TASKS ----------
function* getReduceDepositList(ownerId = null) {
  try {
    yield put(getReduceDepositListRequest());

    const filterClause = {
      order: 'ownerId ASC',
      include: [
        {
          relation: 'owner',
          scope: { fields: ['name'] },
        },
        {
          relation: 'user',
          scope: { fields: ['name'] },
        },
        {
          relation: 'bag',
          scope: { fields: ['name', 'ownerId'] },
        },
      ],
    };

    if (!R.isNil(ownerId) && !R.isEmpty(ownerId)) {
      Object.assign(filterClause, { where: { ownerId } });
    }

    const result = yield call(API.getReduceDepositList, filterClause);
    yield put(getReduceDepositListSuccess(result.data));
    return result;
  } catch (error) {
    yield put(getReduceDepositListFailure(error));
    throw (error);
  }
}

function* createReduceDeposit(payload) {
  try {
    yield put(createReduceDepositRequest());
    const result = yield call(API.createReduceDeposit, payload);
    yield put(createReduceDepositSuccess());
    return result;
  } catch (error) {
    yield put(createReduceDepositFailure(error));
    throw (error);
  }
}

function* deleteReduceDeposit(payload) {
  try {
    yield put(deleteReduceDepositRequest());
    const result = yield call(API.deleteReduceDeposit, payload);
    yield put(deleteReduceDepositSuccess());
    return result;
  } catch (error) {
    yield put(deleteReduceDepositFailure(error));
    throw (error);
  }
}

// ---------- FLOW ----------
export function* getReduceDepositListFlow({ payload }) {
  try {
    yield call(getReduceDepositList, payload);
    yield put(getReduceDepositListFlowSuccess());
  } catch (error) {
    yield put(getReduceDepositListFlowFailure(error));
  }
}

export function* createReduceDepositFlow({ payload }) {
  try {
    const result = yield call(createReduceDeposit, payload);

    if (result.status === 200) {
      yield put(getReduceDepositListFlowRequest());
    }

    yield put(createReduceDepositFlowSuccess());
  } catch (error) {
    yield put(createReduceDepositFlowFailure(error));
  }
}

export function* deleteReduceDepositFlow({ payload }) {
  try {
    const result = yield call(deleteReduceDeposit, payload);

    if (result.status === 200) {
      yield put(getReduceDepositListFlowRequest());
    }
    yield put(deleteReduceDepositFlowSuccess());
  } catch (error) {
    yield put(deleteReduceDepositFlowFailure(error));
  }
}


export default [
  takeLatest(getReduceDepositListFlowRequest, getReduceDepositListFlow),
  takeLatest(createReduceDepositFlowRequest, createReduceDepositFlow),
  takeLatest(deleteReduceDepositFlowRequest, deleteReduceDepositFlow),
];
