import * as R from 'ramda';
import { createSelector } from 'reselect';
import { tagManagementStateSelector } from '../../lib/rootSelector';

export const reduxStatusSelector = createSelector(
  tagManagementStateSelector,
  result => R.path(['reduxStatus'], result),
);

export const reduxErrorSelector = createSelector(
  tagManagementStateSelector,
  result => R.path(['error', 'message'], result),
);

export const tagListSelector = createSelector(
  tagManagementStateSelector,
  result => R.path(['data'], result),
);

export const categorizedTagListSelector = createSelector(
  tagListSelector,
  result => R.path(['categorizedTags'], result),
);
