import React, { useEffect, useState } from 'react';
import { Button, Table, Divider } from 'antd';
import PropTypes from 'prop-types';
import CouponInfoComponent from './CouponInfoComponent';
import ReduxStatusVisualizedComponent from '../../../../components/ReduxStatusVisualizedComponent';

const styles = {
  mainContainer: {
    margin: 20,
  },
};

const CouponManagementPage = ({
  reduxStatus,
  reduxError,
  updateCouponInfo,
  deleteCoupon,
  couponList,
  createCoupon,
  memberList,
}) => {
  const getRowKey = ({ id, couponCode }) => (`${id}.${couponCode}`);

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      width: 150,
    },
    {
      title: '折扣類型',
      dataIndex: ['couponType', 'name'],
    },
    {
      title: '折扣碼',
      dataIndex: 'couponCode',
    },
    {
      title: '折扣碼名稱',
      dataIndex: 'couponName',
    },
    {
      title: '打折',
      dataIndex: 'percent',
      render: percent => (percent === 0 ? '' : `${percent / 10}折`),
    },
    {
      title: '折抵',
      dataIndex: 'minus',
      render: minus => (minus === 0 ? '' : `$${minus}元`),
    },
    {
      title: '結束日期',
      dataIndex: 'endDate',
    },
    {
      title: '是否啟用',
      dataIndex: 'isActive',
      render: isActive => (isActive === true ? '是' : '否'),
    },
    {
      title: '是否為預設優惠券',
      dataIndex: 'isDefault',
      render: isActive => (isActive === true ? '是' : '否'),
    },
    {
      title: '是否所有人皆可領取',
      dataIndex: 'isGlobal',
      render: isActive => (isActive === true ? '是' : '否'),
    },
  ];

  const [selectedCoupon, setSelectedCoupon] = useState(null);

  useEffect(() => {
    setSelectedCoupon(null);
  }, [couponList]);

  const onRow = coupon => ({
    onClick: () => {
      setSelectedCoupon(coupon);
    },
  });

  return (
    <div style={styles.mainContainer} key="baseContainer">
      <ReduxStatusVisualizedComponent
        reduxStatus={reduxStatus}
        showModalOnError
        modalFailureContent={reduxError}
      >
        <Button onClick={() => { setSelectedCoupon({}); }}>
          新增優惠券
        </Button>

        <Divider />
        <h1>預設優惠券，若勾選表示必須在Api裡面設定trigger的時機,例如評論一個包包這種優惠券，必須另外修改Api否則會被系統認為是所有人可領取或是被指定給某些人。</h1>
        <br />
        <div>如為打折類型，折抵需設定為0; 反之 如為折抵類型，打折需設定為0</div>
        <br />
        <div>若折扣為 8折，請在打折欄位中輸入 80</div>

        <Divider />
        <Table
          rowKey={getRowKey}
          columns={columns}
          childrenColumnName="Coupons"
          dataSource={couponList}
          expandRowByClick
          onRow={onRow}
          pagination={false}
        />

        <CouponInfoComponent
          memberList={memberList}
          couponInfo={selectedCoupon}
          updateCouponInfo={updateCouponInfo}
          deleteCouponInfo={deleteCoupon}
          createCouponInfo={createCoupon}
          cancelSelectCouponInfo={() => setSelectedCoupon(null)}
        />
      </ReduxStatusVisualizedComponent>
    </div>
  );
};

CouponManagementPage.propTypes = {
  reduxStatus: PropTypes.string,
  reduxError: PropTypes.string,
  couponList: PropTypes.array,
  updateCouponInfo: PropTypes.func.isRequired,
  createCoupon: PropTypes.func.isRequired,
  deleteCoupon: PropTypes.func.isRequired,
  memberList: PropTypes.array,
};

CouponManagementPage.defaultProps = {
  reduxStatus: null,
  reduxError: null,
  couponList: [],
  memberList: [],
};

export default CouponManagementPage;
