const transactionType = {
  rent: '僅租賃',
  buy: '僅買斷',
  rentBuy: '可租可買斷',
};

const typeId = {
  rent: 1,
  buy: 2,
  rentBuy: 3,
};

const commodityType = {
  bag: '包包',
  clothes: '衣服',
  accessories: '配件',
  essence: '保養管理',
};

const commodityTypeId = {
  bag: 1,
  clothes: 2,
  accessories: 3,
  essence: 4,
};

module.exports = {
  transactionType,
  typeId,
  commodityTypeId,
  commodityType,
};
