import * as R from 'ramda';
import { createSelector } from 'reselect';
import { memberManagementStateSelector } from '../../lib/rootSelector';

export const reduxStatusSelector = createSelector(
  memberManagementStateSelector,
  result => R.path(['reduxStatus'], result),
);

export const reduxErrorSelector = createSelector(
  memberManagementStateSelector,
  result => R.path(['error', 'message'], result),
);


export const memberListSelector = createSelector(
  memberManagementStateSelector,
  result => R.path(['data'], result),
);

export const memberNumbersSelector = createSelector(
  memberManagementStateSelector,
  result => R.path(['memberNumbers'], result),
);
