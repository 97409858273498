import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ActionCreators from '../../ActionCreator';
import ThemeManagementPage from './ThemeManagementPage';
import {
  reduxStatusSelector,
  reduxErrorSelector,
  themeListSelector,
} from '../../selector';

const ThemeManagementPageContainer = ({
  reduxStatus,
  reduxError,
  actions: {
    getThemeListFlowRequest,
    createThemeFlowRequest,
    updateThemeFlowRequest,
    deleteThemeFlowRequest,
    resetStatus,
  },
  data,
}) => {
  useEffect(() => {
    getThemeListFlowRequest();
    return resetStatus;
  }, []);

  return (
    <ThemeManagementPage
      reduxStatus={reduxStatus}
      reduxError={reduxError}
      createTheme={createThemeFlowRequest}
      updateTheme={updateThemeFlowRequest}
      deleteTheme={deleteThemeFlowRequest}
      data={data}
    />
  );
};

ThemeManagementPageContainer.propTypes = {
  reduxStatus: PropTypes.string,
  reduxError: PropTypes.string,
  actions: PropTypes.shape({
    getThemeListFlowRequest: PropTypes.func.isRequired,
    createThemeFlowRequest: PropTypes.func.isRequired,
    updateThemeFlowRequest: PropTypes.func.isRequired,
    deleteThemeFlowRequest: PropTypes.func.isRequired,
    resetStatus: PropTypes.func.isRequired,
  }).isRequired,
  data: PropTypes.array,
};

ThemeManagementPageContainer.defaultProps = {
  reduxStatus: null,
  reduxError: null,
  data: [],
};

const mapStateToProps = state => ({
  reduxStatus: reduxStatusSelector(state),
  reduxError: reduxErrorSelector(state),
  data: themeListSelector(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    ...ActionCreators,
  }, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ThemeManagementPageContainer);
