import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';
import * as R from 'ramda';
import ActionCreator from './ActionCreator';
import API from './API';

const {
  getCommentListRequest,
  getCommentListSuccess,
  getCommentListFailure,
  getCommentListFlowRequest,
  getCommentListFlowSuccess,
  getCommentListFlowFailure,

  searchFlowSuccess,
  searchFlowFailure,
  searchFlowRequest,

  updateCommentInfoFlowRequest,
  updateCommentInfoFlowSuccess,
  updateCommentInfoFlowFailure,
  updateCommentInfoRequest,
  updateCommentInfoSuccess,
  updateCommentInfoFailure,
} = ActionCreator;

// ---------- NORMAL FUNCTIONS ----------
const buildFilterClause = (filterCondition = {}) => {
  try {
    const {
      keyword,
      page = 0,
      commentByUserId,
      bagId,
      ...otherCondition
    } = filterCondition;

    const pageLimit = 10;

    const where = {};
    if (keyword) {
      Object.assign(where, {
        content: {
          like: `%${keyword}%`,
        },
      });
    }

    if (otherCondition && Object.keys(otherCondition).length > 0) {
      Object.assign(where, otherCondition);
    }

    if (commentByUserId) {
      Object.assign(where, {
        commentByUserId,
      });
    }

    if (bagId) {
      Object.assign(where, {
        bagId,
      });
    }

    const assignedFilterCondition = {
      limit: pageLimit,
      skip: pageLimit * page,
      order: 'id DESC',
      include: [
        {
          relation: 'commentByUser',
          scope: { fields: ['name', 'email'] },
        },
        {
          relation: 'bag',
          scope: { fields: 'name' },
        },
        {
          relation: 'resources',
          scope: { fields: 'uri' },
        },
      ],
      ...(R.isEmpty(where) ? {} : { where }),
    };

    return assignedFilterCondition;
  } catch (error) {
    // eslint-disable-next-line
    console.error(error);
    return {};
  }
};

// ---------- TASKS ----------
function* getCommentList(filterCondition = {}) {
  try {
    yield put(getCommentListRequest());
    const result = yield call(API.getCommentList, buildFilterClause(filterCondition));
    yield put(getCommentListSuccess(result.data));
    return result;
  } catch (error) {
    yield put(getCommentListFailure(error));
    throw (error);
  }
}

function* updateCommentInfo(data) {
  yield put(updateCommentInfoRequest());
  try {
    const {
      id: commentId,
      ...restOfData
    } = (data || {});

    if (!R.isEmpty(restOfData)) {
      yield call(API.updateCommentInfo, {
        ...restOfData,
        id: commentId,
      });
    }

    // NOTE: The update operation has been finished successfully.
    //       Now we fetch again the data and update to the redux store.
    const result = yield call(API.getCommentList, buildFilterClause({
      id: data.id,
    }));

    yield put(updateCommentInfoSuccess(result.data[0]));
    return result;
  } catch (error) {
    yield put(updateCommentInfoFailure(error));
    throw (error);
  }
}

// ---------- FLOW ----------
export function* getCommentListFlow({ payload }) {
  try {
    const result = yield call(getCommentList, payload);
    yield put(getCommentListFlowSuccess({
      condition: payload,
      data: result.data,
    }));
  } catch (error) {
    yield put(getCommentListFlowFailure(error));
  }
}

export function* searchFlow({ payload }) {
  try {
    const result = yield call(getCommentList, payload);
    yield put(searchFlowSuccess({
      condition: payload,
      data: result.data,
    }));
  } catch (error) {
    yield put(searchFlowFailure(error));
  }
}

export function* updateCommentInfoFlow({ payload }) {
  try {
    const result = yield call(updateCommentInfo, payload);
    yield put(updateCommentInfoFlowSuccess(result.data));
  } catch (error) {
    yield put(updateCommentInfoFlowFailure(error));
  }
}

export default [
  takeLatest(getCommentListFlowRequest, getCommentListFlow),
  takeLatest(searchFlowRequest, searchFlow),
  takeLatest(updateCommentInfoFlowRequest, updateCommentInfoFlow),
];
