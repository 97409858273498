import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Table, Input, Button } from 'antd';
import ReduxStatusVisualizedComponent from '../../../../components/ReduxStatusVisualizedComponent';
import WithdrawInfoComponent from './WithdrawInfoComponent';

const { Search } = Input;

const styles = {
  mainContainer: {
    margin: 20,
  },
  searchBarStyle: {
    margin: '30px 0',
    width: 300,
  },
  loadMoreButton: {
    margin: '20px 0',
    width: '100%',
  },
};

const columns = [
  {
    title: '提領編號',
    dataIndex: 'id',
    width: 150,
  },
  {
    title: '交易型態',
    dataIndex: ['order', 'type', 'name'],
    render: name => (name === 'rent' ? '租賃' : '買斷'),
    width: 150,
  },
  {
    title: '提出時間',
    dataIndex: 'requestDate',
    render: data => `${new Date(data).toLocaleString()}`,
  },
  {
    title: '提領金額',
    dataIndex: ['order', 'ownerEarnedAmount'],
  },
  {
    title: '使用者',
    dataIndex: ['user', 'payeeName'],
  },
  {
    title: '是否匯款',
    dataIndex: ['isRemit'],
    render: data => (data ? '是' : '否'),
  },
];

const OrderManagementPage = ({
  search,
  reduxStatus,
  reduxError,
  data,
  updateWithdrawInfo,
  deleteWithdraw,
  handleLoadMore,
  unRemitNumber,
}) => {
  const [selectedOrder, setSelectedOrder] = useState(null);

  useEffect(() => {
    setSelectedOrder(null);
  }, [data]);

  const onRow = record => ({
    onClick: () => {
      setSelectedOrder(record);
    },
  });

  const getRowKey = ({ id, name }) => (`${id}.${name}`);
  return (
    <div style={styles.mainContainer} key="baseContainer">
      <Search
        placeholder="搜尋"
        onSearch={value => search(value)}
        enterButton
        size="large"
        style={styles.searchBarStyle}
      />
      <ReduxStatusVisualizedComponent
        reduxStatus={reduxStatus}
        showModalOnError
        modalFailureContent={reduxError}
      >
        {`尚未匯款筆數: ${unRemitNumber}`}
        <Table
          columns={columns}
          dataSource={data}
          rowKey={getRowKey}
          onRow={onRow}
          expandRowByClick
          pagination={false}
        />

        <Button
          style={styles.loadMoreButton}
          onClick={handleLoadMore}
          type="primary"
        >
          載入更多訂單
        </Button>
      </ReduxStatusVisualizedComponent>

      <WithdrawInfoComponent
        withdrawInfo={selectedOrder}
        updateWithdrawInfo={updateWithdrawInfo}
        deleteWithdraw={deleteWithdraw}
        cancelSelectWithdraw={() => setSelectedOrder(null)}
      />
    </div>
  );
};

OrderManagementPage.propTypes = {
  reduxStatus: PropTypes.string.isRequired,
  reduxError: PropTypes.string,
  data: PropTypes.array,
  updateWithdrawInfo: PropTypes.func.isRequired,
  search: PropTypes.func.isRequired,
  deleteWithdraw: PropTypes.func.isRequired,
  handleLoadMore: PropTypes.func.isRequired,
  unRemitNumber: PropTypes.number,
};

OrderManagementPage.defaultProps = {
  reduxError: null,
  data: [],
  unRemitNumber: null,
};

export default OrderManagementPage;
