import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { Modal, Button } from 'antd';
import renderTableFormField from './renderTableFormField';
import renderSeparator from './renderSeparator';

const styles = {
  tableStyle: {
    margin: '10px auto',
  },
};

const FormTableComponent = ({
  data,
  updateData,
  deleteData,
  createData,
  isCreating,
  cancelSelection,
  renderTableRows,
}) => {
  const [modifiedData, setModifiedData] = useState({});
  const [showDialog, setShowDialog] = useState(false);
  useEffect(() => {
    if (data !== null) {
      setShowDialog(true);
      setModifiedData({});
    } else {
      setShowDialog(false);
    }
  }, [data]);

  const handleSave = (event) => {
    event.preventDefault();
    if (isCreating) {
      createData(modifiedData);
      return;
    }

    const id = R.pathOr(null, ['id'], data);
    if (!id) return;
    updateData({
      ...modifiedData,
      id,
    });
  };

  const handleDelete = (event) => {
    event.preventDefault();
    deleteData(data);
  };

  const renderField = rowData => renderTableFormField({
    ...rowData,
    modifiedData,
    setModifiedData,
    data,
  });

  return (
    <Modal
      visible={showDialog}
      onCancel={cancelSelection}
      footer={[
        deleteData && !isCreating && <Button key="delete" onClick={handleDelete}>刪除</Button>,
        <Button key="close" onClick={cancelSelection}>關閉</Button>,
        !R.isEmpty(modifiedData) && (<Button key="save" type="submit" onClick={handleSave}>儲存</Button>),
      ]}
    >
      <form onSubmit={handleSave}>
        <table style={styles.tableStyle}>
          <tbody>

            {renderTableRows(renderField, renderSeparator)}

          </tbody>
        </table>
        {
          // NOTE: This is a hidden button. This is for auto submitting when user press the enter key.
          !R.isEmpty(modifiedData) && (<button type="submit" style={{ display: 'none' }} onClick={handleSave}>儲存</button>)
        }
      </form>
    </Modal>

  );
};

FormTableComponent.propTypes = {
  data: PropTypes.shape({}),
  updateData: PropTypes.func.isRequired,
  cancelSelection: PropTypes.func.isRequired,
  renderTableRows: PropTypes.func.isRequired,
  createData: PropTypes.func,
  isCreating: PropTypes.bool,
  deleteData: PropTypes.func,
};

FormTableComponent.defaultProps = {
  data: null,
  deleteData: null,
  createData: null,
  isCreating: false,
};

export default FormTableComponent;
