import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ActionCreator from '../../ActionCreator';
import NegotiationManagementPage from './NegotiationManagementPage';
import {
  reduxStatusSelector,
  reduxErrorSelector,
  negotiationListSelector,
} from '../../selector';
import {
  memberIdSelector,
} from '../../../Auth/selector';

const NegotiationContainer = ({
  reduxStatus,
  reduxError,
  data,
  memberId,
  actions: {
    dealNegotiationFlowRequest,
    getNegotiationListFlowRequest,
    resetStatus,
  },
}) => {
  useEffect(() => {
    if (memberId) getNegotiationListFlowRequest();
    return resetStatus;
  }, []);

  const updateNegotiationInfo = (updateData) => {
    dealNegotiationFlowRequest(updateData);
  };

  return (
    <NegotiationManagementPage
      memberId={memberId}
      updateNegotiationInfo={updateNegotiationInfo}
      data={data}
      reduxStatus={reduxStatus}
      reduxError={reduxError}
    />
  );
};

NegotiationContainer.propTypes = {
  reduxStatus: PropTypes.string,
  reduxError: PropTypes.string,
  data: PropTypes.array.isRequired,
  memberId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  actions: PropTypes.shape({
    dealNegotiationFlowRequest: PropTypes.func.isRequired,
    getNegotiationListFlowRequest: PropTypes.func.isRequired,
    resetStatus: PropTypes.func.isRequired,
  }).isRequired,
};

NegotiationContainer.defaultProps = {
  reduxStatus: null,
  reduxError: null,
  memberId: null,
};

const mapStateToProps = state => ({
  reduxStatus: reduxStatusSelector(state),
  reduxError: reduxErrorSelector(state),
  data: negotiationListSelector(state),
  memberId: memberIdSelector(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    ...ActionCreator,
  }, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NegotiationContainer);
