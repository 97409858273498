import React from 'react';
import PropTypes from 'prop-types';
import {
  Layout,
} from 'antd';
import SiderMenu from './Sider/SiderMenu';

const {
  Content,
  Sider,
} = Layout;

const styles = {
  mainContainer: {
    minHeight: '100vh',
  },
  contentContainer: {
    backgroundColor: 'white',
  },
};

const GeneralLayout = (props) => {
  const {
    Child,
    ...otherProps
  } = props;

  return (
    <Layout
      style={styles.mainContainer}
    >
      <Sider>
        <SiderMenu {...otherProps} />
      </Sider>
      <Layout>
        <Content style={styles.contentContainer}>
          <Child {...otherProps} />
        </Content>
      </Layout>
    </Layout>
  );
};

GeneralLayout.propTypes = {
  Child: PropTypes.func.isRequired,
  path: PropTypes.string.isRequired,
};

GeneralLayout.defaultProps = {
};

export default GeneralLayout;
