export default {
  SIGN_IN: '/sign_in',
  SIGN_OUT: '/sign_out',
  DASHBOARD: '/dashboard',
  MEMBER_MANAGEMENT: '/member_management',
  TAG_MANAGEMENT: '/tag_management',
  BAG_MANAGEMENT: '/bag_management',
  ORDER_MANAGEMENT: '/order_management',
  CONTACT: '/contact',
  SUBSCRIBE: '/subscribe',
  CART_MANAGEMENT: '/cart_management',
  THEME_MANAGEMENT: '/theme_management',
  COMMENT_MANAGEMENT: '/comment_management',
  NEGOTIATION_MANAGEMENT: '/negotiation_management',
  REDUCE_DEPOSIT_MANAGEMENT: '/reduce_deposit_management',
  WITHDRAW_MANAGEMENT: '/withdraw_management',
  CLOTHES_MANAGEMENT: '/clothes_management',
  TAPPAY_PAY_BY_TOKEN_MANAGEMENT: '/tappay_pay_by_token_management',
  COUPON_MANAGEMENT: '/coupon_management',
  ACCESSORIES_MANAGEMENT: '/accessories_management',
  ESSENCE_MANAGEMENT: '/essence_management',
  ADDRESS_MANAGEMENT: '/address_management',
  MARQUEE_MANAGEMENT: '/marquee_management',
  IDENTIFICATION_MANAGEMENT: '/identification_management',
};
