const bagStatus = {
  pending: '待處理',
  // Note: due to no shipping fee when submit bags
  // pleasePayTransmitFee: '請繳交運送費',
  // payComplete: '繳費完成',
  shipping: '收貨中',
  inStock: '有現貨',
  appraisalFail: '審核未通過',
  rented: '出租中',
  bagReturn: '商品已寄回',
  bagCheck: '包款歸還檢查中',
  bagNursing: '包款護理',
  bagNursingFinished: '包款護理結束',
  terminate: '終止出租',
  sold: '已售出',
  pickup: '完成取件',
  reviewFail: '鑑定未通過',
  onMarket: '上架中',
};

const bagStatusId = {
  pending: 1,
  // Note: due to no shipping fee when submit bags
  // pleasePayTransmitFee: 2,
  // payComplete: 3,
  shipping: 4,
  inStock: 5,
  appraisalFail: 6,
  rented: 7,
  bagReturn: 8,
  bagCheck: 9,
  bagNursing: 10,
  bagNursingFinished: 11,
  terminate: 12,
  sold: 13,
  pickup: 14,
  reviewFail: 15,
  onMarket: 16,
};

module.exports = {
  bagStatus,
  bagStatusId,
};
