
const zeroCardStatusId = {
  notPayNoAPP: '001',
  reviewByMan: '002',
  successButNotInvoiceYet: '003',
  invoicing: '004',
  appropriation: '005',
  reject: '006',
  canceledAndRefund: '007',
  canceled: '008',
  cancelProcessing: '009',
};

const zeroCardStatus = {
  notPayNoAPP: '消費者尚未在 payment_url 上進行操作',
  reviewByMan: '此交易已轉專人審核處理中',
  successButNotInvoiceYet: '交易已核准但尚未請款',
  invoicing: '交易請款中',
  appropriation: '交易已撥款',
  reject: '交易失敗(婉拒)',
  canceledAndRefund: '交易在核准後通知取消或已全額退款',
  canceled: '訂單在審核時取消或逾時取消',
  cancelProcessing: '部份取消資料處理中',
};

module.exports = {
  zeroCardStatusId,
  zeroCardStatus,
};
