import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { navigate, Location } from '@reach/router';
import DeepEqual from 'fast-deep-equal';
import * as R from 'ramda';
import Paths from '../../../../routes/Paths';
import BagSearchPanel from './BagSearchPanel';
import * as SearchBagHelper from '../../../../utils/SearchBagHelper';
import ActionCreators from '../../ActionCreator';
import {
  filterConditionSelector,
} from '../../selector';
import TagActionCreators from '../../../TagManagement/ActionCreator';
import {
  categorizedTagListSelector,
} from '../../../TagManagement/selector';

const BagSearchPanelContainer = ({
  location,
  filterCondition,
  actions: {
    resetStatus,
    getBagListFlowRequest,
    getTagListFlowRequest,
  },
  tags,
  page,
  commodityType,
}) => {
  const [loadPage, setLoadPage] = useState(-1);
  const [condition, setCondition] = useState({});

  useEffect(() => {
    try {
      const {
        page: oldPage,
        ...restOfFilterCondition
      } = filterCondition || {};
      const filterConditionFromSearch = {
        ...SearchBagHelper.getCurrentFilterConditionFromQuery(location),
        commodityType,
      };

      if (!DeepEqual(filterCondition ? restOfFilterCondition : null, filterConditionFromSearch)) {
        // NOTE: If the base filter condition has changed, we need to load the data from the beginning.
        //       So we reset the loadPage to 0.
        setLoadPage(0);
        const newSearchCondition = {
          ...filterConditionFromSearch,
          commodityType,
          page: 0,
        };
        setCondition(newSearchCondition);
        getBagListFlowRequest(newSearchCondition);
      } else if (oldPage !== loadPage && loadPage >= 0) {
        const newSearchCondition = {
          ...restOfFilterCondition,
          commodityType,
          page: loadPage,
        };
        setCondition(newSearchCondition);
        getBagListFlowRequest(newSearchCondition);
      } else {
        setCondition(filterCondition);
      }
    } catch (error) {
      setCondition({});
      getBagListFlowRequest();
    }
    return resetStatus;
  }, [filterCondition, location, Math.max(loadPage, 0), commodityType]);

  useEffect(() => {
    setLoadPage(loadPage + 1);
  }, [page]);

  useEffect(() => {
    getTagListFlowRequest();
  }, []);

  const handleSearchConditionChange = (newFilterCondition) => {
    const queryString = SearchBagHelper.getQueryStringFromFilterCondition(newFilterCondition, location);
    navigate(`${R.pathOr(Paths.BAG_MANAGEMENT, ['pathname'], location)}${queryString}`);
  };

  return (
    <BagSearchPanel
      handleSearchConditionChange={handleSearchConditionChange}
      tags={tags || []}
      condition={condition}
    />
  );
};

BagSearchPanelContainer.propTypes = {
  filterCondition: PropTypes.shape({}),
  location: PropTypes.shape({}),
  tags: PropTypes.array,
  actions: PropTypes.shape({
    getTagListFlowRequest: PropTypes.func.isRequired,
    getBagListFlowRequest: PropTypes.func.isRequired,
    resetStatus: PropTypes.func.isRequired,
  }).isRequired,
  page: PropTypes.number,
  commodityType: PropTypes.number,
};

BagSearchPanelContainer.defaultProps = {
  filterCondition: null,
  location: {},
  tags: [],
  page: 0,
  commodityType: 1,
};

const mapStateToProps = state => ({
  filterCondition: filterConditionSelector(state),
  tags: categorizedTagListSelector(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    ...ActionCreators,
    ...TagActionCreators,
  }, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(props => (
  <Location>
    {({ location }) => (<BagSearchPanelContainer location={location} {...props} />)}
  </Location>
));
