import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';
import * as R from 'ramda';
import API from './API';
import ActionCreator from './ActionCreator';

const {
  searchFlowRequest,
  searchFlowSuccess,
  searchFlowFailure,

  updateWithdrawInfoFlowRequest,
  updateWithdrawInfoFlowFailure,
  updateWithdrawInfoFlowSuccess,
  updateWithdrawInfoRequest,
  updateWithdrawInfoFailure,
  updateWithdrawInfoSuccess,

  deleteWithdrawFlowRequest,
  deleteWithdrawFlowFailure,
  deleteWithdrawFlowSuccess,
  deleteWithdrawRequest,
  deleteWithdrawFailure,
  deleteWithdrawSuccess,

  getWithdrawListRequest,
  getWithdrawListSuccess,
  getWithdrawListFailure,
  getWithdrawListFlowRequest,
  getWithdrawListFlowSuccess,
  getWithdrawListFlowFailure,

  getUnRemitNumbersRequest,
  getUnRemitNumbersSuccess,
  getUnRemitNumbersFailure,
  getUnRemitNumbersFlowRequest,
  getUnRemitNumbersFlowSuccess,
  getUnRemitNumbersFlowFailure,
} = ActionCreator;


// ---------- NORMAL FUNCTIONS ----------
const buildFilterClause = (filterCondition = {}) => {
  try {
    const {
      keyword,
      page = 0,
      ...otherCondition
    } = filterCondition;

    const pageLimit = 10;

    const where = {};
    if (keyword) {
      Object.assign(where, {
        id: keyword,
      });
    }

    if (otherCondition && Object.keys(otherCondition).length > 0) {
      Object.assign(where, otherCondition);
    }

    const assignedFilterCondition = {
      limit: pageLimit,
      skip: pageLimit * page,
      order: 'requestDate desc',
      include: [
        {
          relation: 'user',
          scope: { fields: ['payeeName', 'email', 'payeeBankCode', 'payeeBankAccount', 'payeeBankBranch'] },
        },
        {
          relation: 'order',
          scope: { fields: ['ownerEarnedAmount', 'typeId'] },
        },
      ],
      ...(R.isEmpty(where) ? {} : { where }),
    };

    return assignedFilterCondition;
  } catch (error) {
    // eslint-disable-next-line
    console.error(error);
    return {};
  }
};


// Task
export function* getWithdrawList(filterCondition = {}) {
  try {
    yield put(getWithdrawListRequest());
    const result = yield call(API.getWithdrawList, buildFilterClause(filterCondition));
    yield put(getWithdrawListSuccess(result.data));
    return result;
  } catch (error) {
    yield put(getWithdrawListFailure(error));
    throw (error);
  }
}

function* updateWithdrawInfo(data) {
  yield put(updateWithdrawInfoRequest());
  try {
    const {
      id,
      ...restOfData
    } = (data || {});

    if (!R.isEmpty(restOfData)) {
      yield call(API.updateWithdrawInfo, {
        ...restOfData,
        id,
      });
    }

    const result = yield call(API.getWithdrawList, buildFilterClause({ keyword: id }));
    yield put(updateWithdrawInfoSuccess(result.data[0]));

    return result;
  } catch (error) {
    yield put(updateWithdrawInfoFailure(error));
    throw (error);
  }
}


function* deleteWithdraw(data) {
  yield put(deleteWithdrawRequest());
  try {
    const result = yield call(API.deleteWithdraw, data);
    yield put(deleteWithdrawSuccess(data));
    return result;
  } catch (error) {
    yield put(deleteWithdrawFailure(error));
    throw (error);
  }
}

function* getUnRemitNumbers() {
  try {
    yield put(getUnRemitNumbersRequest());
    const result = yield call(API.getRemitNumbers, { isRemit: { neq: true } });
    yield put(getUnRemitNumbersSuccess(result.data));
    return result;
  } catch (error) {
    yield put(getUnRemitNumbersFailure(error));
    throw (error);
  }
}

// Flow
export function* searchFlow({ payload }) {
  try {
    const result = yield call(getWithdrawList, payload);
    yield put(searchFlowSuccess({
      condition: payload,
      data: result.data,
    }));
  } catch (error) {
    yield put(searchFlowFailure(error));
  }
}

export function* getWithdrawListFlow({ payload }) {
  try {
    const result = yield call(getWithdrawList, payload);
    yield put(getWithdrawListFlowSuccess({
      condition: payload,
      data: result.data,
    }));
  } catch (error) {
    yield put(getWithdrawListFlowFailure(error));
  }
}

export function* updateWithdrawInfoFlow({ payload }) {
  try {
    const result = yield call(updateWithdrawInfo, payload);
    yield put(updateWithdrawInfoFlowSuccess(result.data));
  } catch (error) {
    yield put(updateWithdrawInfoFlowFailure(error));
  }
}


export function* deleteWithdrawFlow({ payload }) {
  try {
    yield call(deleteWithdraw, payload);
    yield put(deleteWithdrawFlowSuccess(payload));
  } catch (error) {
    yield put(deleteWithdrawFlowFailure(error));
  }
}

export function* getUnRemitNumbersFlow({ payload }) {
  try {
    const result = yield call(getUnRemitNumbers, payload);
    yield put(getUnRemitNumbersFlowSuccess(result.data.count));
  } catch (error) {
    yield put(getUnRemitNumbersFlowFailure(error));
  }
}

export default [
  takeLatest(searchFlowRequest, searchFlow),
  takeLatest(updateWithdrawInfoFlowRequest, updateWithdrawInfoFlow),
  takeLatest(deleteWithdrawFlowRequest, deleteWithdrawFlow),
  takeLatest(getWithdrawListFlowRequest, getWithdrawListFlow),
  takeLatest(getUnRemitNumbersFlowRequest, getUnRemitNumbersFlow),
];
