import api from '../../lib/configAxios';

export default {

  getSubscribeList: async (filter = { limit: 10, order: 'id DESC' }) => api.get(
    `Subscribes?filter=${encodeURIComponent(JSON.stringify(filter))}`,
  ),

  updateSubscribeInfo: async data => api.patch(
    `Subscribes/${data.id}`, data,
  ),

  deleteSubscribe: async data => api.delete(
    `Subscribes/${data.id}`,
  ),
  sendNoticeToSubscriber: async data => api.post(
    'Subscribes/sendNotice/', data,
  ),
};
