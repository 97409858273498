import { handleActions, combineActions } from 'redux-actions';
import update from 'immutability-helper';
import ActionCreator from './ActionCreator';
import ReduxStatus from '../../constants/ReduxStatus';

const {
  getReduceDepositListFlowRequest,
  getReduceDepositListSuccess,
  getReduceDepositListFailure,

  createReduceDepositFlowRequest,
  createReduceDepositSuccess,
  createReduceDepositFailure,

  deleteReduceDepositFlowRequest,
  deleteReduceDepositSuccess,
  deleteReduceDepositFailure,
} = ActionCreator;

const initialState = {
  reduxStatus: ReduxStatus.STATUS_INITIAL,
  error: null,
  data: [],
};

export default handleActions({
  [combineActions(
    getReduceDepositListFlowRequest,
    createReduceDepositFlowRequest,
    deleteReduceDepositFlowRequest,
  )]: state => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_LOADING },
    error: { $set: initialState.error },
  }),

  [combineActions(
    getReduceDepositListSuccess,
    createReduceDepositSuccess,
    deleteReduceDepositSuccess,
  )]: (state, { payload }) => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_NORMAL },
    data: { $set: payload },
  }),
  [combineActions(
    getReduceDepositListFailure,
    createReduceDepositFailure,
    deleteReduceDepositFailure,
  )]: (state, { payload }) => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_ERROR },
    error: { $set: payload },
  }),
}, initialState);
