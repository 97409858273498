import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ActionCreators from '../../../MemberManagement/ActionCreator';
import CartActionCreators from '../../ActionCreator';
import CartManagementPage from './CartManagementPage';

import {
  reduxStatusSelector,
  reduxErrorSelector,
  memberListSelector,
} from '../../../MemberManagement/selector';

import {
  cartListSelector,
} from '../../selector';

const CartManagementPageContainer = ({
  reduxStatus,
  reduxError,
  actions: {
    getMemberListFlowRequest,
    searchByKeywordFlowRequest,
    updateMemberInfoFlowRequest,
    resetStatus,
    getCartListFlowRequest,
  },
  data,
  cartList,
  onMemberSelected,
}) => {
  useEffect(() => {
    if (!data || data.length === 0) getMemberListFlowRequest();
    else resetStatus();
    return resetStatus;
  }, []);

  return (
    <CartManagementPage
      reduxStatus={reduxStatus}
      reduxError={reduxError}
      data={data}
      searchByKeyword={searchByKeywordFlowRequest}
      updateMemberInfo={updateMemberInfoFlowRequest}
      onMemberSelected={onMemberSelected}
      type="cartInfo"
      cartList={cartList}
      getCartListFlowRequest={getCartListFlowRequest}
    />
  );
};

CartManagementPageContainer.propTypes = {
  reduxStatus: PropTypes.string,
  reduxError: PropTypes.string,
  actions: PropTypes.shape({
    getMemberListFlowRequest: PropTypes.func.isRequired,
    getCartListFlowRequest: PropTypes.func.isRequired,
    searchByKeywordFlowRequest: PropTypes.func.isRequired,
    updateMemberInfoFlowRequest: PropTypes.func.isRequired,
    resetStatus: PropTypes.func.isRequired,
  }).isRequired,
  data: PropTypes.array,
  cartList: PropTypes.array,
  onMemberSelected: PropTypes.func,
};

CartManagementPageContainer.defaultProps = {
  reduxStatus: null,
  reduxError: null,
  data: [],
  cartList: [],
  onMemberSelected: null,
};

const mapStateToProps = state => ({
  reduxStatus: reduxStatusSelector(state),
  reduxError: reduxErrorSelector(state),
  data: memberListSelector(state),
  cartList: cartListSelector(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    ...ActionCreators,
    ...CartActionCreators,
  }, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CartManagementPageContainer);
