import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Table, Input, Button } from 'antd';
import ReduxStatusVisualizedComponent from '../../../../components/ReduxStatusVisualizedComponent';
import ReduceDepositInfoComponent from './ReduceDepositInfoComponent';

const { Search } = Input;

const styles = {
  mainContainer: {
    margin: 20,
  },
  searchBarStyle: {
    margin: '30px 0',
    width: 300,
  },
};

const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    width: 150,
    sorter: (a, b) => Number(a.id) - Number(b.id),
    sortDirections: ['ascend', 'descend'],
  },
  {
    title: '包主userId',
    dataIndex: ['ownerId'],
    sorter: (a, b) => Number(a.ownerId) - Number(b.ownerId),
    sortDirections: ['ascend', 'descend'],
  },
  {
    title: '包主',
    dataIndex: ['owner', 'name'],
  },
  {
    title: '減免租金人userId',
    dataIndex: ['userId'],
  },
  {
    title: '減免租金人',
    dataIndex: ['user', 'name'],
  },
  {
    title: '包包Id',
    dataIndex: ['bagId'],
    sorter: (a, b) => Number(a.bagId) - Number(b.bagId),
    sortDirections: ['ascend', 'descend'],
  },
  {
    title: '包包',
    dataIndex: ['bag', 'name'],
  },
];

const ReduceDepositManagementPage = ({
  reduxStatus,
  reduxError,
  data,
  search,
  createReduceDeposit,
  deleteReduceDeposit,
}) => {
  const getRowKey = ({ id, email }) => (`${id}.${email}`);

  const [selectedReduceDeposit, setSelectedReduceDeposit] = useState(null);

  useEffect(() => {
    setSelectedReduceDeposit(null);
  }, [data]);

  const onRow = record => ({
    onClick: () => {
      setSelectedReduceDeposit(record);
    },
  });

  return (
    <div style={styles.mainContainer} key="baseContainer">
      <Search
        placeholder="包主ID"
        onSearch={value => search(value)}
        enterButton
        size="large"
        style={styles.searchBarStyle}
      />

      <ReduxStatusVisualizedComponent
        reduxStatus={reduxStatus}
        showModalOnError
        modalFailureContent={reduxError}
      >
        <Button onClick={() => { setSelectedReduceDeposit({}); }}>
          新增減免押金
        </Button>
        <Table
          rowKey={getRowKey}
          onRow={onRow}
          columns={columns}
          childrenColumnName="ReduceDeposits"
          dataSource={data}
          expandRowByClick
          pagination={false}
        />

      </ReduxStatusVisualizedComponent>

      <ReduceDepositInfoComponent
        reduceDepositInfo={selectedReduceDeposit}
        createReduceDeposit={createReduceDeposit}
        deleteReduceDeposit={deleteReduceDeposit}
        cancelSelectReduceDeposit={() => setSelectedReduceDeposit(null)}
      />
    </div>
  );
};

ReduceDepositManagementPage.propTypes = {
  reduxStatus: PropTypes.string.isRequired,
  reduxError: PropTypes.string,
  data: PropTypes.array,
  search: PropTypes.func.isRequired,
  createReduceDeposit: PropTypes.func.isRequired,
  deleteReduceDeposit: PropTypes.func.isRequired,
};

ReduceDepositManagementPage.defaultProps = {
  data: [],
  reduxError: null,
};

export default ReduceDepositManagementPage;
