import * as R from 'ramda';
import { createSelector } from 'reselect';
import { bagManagementStateSelector } from '../../lib/rootSelector';

export const reduxStatusSelector = createSelector(
  bagManagementStateSelector,
  result => R.path(['reduxStatus'], result),
);

export const reduxErrorSelector = createSelector(
  bagManagementStateSelector,
  result => R.path(['error', 'message'], result),
);

export const bagListSelector = createSelector(
  bagManagementStateSelector,
  result => R.path(['data'], result),
);

export const bagNumberSelector = createSelector(
  bagManagementStateSelector,
  result => R.path(['bagNumbers'], result),
);

export const modifiedBagNumberSelector = createSelector(
  bagManagementStateSelector,
  result => R.path(['modifiedBagNumbers'], result),
);

export const filterConditionSelector = createSelector(
  bagManagementStateSelector,
  result => R.path(['filterCondition'], result),
);
