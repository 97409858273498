import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';
import ActionCreator from './ActionCreator';
import MemberActionCreator from '../MemberManagement/ActionCreator';
import API from './API';


const { getMemberListFlowRequest } = MemberActionCreator;

const {
  getCouponListRequest,
  getCouponListSuccess,
  getCouponListFailure,
  getCouponListFlowRequest,
  getCouponListFlowSuccess,
  getCouponListFlowFailure,

  getCouponTypeListRequest,
  getCouponTypeListSuccess,
  getCouponTypeListFailure,
  getCouponTypeListFlowRequest,
  getCouponTypeListFlowSuccess,
  getCouponTypeListFlowFailure,

  createCouponFlowRequest,
  createCouponFlowSuccess,
  createCouponFlowFailure,
  createCouponRequest,
  createCouponSuccess,
  createCouponFailure,

  updateCouponFlowRequest,
  updateCouponFlowSuccess,
  updateCouponFlowFailure,
  updateCouponRequest,
  updateCouponSuccess,
  updateCouponFailure,

  deleteCouponFlowRequest,
  deleteCouponFlowSuccess,
  deleteCouponFlowFailure,
  deleteCouponRequest,
  deleteCouponSuccess,
  deleteCouponFailure,
} = ActionCreator;


// ---------- TASKS ----------
function* getCouponTypeList() {
  try {
    yield put(getCouponTypeListRequest());
    const result = yield call(API.getCouponTypeList);
    yield put(getCouponTypeListSuccess(result.data));
    return result;
  } catch (error) {
    yield put(getCouponTypeListFailure(error));
    throw (error);
  }
}

function* getCouponList() {
  try {
    yield put(getCouponListRequest());
    yield put(getMemberListFlowRequest());
    const result = yield call(API.getCouponList, { include: ['couponType', 'owners'] });
    yield put(getCouponListSuccess(result.data));
    return result;
  } catch (error) {
    yield put(getCouponListFailure(error));
    throw (error);
  }
}
function* createCoupon(data) {
  try {
    const { isGlobal, owners } = data;

    if (isGlobal === undefined) {
      if (owners.length > 0) {
        Object.assign(data, { isGlobal: false });
      } else {
        Object.assign(data, { isGlobal: true });
      }
    }

    yield put(createCouponRequest());
    const result = yield call(API.createCoupon, data);
    yield put(createCouponSuccess(result.data));
    return result;
  } catch (error) {
    yield put(createCouponFailure(error));
    throw (error);
  }
}

function* updateCoupon(data) {
  yield put(updateCouponRequest());
  try {
    yield call(API.updateCouponInfo, data);

    const result = yield call(API.getCouponList, {
      where: {
        id: data.id,
      },
      include: ['couponType', 'owners'],
    });

    yield put(updateCouponSuccess(result.data[0]));
    return result;
  } catch (error) {
    yield put(updateCouponFailure(error));
    throw (error);
  }
}

function* deleteCoupon(data) {
  yield put(deleteCouponRequest());
  try {
    const result = yield call(API.deleteCoupon, data);
    yield put(deleteCouponSuccess(data));
    return result;
  } catch (error) {
    yield put(deleteCouponFailure(error));
    throw (error);
  }
}

// ---------- FLOW ----------
export function* getCouponTypeListFlow() {
  try {
    yield call(getCouponTypeList);
    yield put(getCouponTypeListFlowSuccess());
  } catch (error) {
    yield put(getCouponTypeListFlowFailure(error));
  }
}

export function* getCouponListFlow() {
  try {
    yield call(getCouponList);
    yield put(getCouponListFlowSuccess());
  } catch (error) {
    yield put(getCouponListFlowFailure(error));
  }
}


export function* createCouponFlow({ payload }) {
  try {
    const result = yield call(createCoupon, payload);
    if (result.status === 200) {
      yield call(getCouponList);
    }
    yield put(createCouponFlowSuccess(payload));
  } catch (error) {
    yield put(createCouponFlowFailure(error));
  }
}

export function* updateCouponFlow({ payload }) {
  try {
    const result = yield call(updateCoupon, payload);
    yield put(updateCouponFlowSuccess(result.data));
  } catch (error) {
    yield put(updateCouponFlowFailure(error));
  }
}

export function* deleteCouponFlow({ payload }) {
  try {
    yield call(deleteCoupon, payload);
    yield put(deleteCouponFlowSuccess(payload));
  } catch (error) {
    yield put(deleteCouponFlowFailure(error));
  }
}


export default [
  takeLatest(getCouponTypeListFlowRequest, getCouponTypeListFlow),
  takeLatest(getCouponListFlowRequest, getCouponListFlow),
  takeLatest(createCouponFlowRequest, createCouponFlow),
  takeLatest(updateCouponFlowRequest, updateCouponFlow),
  takeLatest(deleteCouponFlowRequest, deleteCouponFlow),

];
