import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import FormTableComponent from '../../../../components/FormTable/FormTableComponent';
import Uploader from '../../../ResourceUploader/components/Uploader';
import InputDialog from '../../../../components/InputDialog';
import ThemeBagManagementComponent from '../ThemeBagManagementComponent';

const styles = {
  image: {
    width: 120,
    height: 120,
    objectFit: 'cover',
    borderRadius: 5,
    cursor: 'pointer',
    display: 'inline-block',
    verticalAlign: 'middle',
  },
  uploader: {
    display: 'inline-block',
    verticalAlign: 'middle',
    margin: 5,
  },
};

const ThemeInfoComponent = ({
  themeInfo,
  updateTheme,
  deleteTheme,
  createTheme,
  cancelSelectTheme,
}) => {
  const [showDeleteConfirmDialog, setShowDeleteConfirmDialog] = useState(false);

  const isCreating = Object.keys(themeInfo || {}).length === 0;

  const renderImageChild = (data = {}) => (
    <div>
      <img
        src={R.pathOr(R.pathOr(null, [data.key, 'uri'], themeInfo), ['modifiedValue', 'uri'], data)}
        style={styles.image}
        alt=""
      />
      <div style={styles.uploader}>
        <Uploader
          onFileUploaded={(result) => {
            const {
              handleChange,
            } = data;
            handleChange(result);
          }}
        />
      </div>
    </div>
  );


  const renderTableRows = (renderField, renderSeparator) => [
    renderField({ key: 'name', name: '名稱' }),
    renderField({ key: 'isActive', name: '啟用', type: 'checkbox' }),
    renderField({ key: 'order', name: '順序' }),
    renderSeparator(1),
    renderField({
      key: 'resource',
      name: '照片',
      type: 'custom',
      renderChild: (modifiedValue, isModified, handleChange) => renderImageChild({
        modifiedValue,
        isModified,
        handleChange,
        key: 'resource',
      }),
    }),
    renderSeparator(2),
    renderField({
      key: 'bags',
      name: '主題活動包包',
      type: 'custom',
      renderChild: () => (
        R.pathOr(null, ['id'], themeInfo) ? (
          <ThemeBagManagementComponent
            themeId={R.pathOr(null, ['id'], themeInfo)}
          />
        ) : '(請先新增這個主題活動，再編輯主題活動包包)'
      ),
    }),
  ];

  return [
    <FormTableComponent
      key="FormTableComponent"
      data={themeInfo}
      updateData={updateTheme}
      cancelSelection={cancelSelectTheme}
      renderTableRows={renderTableRows}
      deleteData={() => setShowDeleteConfirmDialog(true)}
      createData={createTheme}
      isCreating={isCreating}
    />,
    <InputDialog
      key="deleteConfirmDialog"
      title="如果真的要刪除，請輸入您的密碼"
      inputProps={{ type: 'password' }}
      isVisible={showDeleteConfirmDialog}
      onSubmit={(value) => {
        setShowDeleteConfirmDialog(false);
        if (value) {
          deleteTheme({
            id: R.path(['id'], themeInfo),
            password: value,
          });
        }
      }}
    />,
  ];
};

ThemeInfoComponent.propTypes = {
  themeInfo: PropTypes.shape({}),
  updateTheme: PropTypes.func.isRequired,
  deleteTheme: PropTypes.func.isRequired,
  createTheme: PropTypes.func.isRequired,
  cancelSelectTheme: PropTypes.func.isRequired,
};

ThemeInfoComponent.defaultProps = {
  themeInfo: null,
};

export default ThemeInfoComponent;
