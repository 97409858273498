import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';
import * as R from 'ramda';
import ActionCreator from './ActionCreator';
import API from './API';

const {
  getContactListRequest,
  getContactListSuccess,
  getContactListFailure,
  updateContactInfoRequest,
  updateContactInfoSuccess,
  updateContactInfoFailure,
  deleteContactRequest,
  deleteContactSuccess,
  deleteContactFailure,


  getContactListFlowRequest,
  getContactListFlowSuccess,
  getContactListFlowFailure,
  updateContactInfoFlowRequest,
  updateContactInfoFlowSuccess,
  updateContactInfoFlowFailure,
  deleteContactFlowRequest,
  deleteContactFlowSuccess,
  deleteContactFlowFailure,
  searchFlowRequest,
  searchFlowSuccess,
  searchFlowFailure,

  getUnReplyNumbersRequest,
  getUnReplyNumbersSuccess,
  getUnReplyNumbersFailure,
  getUnReplyNumbersFlowRequest,
  getUnReplyNumbersFlowSuccess,
  getUnReplyNumbersFlowFailure,
} = ActionCreator;

// ---------- NORMAL FUNCTIONS ----------
const buildFilterClause = (filterCondition = {}) => {
  try {
    const {
      keyword,
      page = 0,
      ...otherCondition
    } = filterCondition;

    const pageLimit = 10;

    const where = {};
    if (keyword) {
      Object.assign(where, {
        name: {
          like: `%${keyword}%`,
        },
      });
    }

    if (otherCondition && Object.keys(otherCondition).length > 0) {
      Object.assign(where, otherCondition);
    }

    const assignedFilterCondition = {
      limit: pageLimit,
      skip: pageLimit * page,
      order: 'id DESC',
      include: [
      ],
      ...(R.isEmpty(where) ? {} : { where }),
    };

    return assignedFilterCondition;
  } catch (error) {
    // eslint-disable-next-line
    console.error(error);
    return {};
  }
};

// ---------- TASKS ----------
function* getContactList(filterCondition = {}) {
  try {
    yield put(getContactListRequest());
    const result = yield call(API.getContactList, buildFilterClause(filterCondition));
    yield put(getContactListSuccess(result.data));
    return result;
  } catch (error) {
    yield put(getContactListFailure(error));
    throw (error);
  }
}

function* updateContactInfo(data) {
  yield put(updateContactInfoRequest());
  try {
    const {
      id: contactId,
      ...restOfData
    } = (data || {});

    if (!R.isEmpty(restOfData)) {
      yield call(API.updateContactInfo, {
        ...restOfData,
        id: contactId,
      });
    }

    // NOTE: The update operation has been finished successfully.
    //       Now we fetch again the data and update to the redux store.
    const result = yield call(API.getContactList, buildFilterClause({
      id: data.id,
    }));

    yield put(updateContactInfoSuccess(result.data[0]));
    return result;
  } catch (error) {
    yield put(updateContactInfoFailure(error));
    throw (error);
  }
}

function* deleteContact(data) {
  yield put(deleteContactRequest());
  try {
    const result = yield call(API.deleteContact, data);
    yield put(deleteContactSuccess(data));
    return result;
  } catch (error) {
    yield put(deleteContactFailure(error));
    throw (error);
  }
}

function* getUnReplyNumbers() {
  try {
    yield put(getUnReplyNumbersRequest());
    const result = yield call(API.getUnReplyNumbers, { isReply: { neq: true } });
    yield put(getUnReplyNumbersSuccess(result.data));
    return result;
  } catch (error) {
    yield put(getUnReplyNumbersFailure(error));
    throw (error);
  }
}


// ---------- FLOW ----------
export function* getContactListFlow({ payload }) {
  try {
    const result = yield call(getContactList, payload);
    yield put(getContactListFlowSuccess({
      condition: payload,
      data: result.data,
    }));
  } catch (error) {
    yield put(getContactListFlowFailure(error));
  }
}

export function* updateContactInfoFlow({ payload }) {
  try {
    const result = yield call(updateContactInfo, payload);
    yield put(updateContactInfoFlowSuccess(result.data));
  } catch (error) {
    yield put(updateContactInfoFlowFailure(error));
  }
}

export function* deleteContactFlow({ payload }) {
  try {
    yield call(deleteContact, payload);
    yield put(deleteContactFlowSuccess(payload));
  } catch (error) {
    yield put(deleteContactFlowFailure(error));
  }
}


export function* searchFlow({ payload }) {
  try {
    const result = yield call(getContactList, payload);
    yield put(searchFlowSuccess({
      condition: payload,
      data: result.data,
    }));
  } catch (error) {
    yield put(searchFlowFailure(error));
  }
}

export function* getUnReplyNumbersFlow({ payload }) {
  try {
    const result = yield call(getUnReplyNumbers, payload);
    yield put(getUnReplyNumbersFlowSuccess(result.data.count));
  } catch (error) {
    yield put(getUnReplyNumbersFlowFailure(error));
  }
}


export default [
  takeLatest(getContactListFlowRequest, getContactListFlow),
  takeLatest(updateContactInfoFlowRequest, updateContactInfoFlow),
  takeLatest(deleteContactFlowRequest, deleteContactFlow),
  takeLatest(searchFlowRequest, searchFlow),
  takeLatest(getUnReplyNumbersFlowRequest, getUnReplyNumbersFlow),
];
