import { createActions } from 'redux-actions';
import { createAsyncActionTypesArray } from '../../utils/createAsyncActionTypes';

export default createActions(
  // ----- TASKS -----
  ...createAsyncActionTypesArray('GET_COUPON_LIST'),
  ...createAsyncActionTypesArray('GET_COUPON_TYPE_LIST'),
  ...createAsyncActionTypesArray('CREATE_COUPON'),
  ...createAsyncActionTypesArray('DELETE_COUPON'),
  ...createAsyncActionTypesArray('UPDATE_COUPON'),

  // ----- FLOWS -----
  ...createAsyncActionTypesArray('GET_COUPON_LIST_FLOW'),
  ...createAsyncActionTypesArray('GET_COUPON_TYPE_LIST_FLOW'),
  ...createAsyncActionTypesArray('CREATE_COUPON_FLOW'),
  ...createAsyncActionTypesArray('DELETE_COUPON_FLOW'),
  ...createAsyncActionTypesArray('UPDATE_COUPON_FLOW'),

  'RESET_STATUS',

  { prefix: 'COUPON_MANAGEMENT' },
);
