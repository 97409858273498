/**
 * The config object
 *
 * Use REACT_APP_CONFIG_ENV environment variable to determine which env config you want to use.
 *
 * For example:
 * `REACT_APP_CONFIG_ENV=dev yarn start` or
 * `REACT_APP_CONFIG_ENV=dev yarn build` will build the app with config.dev.js
 *
 * NOTE:
 * why not use NODE_ENV?
 * Because NODE_ENV will be forced to 'production' by react-scripts when we run 'yarn build'.
 */

const { REACT_APP_CONFIG_ENV } = process.env;

// if app is running in dev mode, e.g. by `yarn start` or `REACT_APP_CONFIG_ENV=local yarn start`
// use the env variable to determine which env config to use
// default to local if unspecified
const env = REACT_APP_CONFIG_ENV || 'local';
const envConfig = {
  API_BASE_URL: 'http://localhost:3018/api',
};

if (/^dev(elopment)?$/i.test(env)) {
  // FIXME:
  // currently we dont use load balance service for this project.
  // if we want to apply https connection on single EC2 instance,
  // we have to setup eb config files manually.
  // for more details, please refer to the link above:
  // https://docs.aws.amazon.com/elasticbeanstalk/latest/dg/https-singleinstance.html
  // this is a workaround solution to use http connection on dev env.
  envConfig.API_BASE_URL = 'https://api-dev.ohphire.com/api';
}

if (/^stg(ing)?$/i.test(env)) {
  envConfig.API_BASE_URL = 'https://api-stg.ohphire.com/api';
}

if (/^prod(uction)?$/i.test(env)) {
  envConfig.API_BASE_URL = 'https://api.ohphire.com/api';
}

export default envConfig;
