/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { passbaseStatus, passbaseStatusId } from '../../../../constants/PassbaseStatus';
import FormTableComponent from '../../../../components/FormTable/FormTableComponent';

const styles = {
  imageItemContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: 'fit-content',
    height: 'fit-content',
  },
  imageContainer: {
    position: 'relative',
  },
  deleteImageButton: {
    position: 'absolute',
    outline: 'none',
    top: 10,
    right: 10,
  },
  image: {
    width: 120,
    height: 120,
    objectFit: 'cover',
    borderRadius: 5,
    cursor: 'pointer',
    display: 'inline-block',
    verticalAlign: 'middle',
  },
  uploader: {
    display: 'inline-block',
    verticalAlign: 'middle',
    margin: 5,
  },
  modal: {
    minWidth: 'fit-content',
  },
  button: {
    margin: '0px 5px',
  },
  inputBox: {
    border: '1px solid #33333333',
    borderRadius: 5,
    outline: 'none',
    minWidth: 300,
    padding: 5,
  },
};


const IdentificationInfoComponent = ({
  identificationInfo,
  updateIdentificationInfo,
  cancelSelectIdentification,
}) => {
  const renderImageChild = (data = {}) => (
    <div style={styles.imageItemContainer}>
      <div style={styles.imageContainer}>

        <img
          src={R.pathOr(R.pathOr(null, [data.key], identificationInfo), ['modifiedValue', 'uri'], data)}
          style={styles.image}
          alt=""
        />
      </div>
    </div>
  );

  const renderVideoChild = (data = {}) => (
    <div style={styles.imageItemContainer}>
      <div style={styles.imageContainer}>

        <video
          controls="controls"
          src={R.pathOr(R.pathOr(null, [data.key], identificationInfo), ['modifiedValue', 'uri'], data)}
          style={styles.image}
          muted
          autoPlay
        />
      </div>
    </div>
  );

  const renderTableRows = (renderField, renderSeparator) => [
    R.pathOr(false, ['isActive'], identificationInfo) && (
    <tr key="isActive">
      <td />
      <td>已認證</td>
    </tr>
    ),
    renderField({ key: 'name', name: '名稱' }),
    renderField({ key: 'email', name: '電子郵件' }),
    renderSeparator(1),
    renderField({
      key: 'cardFrontUri',
      name: '卡片(前)',
      type: 'custom',
      renderChild: (modifiedValue, isModified, handleChange) => renderImageChild({
        modifiedValue,
        isModified,
        handleChange,
        key: 'cardFrontUri',
      }),
    }),
    renderField({
      key: 'cardBackUri',
      name: '卡片(後)',
      type: 'custom',
      renderChild: (modifiedValue, isModified, handleChange) => renderImageChild({
        modifiedValue,
        isModified,
        handleChange,
        key: 'cardBackUri',
      }),
    }),
    renderField({
      key: 'videoUri',
      name: '短影片',
      type: 'custom',
      renderChild: (modifiedValue, isModified, handleChange) => renderVideoChild({
        modifiedValue,
        isModified,
        handleChange,
        key: 'videoUri',
      }),
    }),
    renderSeparator(2),
    renderField({ key: 'idCardTitle', name: '證件類別' }),
    renderField({ key: 'realName', name: '真實姓名' }),
    renderField({ key: 'personalId', name: '身分證字號' }),
    renderField({ key: 'gender', name: '性別' }),
    renderField({ key: 'birthday', name: '生日' }),
    // renderField({ key: 'ip', name: 'ip' }),
    // renderField({ key: 'score', name: '分數' }),
    renderField({ key: 'isIdentified', name: '是否通過驗證', type: 'checkbox' }),
    renderField({
      key: 'status',
      name: '驗證狀態',
      type: 'select',
      options: [
        { id: null, name: null },
        { id: passbaseStatusId.initial, name: passbaseStatus.initial },
        { id: passbaseStatusId.processing, name: passbaseStatus.processing },
        { id: passbaseStatusId.accept, name: passbaseStatus.accept },
        { id: passbaseStatusId.reject, name: passbaseStatus.reject },
      ],
    }),
    renderField({
      key: 'failMailMessage',
      name: '失敗原因',
      type: 'text',
    }),
  ];
  return (
    <FormTableComponent
      data={identificationInfo}
      updateData={updateIdentificationInfo}
      cancelSelection={cancelSelectIdentification}
      renderTableRows={renderTableRows}
    />
  );
};

IdentificationInfoComponent.propTypes = {
  identificationInfo: PropTypes.shape({}),
  updateIdentificationInfo: PropTypes.func.isRequired,
  cancelSelectIdentification: PropTypes.func.isRequired,
};

IdentificationInfoComponent.defaultProps = {
  identificationInfo: null,
};

export default IdentificationInfoComponent;
