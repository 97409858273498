import React from 'react';
import * as R from 'ramda';
import moment from 'moment';

const styles = {
  fieldTitle: {
    textAlign: 'right',
    padding: 10,
  },
  inputBox: {
    border: '1px solid #33333333',
    borderRadius: 5,
    outline: 'none',
    minWidth: 300,
    padding: 5,
  },
  textArea: {
    border: '1px solid #33333333',
    borderRadius: 5,
    outline: 'none',
    minWidth: 300,
    minHeight: 150,
    padding: 5,
  },
  checkbox: {
    textAlign: 'right',
  },
};

/**
 * A function for rendering the row of FormTableComponent.
 * renderTableFormField({
      key,
      name,
      type,
      options,
      disabled,
      data,
      modifiedData,
      setModifiedData,
      renderChild,
 * })
 *
 * key: the data path
 * name: the display name
 * type: could be one of "date", "select", "custom", or null
 * options: the options for "select" type
 * renderChild: this render function for "custom" type
 * disabled: set to "true" if it's not modifiable
 * data: (internal variable)
 * modifiedData: (internal variable)
 * setModifiedData: (internal variable)
 */
const renderTableFormField = (field) => {
  const {
    key,
    name,
    type,
    options,
    disabled,
    data,
    modifiedData,
    setModifiedData,
    renderChild,
    onValueChanged,
  } = field;
  const oldData = R.pathOr('', [key].flat(), data);
  const modifiedValue = R.pathOr(null, [key], modifiedData);
  const isModified = (modifiedValue !== null);
  const handleChange = (event) => {
    const newData = R.pathOr(event, ['target', 'value'], event);

    if (newData === oldData) {
      const {
        [key]: target,
        ...restOfModifiedData
      } = modifiedData;
      setModifiedData(restOfModifiedData);
    } else {
      setModifiedData({
        ...modifiedData,
        [key]: newData,
      });
    }
  };
  let inputComponent = null;
  switch (type) {
    case 'date':
      inputComponent = (
        <input
          disabled={disabled}
          type={type}
          style={styles.inputBox}
          value={isModified ? modifiedValue : moment(oldData).format('YYYY-MM-DD')}
          onChange={(event) => {
            if (onValueChanged)onValueChanged(event);
            handleChange(event);
          }}
        />
      );
      break;
    case 'textArea':
      inputComponent = (
        <textarea
          disabled={disabled}
          style={styles.textArea}
          value={isModified ? modifiedValue : oldData}
          onChange={(event) => {
            if (onValueChanged)onValueChanged(event);
            handleChange(event);
          }}
        />
      );
      break;
    case 'checkbox':
      inputComponent = (
        <input
          disabled={disabled}
          type={type}
          style={styles.checkbox}
          value={isModified ? modifiedValue : oldData}
          checked={isModified ? modifiedValue : oldData}
          onChange={(event) => {
            if (onValueChanged)onValueChanged(event);
            const value = isModified ? modifiedValue : oldData;
            handleChange(({
              ...event,
              target: {
                value: !value,
              },
            }));
          }}
        />
      );
      break;
    case 'dateTime':
      inputComponent = (
        <input
          disabled={disabled}
          type="datetime-local"
          style={styles.inputBox}
          value={isModified ? modifiedValue : moment(oldData).format('YYYY-MM-DDTHH:mm')}
          onChange={(event) => {
            if (onValueChanged)onValueChanged(event);
            handleChange(event);
          }}
        />
      );
      break;
    case 'select':
      inputComponent = (
        <select
          disabled={disabled}
          style={styles.inputBox}
          value={isModified ? modifiedValue : oldData}
          onChange={(event) => {
            if (onValueChanged)onValueChanged(event);
            handleChange(event);
          }}
        >
          {
            options.map(option => <option value={option.id} key={option.id}>{option.name}</option>)
          }
        </select>
      );
      break;
    case 'custom':
      inputComponent = renderChild(modifiedValue, isModified, handleChange);
      break;
    default:
      inputComponent = (
        <input
          disabled={disabled}
          type={type}
          style={styles.inputBox}
          value={isModified ? modifiedValue : oldData}
          onChange={(event) => {
            if (onValueChanged)onValueChanged(event);
            handleChange(event);
          }}
        />
      );
      break;
  }
  return (
    <tr key={key}>
      <td style={styles.fieldTitle}>
        {name}
        {isModified && '*'}
      </td>
      <td>
        {inputComponent}
      </td>
    </tr>
  );
};

export default renderTableFormField;
