import api from '../../lib/configAxios';

export default {

  getCouponTypeList: async () => api.get(
    'CouponTypes',
  ),

  getCouponList: async filter => api.get(
    `Coupons?filter=${encodeURIComponent(JSON.stringify(filter))}`,
  ),

  createCoupon: async data => api.post(
    'Coupons', data,
  ),

  updateCouponInfo: async data => api.patch(
    `Coupons/${data.id}`, data,
  ),

  deleteCoupon: async data => api.post(
    'Coupons/verifyAndDelete', data,
  ),
};
