import React from 'react';
import PropTypes from 'prop-types';
import FormTableComponent from '../../../../components/FormTable/FormTableComponent';


const SubscribeInfoComponent = ({
  subscribeInfo,
  cancelSelectSubscribe,
  sendNoticeToSubscriber,
}) => {
  const isCreating = true;

  const renderTableRows = renderField => [
    renderField({ key: 'subject', name: '主題' }),
    renderField({
      key: 'content',
      name: '內容',
      type: 'textArea',
    }),
    renderField({
      key: 'sendToMember',
      name: '寄信給訂閱會員',
      type: 'checkbox',
    }),
    renderField({
      key: 'sendToUnMember',
      name: '寄信給訂閱者(無帳號)',
      type: 'checkbox',
    }),
  ];

  return (
    <FormTableComponent
      data={subscribeInfo}
      createData={sendNoticeToSubscriber}
      cancelSelection={cancelSelectSubscribe}
      renderTableRows={renderTableRows}
      isCreating={isCreating}
      updateData={() => {}}
    />
  );
};

SubscribeInfoComponent.propTypes = {
  subscribeInfo: PropTypes.shape({}),
  sendNoticeToSubscriber: PropTypes.func.isRequired,
  cancelSelectSubscribe: PropTypes.func.isRequired,
};

SubscribeInfoComponent.defaultProps = {
  subscribeInfo: null,
};

export default SubscribeInfoComponent;
