import * as R from 'ramda';
import { createSelector } from 'reselect';
import { addressManagementStateSelector } from '../../lib/rootSelector';

export const reduxStatusSelector = createSelector(
  addressManagementStateSelector,
  result => R.path(['reduxStatus'], result),
);

export const reduxErrorSelector = createSelector(
  addressManagementStateSelector,
  result => R.path(['error', 'message'], result),
);

export const addressListSelector = createSelector(
  addressManagementStateSelector,
  result => R.path(['data'], result),
);

export const orderNumberSelector = createSelector(
  addressManagementStateSelector,
  result => R.path(['orderNumber'], result),
);

export const orderStatusListSelector = createSelector(
  addressManagementStateSelector,
  result => R.path(['orderStatuses'], result),
);

export const donateOrganizationListSelector = createSelector(
  addressManagementStateSelector,
  result => R.path(['donateOrganizations'], result),
);

export const receiptTypeListSelector = createSelector(
  addressManagementStateSelector,
  result => R.path(['receiptTypes'], result),
);
