import api from '../../lib/configAxios';

export default {
  getNegotiationList: async filter => api.get(
    `Negotiations/?filter=${encodeURIComponent(JSON.stringify(filter))}`,
  ),

  submitNegotiation: async data => api.post(
    'Negotiations/submitNegotiation', data,
  ),

  dealNegotiation: async data => api.post(
    'Negotiations/deal', data,
  ),
};
