import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import ReduxStatusVisualizedComponent from '../../../../components/ReduxStatusVisualizedComponent';
import MarqueeInfoComponent from './MarqueeInfoComponent';

const styles = {
  mainContainer: {
    margin: 20,
  },
};

const columns = [
  {
    title: '跑馬燈文字',
    dataIndex: 'text',
  },
];

const MarqueeManagementPage = ({
  reduxStatus,
  reduxError,
  data,
  updateMarqueeInfo,
}) => {
  const [selectedMarquee, setSelectedMarquee] = useState(null);

  useEffect(() => {
    setSelectedMarquee(null);
  }, [data]);

  const onRow = record => ({
    onClick: () => {
      setSelectedMarquee(record);
    },
  });


  return (
    <div style={styles.mainContainer} key="baseContainer">

      <ReduxStatusVisualizedComponent
        reduxStatus={reduxStatus}
        showModalOnError
        modalFailureContent={reduxError}
      >
        <Table
          rowKey={() => {}}
          columns={columns}
          childrenColumnName="Marqueee"
          expandRowByClick
          dataSource={data}
          onRow={onRow}
          pagination={false}
        />

        <MarqueeInfoComponent
          data={selectedMarquee}
          updateMarqueeInfo={updateMarqueeInfo}
          cancelSelectMarquee={() => setSelectedMarquee(null)}
        />
      </ReduxStatusVisualizedComponent>
    </div>
  );
};

MarqueeManagementPage.propTypes = {
  reduxStatus: PropTypes.string.isRequired,
  reduxError: PropTypes.string,
  data: PropTypes.shape([]),
  updateMarqueeInfo: PropTypes.func.isRequired,
};

MarqueeManagementPage.defaultProps = {
  data: [],
  reduxError: null,
};

export default MarqueeManagementPage;
