import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { Select } from 'antd';
import FormTableComponent from '../../../../components/FormTable/FormTableComponent';
import {
  couponType,
  couponTypeId,
} from '../../../../constants/CouponType';
import InputDialog from '../../../../components/InputDialog';

const { Option } = Select;

const CouponInfoComponent = ({
  couponInfo,
  updateCouponInfo,
  deleteCouponInfo,
  createCouponInfo,
  cancelSelectCouponInfo,
  memberList,
}) => {
  const [showDeleteConfirmDialog, setShowDeleteConfirmDialog] = useState(false);
  const isCreating = Object.keys(couponInfo || {}).length === 0;

  const getCouponTypeOptions = () => {
    const reorderCouponType = Object.keys(couponTypeId)
      .map(level => ({
        id: couponTypeId[level],
        name: couponType[level],
      }));

    reorderCouponType.unshift({ id: null, name: '' });
    return reorderCouponType;
  };

  const renderCouponOwner = (data = {}) => {
    const { handleChange } = data;
    const children = [];
    memberList.forEach((member) => {
      children.push(
        <Option key={member.id}>
          {`社群名字:${R.path(['communityName'], member)} ${R.path(['email'], member)}`}
        </Option>,
      );
    });


    const assignedUserIds = R.pathOr([], ['owners'], couponInfo).map(owner => String(owner.id));

    return (
      <Select
        mode="multiple"
        key={R.pathOr('', ['id'], couponInfo)}
        size="default"
        placeholder="Please select"
        defaultValue={assignedUserIds}
        onChange={(value) => {
          const selectMemberList = R.filter(member => R.includes(String(member.id), value), memberList);
          handleChange(selectMemberList);
        }}
        style={{ width: '100%' }}
        filterOption={(input, option) => option.props.children.indexOf(input) >= 0}
        optionFilterProp="children"
        showSearch
      >
        {children}
      </Select>
    );
  };


  const renderTableRows = renderField => [
    renderField({
      key: 'couponTypeId',
      name: '折扣碼類型',
      type: 'select',
      options: getCouponTypeOptions(),
    }),
    renderField({ key: 'couponCode', name: '折扣碼' }),
    renderField({ key: 'couponName', name: '折扣碼名稱' }),
    renderField({ key: 'endDate', name: '結束時間', type: 'dateTime' }),
    renderField({ key: 'percent', name: '打折' }),
    renderField({ key: 'minus', name: '折抵' }),
    renderField({ key: 'isActive', name: '啟用', type: 'checkbox' }),
    renderField({ key: 'isDefault', name: '預設優惠券', type: 'checkbox' }),
    renderField({ key: 'isGlobal', name: '是否所有人皆可領取', type: 'checkbox' }),
    renderField({ key: 'isReusable', name: '可重複使用', type: 'checkbox' }),
    renderField({ key: 'isHidden', name: '是否不可見', type: 'checkbox' }),
    renderField({
      type: 'custom',
      name: '指定此優惠券領取對象',
      key: 'owners',
      renderChild: (modifiedValue, isModified, handleChange) => renderCouponOwner({
        modifiedValue,
        isModified,
        handleChange,
        key: 'owners',
      }),
    }),
  ];

  return [
    <FormTableComponent
      key="FormTableComponent"
      data={couponInfo}
      updateData={updateCouponInfo}
      cancelSelection={cancelSelectCouponInfo}
      renderTableRows={renderTableRows}
      deleteData={() => setShowDeleteConfirmDialog(true)}
      createData={createCouponInfo}
      isCreating={isCreating}
    />,
    <InputDialog
      key="deleteConfirmDialog"
      title="如果真的要刪除，請輸入您的密碼"
      inputProps={{ type: 'password' }}
      isVisible={showDeleteConfirmDialog}
      onSubmit={(value) => {
        setShowDeleteConfirmDialog(false);
        if (value) {
          deleteCouponInfo({
            id: R.path(['id'], couponInfo),
            password: value,
          });
        }
      }}
    />,
  ];
};

CouponInfoComponent.propTypes = {
  couponInfo: PropTypes.shape({}),
  updateCouponInfo: PropTypes.func.isRequired,
  deleteCouponInfo: PropTypes.func.isRequired,
  cancelSelectCouponInfo: PropTypes.func.isRequired,
  createCouponInfo: PropTypes.func.isRequired,
};

CouponInfoComponent.defaultProps = {
  couponInfo: null,
};

export default CouponInfoComponent;
