import { createActions } from 'redux-actions';
import { createAsyncActionTypesArray } from '../../utils/createAsyncActionTypes';

export default createActions(
  // ----- TASKS -----
  ...createAsyncActionTypesArray('GET_SUBSCRIBE_LIST'),
  ...createAsyncActionTypesArray('GET_MEMBER_SUBSCRIBE_LIST'),
  ...createAsyncActionTypesArray('UPDATE_SUBSCRIBE_INFO'),
  ...createAsyncActionTypesArray('DELETE_SUBSCRIBE'),
  ...createAsyncActionTypesArray('SEND_NOTICE_TO_SUBSCRIBER'),
  ...createAsyncActionTypesArray('SEARCH'),

  // ----- FLOWS -----
  ...createAsyncActionTypesArray('GET_SUBSCRIBE_LIST_FLOW'),
  ...createAsyncActionTypesArray('GET_MEMBER_SUBSCRIBE_LIST_FLOW'),
  ...createAsyncActionTypesArray('UPDATE_SUBSCRIBE_INFO_FLOW'),
  ...createAsyncActionTypesArray('DELETE_SUBSCRIBE_FLOW'),
  ...createAsyncActionTypesArray('SEND_NOTICE_TO_SUBSCRIBER_FLOW'),
  ...createAsyncActionTypesArray('SEARCH_FLOW'),

  'RESET_STATUS',

  { prefix: 'SUBSCRIBE_MANAGEMENT' },
);
