import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import ReduxStatusVisualizedComponent from '../../../../components/ReduxStatusVisualizedComponent';
import AddressInfoComponent from './AddressInfoComponent';


const styles = {
  mainContainer: {
    margin: 20,
  },
  searchBarStyle: {
    margin: '30px 0',
    width: 300,
  },
  loadMoreButton: {
    margin: '20px 0',
    width: '100%',
  },
};

const columns = [
  {
    title: 'id',
    dataIndex: 'id',
    width: 150,
  },
  {
    title: '類型',
    dataIndex: ['id'],
    render: id => (id === 1 ? 'OP' : '廠商'),
    width: 150,
  },
  {
    title: '名字',
    dataIndex: 'name',
  },
];

const OrderManagementPage = ({

  reduxStatus,
  reduxError,
  data,
  orderStatusList,
  donateOrganizationList,
  receiptTypeList,
  updateAddressInfo,
  createOrder,
  deleteOrder,

}) => {
  const [selectedOrder, setSelectedOrder] = useState(null);

  useEffect(() => {
    setSelectedOrder(null);
  }, [data]);

  const onRow = record => ({
    onClick: () => {
      setSelectedOrder(record);
    },
  });
  const getRowKey = ({ id, name }) => (`${id}.${name}`);
  return (
    <div style={styles.mainContainer} key="baseContainer">

      <ReduxStatusVisualizedComponent
        reduxStatus={reduxStatus}
        showModalOnError
        modalFailureContent={reduxError}
      >

        <Table
          columns={columns}
          dataSource={data}
          rowKey={getRowKey}
          onRow={onRow}
          expandRowByClick
          pagination={false}
        />

      </ReduxStatusVisualizedComponent>

      <AddressInfoComponent
        receiptTypeList={receiptTypeList}
        donateOrganizationList={donateOrganizationList}
        orderStatusList={orderStatusList}
        orderInfo={selectedOrder}
        updateAddressInfo={updateAddressInfo}
        createOrder={createOrder}
        deleteOrder={deleteOrder}
        cancelSelectOrder={() => setSelectedOrder(null)}
      />
    </div>
  );
};

OrderManagementPage.propTypes = {
  reduxStatus: PropTypes.string.isRequired,
  reduxError: PropTypes.string,
  data: PropTypes.array,
  orderStatusList: PropTypes.array,
  updateAddressInfo: PropTypes.func.isRequired,
  createOrder: PropTypes.func.isRequired,

  deleteOrder: PropTypes.func.isRequired,
  donateOrganizationList: PropTypes.array,
  receiptTypeList: PropTypes.array,

};

OrderManagementPage.defaultProps = {
  reduxError: null,
  data: [],
  orderStatusList: [],
  donateOrganizationList: [],
  receiptTypeList: [],

};

export default OrderManagementPage;
