export const authStateSelector = state => (state.auth);
export const dashboardStateSelector = state => (state.dashboard);
export const memberManagementStateSelector = state => (state.memberManagement);
export const tagManagementStateSelector = state => (state.tagManagement);
export const bagManagementStateSelector = state => (state.bagManagement);
export const orderManagementStateSelector = state => (state.orderManagement);
export const contactManagementStateSelector = state => (state.contactManagement);
export const subscribeManagementStateSelector = state => (state.subscribeManagement);
export const cartManagementStateSelector = state => (state.cartManagement);
export const themeManagementStateSelector = state => (state.themeManagement);
export const commentManagementStateSelector = state => (state.commentManagement);
export const negotiationStateSelector = state => (state.negotiation);
export const reduceDepositStateSelector = state => (state.reduceDepositManagement);
export const withdrawStateSelector = state => (state.withdrawManagement);
export const clothesManagementStateSelector = state => (state.clothesManagement);
export const payByTokenStateSelector = state => (state.payByTokenManagement);
export const couponManagementStateSelector = state => (state.couponManagement);
export const addressManagementStateSelector = state => (state.addressManagement);
export const MarqueeManagementStateSelector = state => (state.MarqueeManagement);
export const IdentificationManagementStateSelector = state => (state.IdentificationManagement);
