import { createActions } from 'redux-actions';
import { createAsyncActionTypesArray } from '../../utils/createAsyncActionTypes';

export default createActions(
  // ----- TASKS -----
  ...createAsyncActionTypesArray('GET_CONTACT_LIST'),
  ...createAsyncActionTypesArray('UPDATE_CONTACT_INFO'),
  ...createAsyncActionTypesArray('DELETE_CONTACT'),
  ...createAsyncActionTypesArray('SEARCH'),
  ...createAsyncActionTypesArray('GET_UN_REPLY_NUMBERS'),

  // ----- FLOWS -----
  ...createAsyncActionTypesArray('GET_CONTACT_LIST_FLOW'),
  ...createAsyncActionTypesArray('UPDATE_CONTACT_INFO_FLOW'),
  ...createAsyncActionTypesArray('DELETE_CONTACT_FLOW'),
  ...createAsyncActionTypesArray('SEARCH_FLOW'),
  ...createAsyncActionTypesArray('GET_UN_REPLY_NUMBERS_FLOW'),

  'RESET_STATUS',

  { prefix: 'CONTACT_MANAGEMENT' },
);
