import { handleActions, combineActions } from 'redux-actions';
import update from 'immutability-helper';
import ActionCreator from './ActionCreator';
import ReduxStatus from '../../constants/ReduxStatus';

const {
  resetStatus,
  getMemberListRequest,
  getMemberListSuccess,
  getMemberListFailure,
  getMemberNumbersRequest,
  getMemberNumbersSuccess,
  getMemberNumbersFailure,
  searchByKeywordRequest,
  searchByKeywordSuccess,
  searchByKeywordFailure,
  updateMemberInfoRequest,
  updateMemberInfoSuccess,
  updateMemberInfoFailure,
} = ActionCreator;

const initialState = {
  reduxStatus: ReduxStatus.STATUS_INITIAL,
  error: null,
  data: [],
};

export default handleActions({
  [combineActions(
    getMemberListRequest,
    getMemberNumbersRequest,
    searchByKeywordRequest,
    updateMemberInfoRequest,
  )]: state => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_LOADING },
    error: { $set: initialState.error },
  }),
  [combineActions(
    getMemberListSuccess,
    searchByKeywordSuccess,
  )]: (state, { payload }) => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_NORMAL },
    data: { $set: payload },
  }),
  [combineActions(
    getMemberListFailure,
    getMemberNumbersFailure,
    searchByKeywordFailure,
    updateMemberInfoFailure,
  )]: (state, { payload }) => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_ERROR },
    error: { $set: payload },
  }),
  [getMemberNumbersSuccess]: (state, { payload }) => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_NORMAL },
    memberNumbers: { $set: payload },
  }),
  [updateMemberInfoSuccess]: (state, { payload }) => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_NORMAL },
    data: {
      $set: (state.data || []).map((member) => {
        if (member.id === payload.id) return payload;
        return member;
      }),
    },
  }),
  [resetStatus]: state => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_INITIAL },
  }),
}, initialState);
