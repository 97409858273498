import { handleActions, combineActions } from 'redux-actions';
import update from 'immutability-helper';
import * as R from 'ramda';
import ActionCreator from './ActionCreator';
import ReduxStatus from '../../constants/ReduxStatus';

const {
  resetStatus,
  getBagListFlowRequest,
  getBagListFlowSuccess,
  getBagListFlowFailure,
  getBagNumbersFlowRequest,
  getBagNumbersFlowSuccess,
  getBagNumbersFlowFailure,
  getModifiedBuyAmountRequestNumberFlowRequest,
  getModifiedBuyAmountRequestNumberFlowSuccess,
  getModifiedBuyAmountRequestNumberFlowFailure,
  updateBagInfoRequest,
  updateBagInfoSuccess,
  updateBagInfoFailure,
  deleteBagRequest,
  deleteBagSuccess,
  deleteBagFailure,
  createBagRequest,
  createBagSuccess,
  createBagFailure,

  judgeModifyBuyAmountSuccess,
  judgeModifyBuyAmountFailure,
  judgeModifyBuyAmountRequest,
} = ActionCreator;

const initialState = {
  reduxStatus: ReduxStatus.STATUS_INITIAL,
  error: null,
  data: [],
  filterCondition: null,
};

export default handleActions({
  [combineActions(
    getBagListFlowRequest,
    getBagNumbersFlowRequest,
    updateBagInfoRequest,
    deleteBagRequest,
    createBagRequest,
    judgeModifyBuyAmountRequest,
    getModifiedBuyAmountRequestNumberFlowRequest,
  )]: state => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_LOADING },
    error: { $set: initialState.error },
  }),

  [combineActions(
    getBagListFlowSuccess,
  )]: (state, { payload }) => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_NORMAL },
    data: R.pathOr(0, ['filterCondition', 'page'], payload) === 0
      ? { $set: payload.data }
      : { $push: payload.data },
    filterCondition: { $set: payload.filterCondition },
  }),
  [combineActions(
    getBagListFlowFailure,
    getBagNumbersFlowFailure,
    updateBagInfoFailure,
    deleteBagFailure,
    createBagFailure,
    judgeModifyBuyAmountFailure,
    getModifiedBuyAmountRequestNumberFlowFailure,
  )]: (state, { payload }) => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_ERROR },
    error: { $set: payload },
  }),
  [updateBagInfoSuccess]: (state, { payload }) => {
    const {
      id,
    } = payload;
    return update(state, {
      reduxStatus: { $set: ReduxStatus.STATUS_NORMAL },
      data: {
        $set: R.pathOr([], ['data'], state).map((bag) => {
          if (bag.id === id) return payload;
          return bag;
        }),
      },
    });
  },
  [getBagNumbersFlowSuccess]: (state, { payload }) => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_NORMAL },
    bagNumbers: { $set: payload },
  }),
  [getModifiedBuyAmountRequestNumberFlowSuccess]: (state, { payload }) => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_NORMAL },
    modifiedBagNumbers: { $set: payload },
  }),
  [deleteBagSuccess]: (state, { payload }) => {
    const {
      id,
    } = payload;
    return update(state, {
      reduxStatus: { $set: ReduxStatus.STATUS_NORMAL },
      data: {
        $set: R.pathOr([], ['data'], state).map((bag) => {
          if (bag.id === id) return null;
          return bag;
        }).filter(d => d),
      },
    });
  },
  [createBagSuccess]: (state, { payload }) => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_NORMAL },
    data: {
      $set: [...R.pathOr([], ['data'], state), payload],
    },
  }),
  [judgeModifyBuyAmountSuccess]: (state, { payload }) => {
    const {
      id,
    } = payload;

    return update(state, {
      reduxStatus: { $set: ReduxStatus.STATUS_NORMAL },
      data: {
        $set: R.pathOr([], ['data'], state).map((bag) => {
          if (bag.id === id) return payload;
          return bag;
        }),
      },
    });
  },
  [resetStatus]: state => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_INITIAL },
  }),
}, initialState);
