import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Table, Button, Icon } from 'antd';
import ReduxStatusVisualizedComponent from '../../../../components/ReduxStatusVisualizedComponent';
import ThemeInfoComponent from './ThemeInfoComponent';

const styles = {
  mainContainer: {
    margin: 20,
  },
};

const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    width: 150,
  },
  {
    title: '啟用',
    dataIndex: 'isActive',
    width: 100,
    render: value => (value ? <Icon type="check" /> : ''),
  },
  {
    title: '順序',
    dataIndex: 'order',
    width: 150,
  },
  {
    title: '名稱',
    dataIndex: 'name',
  },
];

const ThemeManagementPage = ({
  reduxStatus,
  reduxError,
  data,
  updateTheme,
  deleteTheme,
  createTheme,
}) => {
  const [selectedTheme, setSelectedTheme] = useState(null);

  useEffect(() => {
    setSelectedTheme(null);
  }, [data]);

  const onRow = record => ({
    onClick: () => {
      setSelectedTheme(record);
    },
  });

  const getRowKey = ({ id, name }) => (`${id}.${name}`);

  return (
    <div style={styles.mainContainer} key="baseContainer">

      <ReduxStatusVisualizedComponent
        reduxStatus={reduxStatus}
        showModalOnError
        modalFailureContent={reduxError}
      >

        <Button onClick={() => { setSelectedTheme({}); }}>
          新增主題
        </Button>

        <Table
          rowKey={getRowKey}
          columns={columns}
          dataSource={data}
          onRow={onRow}
          pagination={false}
        />

        <ThemeInfoComponent
          themeInfo={selectedTheme}
          updateTheme={updateTheme}
          deleteTheme={deleteTheme}
          createTheme={createTheme}
          cancelSelectTheme={() => setSelectedTheme(null)}
        />

      </ReduxStatusVisualizedComponent>

    </div>
  );
};

ThemeManagementPage.propTypes = {
  reduxStatus: PropTypes.string.isRequired,
  reduxError: PropTypes.string,
  data: PropTypes.array,
  updateTheme: PropTypes.func.isRequired,
  deleteTheme: PropTypes.func.isRequired,
  createTheme: PropTypes.func.isRequired,
};

ThemeManagementPage.defaultProps = {
  data: [],
  reduxError: null,
};

export default ThemeManagementPage;
