import { handleActions, combineActions } from 'redux-actions';
import update from 'immutability-helper';
import * as R from 'ramda';
import ActionCreator from './ActionCreator';
import ReduxStatus from '../../constants/ReduxStatus';

const {

  getWithdrawListFlowRequest,
  getWithdrawListFlowSuccess,
  getWithdrawListFailure,

  searchFlowRequest,
  searchFlowSuccess,
  searchFlowFailure,

  updateWithdrawInfoRequest,
  updateWithdrawInfoSuccess,
  updateWithdrawInfoFailure,

  deleteWithdrawFlowRequest,
  deleteWithdrawSuccess,
  deleteWithdrawFailure,

  getUnRemitNumbersFlowRequest,
  getUnRemitNumbersFlowSuccess,
  getUnRemitNumbersFlowFailure,
} = ActionCreator;

const initialState = {
  reduxStatus: ReduxStatus.STATUS_INITIAL,
  error: null,
  data: [],
  orderStatuses: [],
  donateOrganizations: [],
  receiptTypes: [],
};

export default handleActions({
  // request
  [combineActions(
    searchFlowRequest,
    updateWithdrawInfoRequest,
    deleteWithdrawFlowRequest,
    getWithdrawListFlowRequest,
    getUnRemitNumbersFlowRequest,
  )]: state => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_LOADING },
    error: { $set: initialState.error },
  }),

  // success
  [combineActions(
    getWithdrawListFlowSuccess,
    searchFlowSuccess,
  )]: (state, { payload }) => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_NORMAL },
    data: R.pathOr(0, ['condition', 'page'], payload) === 0
      ? { $set: payload.data }
      : { $push: payload.data },
  }),
  [updateWithdrawInfoSuccess]: (state, { payload }) => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_NORMAL },
    data: {
      $set: (state.data || []).map((order) => {
        if (order.id === payload.id) return payload;
        return order;
      }),
    },
  }),
  [deleteWithdrawSuccess]: (state, { payload }) => {
    const {
      id,
    } = payload;
    return update(state, {
      reduxStatus: { $set: ReduxStatus.STATUS_NORMAL },
      data: {
        $set: R.pathOr([], ['data'], state).map((bag) => {
          if (bag.id === id) return null;
          return bag;
        }).filter(d => d),
      },
    });
  },
  [getUnRemitNumbersFlowSuccess]: (state, { payload }) => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_NORMAL },
    unRemitNumber: { $set: payload },
  }),

  // failure
  [combineActions(
    searchFlowFailure,
    updateWithdrawInfoFailure,
    deleteWithdrawFailure,
    getWithdrawListFailure,
    getUnRemitNumbersFlowFailure,
  )]: (state, { payload }) => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_ERROR },
    error: { $set: payload },
  }),
}, initialState);
