import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import FormTableComponent from '../../../../components/FormTable/FormTableComponent';
import InputDialog from '../../../../components/InputDialog';

const OrderInfoComponent = ({
  orderInfo,
  updateAddressInfo,
  cancelSelectOrder,

  createOrder,
  deleteOrder,
}) => {
  const isCreating = Object.keys(orderInfo || {}).length === 0;


  const [showDeleteConfirmDialog, setShowDeleteConfirmDialog] = useState(false);


  const renderTableRows = renderField => [
    renderField({ key: 'name', name: '收件者' }),
    renderField({ key: 'storeReceiver', name: '商店收件者' }),
    renderField({ key: 'phone', name: '收店電話' }),
    renderField({ key: 'storeCellPhone', name: '店到店電話' }),
    renderField({ key: 'recipientAddress', name: '收件地址' }),
    renderField({ key: 'storeAddress', name: '店到店門市或地址' }),
  ];
  return [
    <FormTableComponent
      key="FormTableComponent"
      data={orderInfo}
      updateData={updateAddressInfo}
      cancelSelection={cancelSelectOrder}
      renderTableRows={renderTableRows}
      createData={createOrder}
      isCreating={isCreating}
      deleteData={() => setShowDeleteConfirmDialog(true)}
    />,
    <InputDialog
      key="deleteConfirmDialog"
      title="如果真的要刪除，請輸入您的密碼"
      inputProps={{ type: 'password' }}
      isVisible={showDeleteConfirmDialog}
      onSubmit={(value) => {
        setShowDeleteConfirmDialog(false);
        if (value) {
          deleteOrder({
            id: R.path(['id'], orderInfo),
            password: value,
          });
        }
      }}
    />,
  ];
};

OrderInfoComponent.propTypes = {
  orderInfo: PropTypes.shape({}),
  updateAddressInfo: PropTypes.func.isRequired,
  cancelSelectOrder: PropTypes.func.isRequired,
  createOrder: PropTypes.func.isRequired,
  deleteOrder: PropTypes.func.isRequired,
  orderStatusList: PropTypes.array,
  donateOrganizationList: PropTypes.array,
  receiptTypeList: PropTypes.array,
};

OrderInfoComponent.defaultProps = {
  orderInfo: null,
  orderStatusList: [],
  donateOrganizationList: [],
  receiptTypeList: [],
};

export default OrderInfoComponent;
