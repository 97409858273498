import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Input,
  Icon,
  Button,
} from 'antd';
import ReduxStatusVisualizedComponent from '../../../../components/ReduxStatusVisualizedComponent';
import Logo from '../../../../components/Logo';

const styles = {
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    position: 'absolute',
  },
  logoStyle: {
    minWidth: 300,
    height: 60,
    color: 'black',
    margin: '20px 0',
    fontSize: '50px',
  },
  titleStyle: {
    color: 'black',
    fontSize: 14,
    margin: 12,
  },
  emailStyle: {
    color: 'black',
  },
  passwordStyle: {
    color: 'black',
    marginTop: 24,
  },
  buttonStyle: {
    marginTop: 24,
    width: '100%',
    fontSize: 14,
  },
  hintStyle: {
    fontSize: 12,
  },
};

const LoginScreen = ({
  beginLogin,
  reduxStatus,
}) => {
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };
  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };
  const handleLoginButtonClicked = (event) => {
    event.preventDefault();
    beginLogin(email, password);
  };

  const modalFailureTitle = '登入失敗';
  const modalFailureContent = '請確認您輸入的資料是否正確';

  return (
    <ReduxStatusVisualizedComponent
      reduxStatus={reduxStatus}
      containerStyle={styles.mainContainer}
      showModalOnError
      modalFailureTitle={modalFailureTitle}
      modalFailureContent={modalFailureContent}
    >
      <form onSubmit={handleLoginButtonClicked}>
        <Logo style={styles.logoStyle} />
        <div style={styles.titleStyle}>
          登入Email
        </div>
        <div style={styles.emailStyle}>
          <Input
            autoFocus
            placeholder="Email ..."
            prefix={(
              <Icon
                type="user"
                style={{ color: 'rgba(0,0,0,.25)' }}
              />
          )}
            allowClear
            onChange={handleEmailChange}
            value={email}
          />
        </div>
        <div style={styles.passwordStyle}>
          <Input.Password
            placeholder="密碼 ..."
            allowClear
            prefix={(
              <Icon
                type="lock"
                style={{ color: 'rgba(0,0,0,.25)' }}
              />
          )}
            onChange={handlePasswordChange}
            value={password}
          />
        </div>
        <Button
          type="primary"
          style={styles.buttonStyle}
          size="large"
          onClick={handleLoginButtonClicked}
        >
          登入
        </Button>
        <input type="submit" hidden />
      </form>
    </ReduxStatusVisualizedComponent>

  );
};

LoginScreen.propTypes = {
  beginLogin: PropTypes.func,
  reduxStatus: PropTypes.string,
};

LoginScreen.defaultProps = {
  beginLogin: () => {},
  reduxStatus: null,
};

export default LoginScreen;
