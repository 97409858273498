import api from '../../lib/configAxios';

export default {
  updateWithdrawInfo: async data => api.patch(
    `Withdraws/${data.id}`, data,
  ),
  deleteWithdraw: async data => api.post(
    'Withdraws/verifyAndDelete', data,
  ),
  getWithdrawList: async filter => api.get(
    `Withdraws?filter=${encodeURIComponent(JSON.stringify(filter))}`,
  ),
  getRemitNumbers: async filter => api.get(
    `Withdraws/count?where=${encodeURIComponent(JSON.stringify(filter))}`,
  ),
};
