import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ActionCreators from '../../ActionCreator';
import IdentificationManagementPage from './IdentificationManagementPage';
import {
  reduxStatusSelector,
  reduxErrorSelector,
  identificationListSelector,
  identificationNumbersSelector,
} from '../../selector';

const IdentificationManagementPageContainer = ({
  reduxStatus,
  reduxError,
  actions: {
    getIdentificationListFlowRequest,
    getIdentificationNumbersFlowRequest,
    searchByKeywordFlowRequest,
    updateIdentificationInfoFlowRequest,
    resetStatus,
  },
  data,
  identificationNumbers,
  onIdentificationSelected,
}) => {
  useEffect(() => {
    if (!data || data.length === 0) {
      getIdentificationListFlowRequest();
      getIdentificationNumbersFlowRequest({
        isIdentified: false,
      });
    } else resetStatus();
    return resetStatus;
  }, [data]);

  return (
    <IdentificationManagementPage
      reduxStatus={reduxStatus}
      reduxError={reduxError}
      data={data}
      identificationNumbers={identificationNumbers}
      searchByKeyword={searchByKeywordFlowRequest}
      updateIdentificationInfo={updateIdentificationInfoFlowRequest}
      onIdentificationSelected={onIdentificationSelected}
    />
  );
};

IdentificationManagementPageContainer.propTypes = {
  reduxStatus: PropTypes.string,
  reduxError: PropTypes.string,
  actions: PropTypes.shape({
    getIdentificationListFlowRequest: PropTypes.func.isRequired,
    searchByKeywordFlowRequest: PropTypes.func.isRequired,
    updateIdentificationInfoFlowRequest: PropTypes.func.isRequired,
    getIdentificationNumbersFlowRequest: PropTypes.func.isRequired,
    resetStatus: PropTypes.func.isRequired,
  }).isRequired,
  data: PropTypes.array,
  onIdentificationSelected: PropTypes.func,
  identificationNumbers: PropTypes.number,
};

IdentificationManagementPageContainer.defaultProps = {
  reduxStatus: null,
  reduxError: null,
  data: [],
  onIdentificationSelected: null,
  identificationNumbers: null,
};

const mapStateToProps = state => ({
  reduxStatus: reduxStatusSelector(state),
  reduxError: reduxErrorSelector(state),
  data: identificationListSelector(state),
  identificationNumbers: identificationNumbersSelector(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    ...ActionCreators,
  }, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(IdentificationManagementPageContainer);
