import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Table, Button, Input } from 'antd';
import ReduxStatusVisualizedComponent from '../../../../components/ReduxStatusVisualizedComponent';
import SubscribeInfoComponent from './SubscribeInfoComponent';
import SendNoticeToSubscriberComponent from './SendNoticeToSubscriberComponent';

const { Search } = Input;

const styles = {
  mainContainer: {
    margin: 20,
  },
  searchBarStyle: {
    margin: '30px 0',
    width: 300,
  },
  loadMoreButton: {
    margin: '20px 0',
    width: '100%',
  },
  text: {
    margin: '20px 0',
  },
  switchMemberButtonContainer: {
    margin: '10px 0px',
  },
  button: {
    margin: '0px 5px',
  },
  buttonActive: {
    margin: '0px 5px',
    border: '2px #66B3FF solid',
    color: '#66B3FF',
  },
};

const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    width: 150,
  },
  {
    title: 'E-mail',
    dataIndex: 'email',
  },
];

const SubscribeManagementPage = ({
  reduxStatus,
  reduxError,
  data,
  updateSubscribeInfo,
  deleteSubscribe,
  search,
  handleLoadMore,
  sendNoticeToSubscriber,
  onClickUnMemberSubscribe,
  onClickMemberSubscribe,
  selectType,
}) => {
  const [selectedSubscribe, setSelectedSubscribe] = useState(null);
  const [selectedSubscribeSend, setSelectedSubscribeSend] = useState(null);

  useEffect(() => {
    setSelectedSubscribe(null);
    setSelectedSubscribeSend(null);
  }, [data]);

  const onRow = record => ({
    onClick: () => {
      setSelectedSubscribe(record);
    },
  });

  const getRowKey = ({ id, email }) => (`${id}.${email}`);

  return (
    <div style={styles.mainContainer} key="baseContainer">
      <Search
        placeholder="搜尋"
        onSearch={value => search(value)}
        enterButton
        size="large"
        style={styles.searchBarStyle}
      />

      <ReduxStatusVisualizedComponent
        reduxStatus={reduxStatus}
        showModalOnError
        modalFailureContent={reduxError}
      >
        <div style={styles.switchMemberButtonContainer}>
          <Button
            onClick={() => { setSelectedSubscribeSend({}); }}
            style={styles.button}
          >
            傳送通知
          </Button>
          <Button
            onClick={onClickUnMemberSubscribe}
            style={selectType === 'unMember' ? styles.buttonActive : styles.button}
          >
            非會員訂閱者
          </Button>
          <Button
            onClick={onClickMemberSubscribe}
            style={selectType === 'member' ? styles.buttonActive : styles.button}
          >
            會員訂閱者
          </Button>
        </div>

        <Table
          rowKey={getRowKey}
          columns={columns}
          childrenColumnName="Subscribes"
          dataSource={data}
          expandRowByClick
          onRow={onRow}
          pagination={false}
        />

        <SubscribeInfoComponent
          subscribeInfo={selectedSubscribe}
          updateSubscribeInfo={updateSubscribeInfo}
          deleteSubscribe={deleteSubscribe}
          cancelSelectSubscribe={() => setSelectedSubscribe(null)}
        />

        <SendNoticeToSubscriberComponent
          subscribeInfo={selectedSubscribeSend}
          sendNoticeToSubscriber={sendNoticeToSubscriber}
          cancelSelectSubscribe={() => setSelectedSubscribeSend(null)}
        />
        <Button
          style={styles.loadMoreButton}
          onClick={handleLoadMore}
          type="primary"
        >
          載入更多聯絡資料
        </Button>
      </ReduxStatusVisualizedComponent>

    </div>
  );
};

SubscribeManagementPage.propTypes = {
  reduxStatus: PropTypes.string.isRequired,
  reduxError: PropTypes.string,
  data: PropTypes.array,
  updateSubscribeInfo: PropTypes.func.isRequired,
  deleteSubscribe: PropTypes.func.isRequired,
  search: PropTypes.func.isRequired,
  handleLoadMore: PropTypes.func.isRequired,
  sendNoticeToSubscriber: PropTypes.func.isRequired,
  onClickUnMemberSubscribe: PropTypes.func.isRequired,
  onClickMemberSubscribe: PropTypes.func.isRequired,
  selectType: PropTypes.string.isRequired,
};

SubscribeManagementPage.defaultProps = {
  data: [],
  reduxError: null,
};

export default SubscribeManagementPage;
