import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Table, Input } from 'antd';
import MemberInfoComponent from './MemberInfoComponent';
import CartInfoComponent from './CartInfoComponent';
import ReduxStatusVisualizedComponent from '../../../../components/ReduxStatusVisualizedComponent';

const { Search } = Input;

const styles = {
  mainContainer: {
    margin: 20,
  },
  searchBarStyle: {
    margin: '30px 0',
    width: 300,
  },
};

const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
  },
  {
    title: '姓名',
    dataIndex: 'name',
  },
  {
    title: '社群姓名',
    dataIndex: 'communityName',
  },
  {
    title: 'E-mail',
    dataIndex: 'email',
  },
  {
    title: '時尚達人',
    dataIndex: 'isMaster',
    render: isMaster => (isMaster ? '是' : '否'),
  },
];

const MemberManagementPage = ({
  reduxStatus,
  reduxError,
  data,
  memberNumbers,
  searchByKeyword,
  updateMemberInfo,
  onMemberSelected,
  fetchCartInfo,
  cartList,
  type,
}) => {
  const [selectedMember, setSelectedMember] = useState(null);

  useEffect(() => {
    setSelectedMember(null);
  }, [data]);

  const onRow = record => ({
    onClick: () => {
      if (onMemberSelected) onMemberSelected(record);
      else setSelectedMember(record);

      if (type === 'cartInfo') {
        fetchCartInfo(record.id);
      }
    },
  });

  return (
    <div style={styles.mainContainer} key="baseContainer">

      <Search
        placeholder="搜尋"
        onSearch={value => searchByKeyword(value)}
        enterButton
        size="large"
        style={styles.searchBarStyle}
      />

      <ReduxStatusVisualizedComponent
        reduxStatus={reduxStatus}
        showModalOnError
        modalFailureContent={reduxError}
      >
        {memberNumbers ? `目前會員總數: ${memberNumbers}` : null}
        <Table
          columns={columns}
          dataSource={data}
          rowKey="id"
          onRow={onRow}
        />
      </ReduxStatusVisualizedComponent>

      { type === 'memberInfo'
      && (
      <MemberInfoComponent
        memberInfo={selectedMember}
        updateMemberInfo={updateMemberInfo}
        cancelSelectMember={() => setSelectedMember(null)}
      />
      )}

      { type === 'cartInfo'
      && (
        <CartInfoComponent
          cartList={cartList}
          memberInfo={selectedMember}
          onCloseDialog={() => setSelectedMember(null)}
        />
      )}
    </div>
  );
};

MemberManagementPage.propTypes = {
  reduxStatus: PropTypes.string.isRequired,
  reduxError: PropTypes.string,
  data: PropTypes.array,
  memberNumbers: PropTypes.number,
  searchByKeyword: PropTypes.func.isRequired,
  updateMemberInfo: PropTypes.func.isRequired,
  onMemberSelected: PropTypes.func,
  fetchCartInfo: PropTypes.func,
  type: PropTypes.string,
  cartList: PropTypes.array,
};

MemberManagementPage.defaultProps = {
  data: [],
  memberNumbers: null,
  cartList: [],
  reduxError: null,
  onMemberSelected: null,
  type: 'memberInfo',
  fetchCartInfo: null,
};

export default MemberManagementPage;
