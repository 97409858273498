import React from 'react';
import PropTypes from 'prop-types';
import FormTableComponent from '../../../../components/FormTable/FormTableComponent';


const ContactInfoComponent = ({
  contactInfo,
  updateContactInfo,
  deleteContact,
  cancelSelectContact,
}) => {
  const isCreating = Object.keys(contactInfo || {}).length === 0;

  const renderTableRows = (renderField, renderSeparator) => [
    renderField({ key: 'name', name: '姓名' }),
    renderField({ key: 'email', name: 'E-mail' }),
    renderField({ key: 'phone', name: '手機號碼' }),
    renderField({ key: 'orderNo', name: '訂單編號' }),
    renderField({
      key: 'content',
      name: '內容',
      type: 'textArea',
    }),
    renderField({
      key: 'createTime',
      name: '聯絡日期',
      type: 'dateTime',
    }),
    renderSeparator(1),
    renderField({ key: 'subject', name: '信件主旨' }),
    renderField({
      key: 'replyContent',
      name: '客服回覆',
      type: 'textArea',
    }),
    renderField({
      key: 'isSenEmailAfterSaved',
      name: '儲存後寄送email回覆',
      type: 'checkbox',
    }),
    renderField({
      key: 'isReply',
      name: '聯絡狀態',
      type: 'select',
      options: [{ id: 'false', name: '尚未聯繫' }, { id: 'true', name: '已回覆' }],
    }),
  ];

  return (
    <FormTableComponent
      data={contactInfo}
      updateData={updateContactInfo}
      cancelSelection={cancelSelectContact}
      renderTableRows={renderTableRows}
      deleteData={deleteContact}
      isCreating={isCreating}
    />
  );
};

ContactInfoComponent.propTypes = {
  contactInfo: PropTypes.shape({}),
  updateContactInfo: PropTypes.func.isRequired,
  deleteContact: PropTypes.func.isRequired,
  cancelSelectContact: PropTypes.func.isRequired,
};

ContactInfoComponent.defaultProps = {
  contactInfo: null,
};

export default ContactInfoComponent;
