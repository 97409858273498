import { handleActions, combineActions } from 'redux-actions';
import update from 'immutability-helper';
import * as R from 'ramda';
import ActionCreator from './ActionCreator';
import ReduxStatus from '../../constants/ReduxStatus';

const {
  resetStatus,
  getSubscribeListFlowRequest,
  getSubscribeListFlowSuccess,
  getSubscribeListFlowFailure,
  updateSubscribeInfoRequest,
  updateSubscribeInfoSuccess,
  updateSubscribeInfoFailure,
  deleteSubscribeRequest,
  deleteSubscribeSuccess,
  deleteSubscribeFailure,
  searchFlowRequest,
  searchFlowSuccess,
  searchFlowFailure,
  sendNoticeToSubscriberRequest,
  sendNoticeToSubscriberSuccess,
  sendNoticeToSubscriberFailure,
  getMemberSubscribeListFlowRequest,
  getMemberSubscribeListFlowSuccess,
  getMemberSubscribeListFlowFailure,
} = ActionCreator;

const initialState = {
  reduxStatus: ReduxStatus.STATUS_INITIAL,
  error: null,
  data: [],
};

export default handleActions({
  [combineActions(
    getSubscribeListFlowRequest,
    updateSubscribeInfoRequest,
    deleteSubscribeRequest,
    searchFlowRequest,
    sendNoticeToSubscriberRequest,
    getMemberSubscribeListFlowRequest,
  )]: state => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_LOADING },
    error: { $set: initialState.error },
  }),

  [combineActions(
    getSubscribeListFlowSuccess,
    searchFlowSuccess,
    getMemberSubscribeListFlowSuccess,
  )]: (state, { payload }) => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_NORMAL },
    data: R.pathOr(0, ['condition', 'page'], payload) === 0
      ? { $set: payload.data }
      : { $push: payload.data },
  }),
  [combineActions(
    getSubscribeListFlowFailure,
    updateSubscribeInfoFailure,
    deleteSubscribeFailure,
    sendNoticeToSubscriberFailure,
    searchFlowFailure,
    getMemberSubscribeListFlowFailure,
  )]: (state, { payload }) => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_ERROR },
    error: { $set: payload },
  }),
  [updateSubscribeInfoSuccess]: (state, { payload }) => {
    const {
      id,
    } = payload;
    return update(state, {
      reduxStatus: { $set: ReduxStatus.STATUS_NORMAL },
      data: {
        $set: R.pathOr([], ['data'], state).map((Subscribe) => {
          if (Subscribe.id === id) return payload;
          return Subscribe;
        }),
      },
    });
  },
  [sendNoticeToSubscriberSuccess]: (state, { payload }) => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_NORMAL },
    data: {
      $set: [...R.pathOr([], ['data'], state), payload],
    },
  }),
  [deleteSubscribeSuccess]: (state, { payload }) => {
    const {
      id,
    } = payload;
    return update(state, {
      reduxStatus: { $set: ReduxStatus.STATUS_NORMAL },
      data: {
        $set: R.pathOr([], ['data'], state).map((Subscribe) => {
          if (Subscribe.id === id) return null;
          return Subscribe;
        }).filter(d => d),
      },
    });
  },
  [resetStatus]: state => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_INITIAL },
  }),
}, initialState);
