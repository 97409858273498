import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'antd';
import ReduxStatusVisualizedComponent from '../../../../components/ReduxStatusVisualizedComponent';
import ThemeBagItemComponent from './ThemeBagItemComponent';
import BagManagementContainer from '../../../BagManagement/components/BagManagementPage';

const styles = {
  mainContainer: {
    margin: 20,
  },
  selectedBagDisplayerInSelector: {
    position: 'fixed',
    zIndex: 9999999,
    top: 0,
    backgroundColor: 'white',
    padding: 10,
    left: 0,
    width: '100%',
  },
};

const ThemeBagManagementPage = ({
  reduxStatus,
  reduxError,
  data,
  linkThemeBags,
  unlinkThemeBag,
}) => {
  const [showBagSelector, setShowBagSelector] = useState(false);
  const [selectedBags, setSelectedBags] = useState([]);
  useEffect(() => {
    setSelectedBags([]);
  }, [showBagSelector]);

  const handleBagSelected = bag => setSelectedBags([
    ...(selectedBags.map((selectedBag) => {
      if (bag.id === selectedBag.id) return null;
      return selectedBag;
    })).filter(d => d),
    bag,
  ]);

  const linkSelectedBags = () => {
    linkThemeBags(selectedBags);
    setShowBagSelector(false);
  };

  return (
    <div style={styles.mainContainer} key="baseContainer">

      <ReduxStatusVisualizedComponent
        reduxStatus={reduxStatus}
        showModalOnError
        modalFailureContent={reduxError}
      >
        {(data || []).map(bag => (
          <ThemeBagItemComponent
            key={bag.id}
            onClick={() => { unlinkThemeBag(bag.id); }}
            {...bag}
          />
        ))}
      </ReduxStatusVisualizedComponent>

      <Button onClick={() => { setShowBagSelector(true); }}>新增</Button>

      <Modal
        title="選擇包款"
        visible={showBagSelector}
        width="90%"
        onCancel={() => { setShowBagSelector(false); }}
        onOk={linkSelectedBags}
      >
        <BagManagementContainer
          onBagSelected={(bag) => {
            if (!bag) return;
            handleBagSelected(bag);
          }}
        />
        {
        selectedBags.length > 0
        && (
        <div style={styles.selectedBagDisplayerInSelector}>
          已選的包：
          {selectedBags.map(bag => (
            <ThemeBagItemComponent
              {...bag}
              key={bag.id}
              onClick={() => setSelectedBags(selectedBags.filter(selectedBag => (!(selectedBag.id === bag.id))))}
            />
          )) }
        </div>
        )
      }
      </Modal>

    </div>
  );
};

ThemeBagManagementPage.propTypes = {
  reduxStatus: PropTypes.string.isRequired,
  reduxError: PropTypes.string,
  data: PropTypes.array,
  linkThemeBags: PropTypes.func.isRequired,
  unlinkThemeBag: PropTypes.func.isRequired,
};

ThemeBagManagementPage.defaultProps = {
  data: [],
  reduxError: null,
};

export default ThemeBagManagementPage;
