import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';
import ActionCreator from './ActionCreator';
import API from './API';

const {
  tappayPayByTokenRequest,
  tappayPayByTokenSuccess,
  tappayPayByTokenFailure,
  tappayPayByTokenFlowRequest,
  tappayPayByTokenFlowSuccess,
  tappayPayByTokenFlowFailure,
} = ActionCreator;

// ---------- TASKS ----------
function* tappayPayByToken(payload) {
  try {
    yield put(tappayPayByTokenRequest());
    const result = yield call(API.payBToken, payload);
    yield put(tappayPayByTokenSuccess());
    return result;
  } catch (error) {
    yield put(tappayPayByTokenFailure(error));
    throw (error);
  }
}

// ---------- FLOW ----------
export function* tappayPayByTokenFlow({ payload }) {
  try {
    yield call(tappayPayByToken, payload);
    yield put(tappayPayByTokenFlowSuccess());
  } catch (error) {
    yield put(tappayPayByTokenFlowFailure(error));
  }
}

export default [
  takeLatest(tappayPayByTokenFlowRequest, tappayPayByTokenFlow),
];
