import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Table, Button } from 'antd';
import ReduxStatusVisualizedComponent from '../../../../components/ReduxStatusVisualizedComponent';
import TagInfoComponent from './TagInfoComponent';

const styles = {
  mainContainer: {
    margin: 20,
  },
};

const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    width: 150,
  },
  {
    title: '順序',
    dataIndex: 'order',
    width: 150,
  },
  {
    title: '名稱',
    dataIndex: 'name',
  },
];

const TagManagementPage = ({
  reduxStatus,
  reduxError,
  data,
  updateTagInfo,
  deleteTag,
  createTag,
  onTagSelected,
}) => {
  const [selectedTag, setSelectedTag] = useState(null);

  useEffect(() => {
    setSelectedTag(null);
  }, [data]);

  const onRow = record => ({
    onClick: () => {
      if (record.isTag) {
        if (onTagSelected) onTagSelected(record);
        else setSelectedTag(record);
      }
    },
  });

  const getRowKey = ({ id, name }) => (`${id}.${name}`);

  return (
    <div style={styles.mainContainer} key="baseContainer">

      <ReduxStatusVisualizedComponent
        reduxStatus={reduxStatus}
        showModalOnError
        modalFailureContent={reduxError}
      >

        <Button onClick={() => { setSelectedTag({}); }}>
          新增標籤
        </Button>

        <Table
          rowKey={getRowKey}
          columns={columns}
          childrenColumnName="tags"
          expandRowByClick
          dataSource={data.categorizedTags}
          onRow={onRow}
          pagination={false}
        />

        <TagInfoComponent
          tagInfo={selectedTag}
          tagCategories={data.categorizedTags || []}
          updateTagInfo={updateTagInfo}
          deleteTag={deleteTag}
          createTag={createTag}
          cancelSelectTag={() => setSelectedTag(null)}
        />

      </ReduxStatusVisualizedComponent>

    </div>
  );
};

TagManagementPage.propTypes = {
  reduxStatus: PropTypes.string.isRequired,
  reduxError: PropTypes.string,
  data: PropTypes.shape({
    categorizedTags: PropTypes.array,
  }),
  updateTagInfo: PropTypes.func.isRequired,
  deleteTag: PropTypes.func.isRequired,
  createTag: PropTypes.func.isRequired,
  onTagSelected: PropTypes.func,
};

TagManagementPage.defaultProps = {
  data: {},
  reduxError: null,
  onTagSelected: null,
};

export default TagManagementPage;
