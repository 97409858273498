import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ActionCreators from '../../ActionCreator';
import ContactManagementPage from './ContactManagementPage';
import {
  reduxStatusSelector,
  reduxErrorSelector,
  contactListSelector,
  unReplyNumberSelector,
} from '../../selector';

const ContactManagementPageContainer = ({
  reduxStatus,
  reduxError,
  actions: {
    updateContactInfoFlowRequest,
    getContactListFlowRequest,
    searchFlowRequest,
    deleteContactFlowRequest,
    resetStatus,
    getUnReplyNumbersFlowRequest,
  },
  data,
  unReplyNumber,
}) => {
  useEffect(() => {
    getContactListFlowRequest();
    getUnReplyNumbersFlowRequest();
    return resetStatus;
  }, []);

  const [searchKeyword, setSearchKeyword] = useState(null);
  const [page, setPage] = useState(0);

  const handleSearch = (keyword) => {
    setSearchKeyword(keyword);
    setPage(0);
    searchFlowRequest({
      keyword,
      page: 0,
    });
  };
  const handleLoadMore = () => {
    const newPage = page + 1;
    setPage(newPage);
    searchFlowRequest({
      keyword: searchKeyword,
      page: newPage,
    });
  };

  return (
    <ContactManagementPage
      reduxStatus={reduxStatus}
      reduxError={reduxError}
      data={data}
      updateContactInfo={updateContactInfoFlowRequest}
      deleteContact={deleteContactFlowRequest}
      search={handleSearch}
      handleLoadMore={handleLoadMore}
      unReplyNumber={unReplyNumber}
    />
  );
};

ContactManagementPageContainer.propTypes = {
  reduxStatus: PropTypes.string,
  reduxError: PropTypes.string,
  actions: PropTypes.shape({
    getContactListFlowRequest: PropTypes.func.isRequired,
    updateContactInfoFlowRequest: PropTypes.func.isRequired,
    deleteContactFlowRequest: PropTypes.func.isRequired,
    resetStatus: PropTypes.func.isRequired,
    searchFlowRequest: PropTypes.func.isRequired,
    getUnReplyNumbersFlowRequest: PropTypes.func.isRequired,
  }).isRequired,
  data: PropTypes.array,
  unReplyNumber: PropTypes.number,
};

ContactManagementPageContainer.defaultProps = {
  reduxStatus: null,
  reduxError: null,
  data: [],
  unReplyNumber: null,
};

const mapStateToProps = state => ({
  reduxStatus: reduxStatusSelector(state),
  reduxError: reduxErrorSelector(state),
  data: contactListSelector(state),
  unReplyNumber: unReplyNumberSelector(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    ...ActionCreators,
  }, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ContactManagementPageContainer);
