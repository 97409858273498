import { handleActions, combineActions } from 'redux-actions';
import update from 'immutability-helper';
import * as R from 'ramda';
import ActionCreator from './ActionCreator';
import ReduxStatus from '../../constants/ReduxStatus';

const {
  resetStatus,
  getMarqueeListFlowRequest,
  getMarqueeListFlowSuccess,
  getMarqueeListFlowFailure,
  updateMarqueeInfoRequest,
  updateMarqueeInfoSuccess,
  updateMarqueeInfoFailure,
} = ActionCreator;

const initialState = {
  reduxStatus: ReduxStatus.STATUS_INITIAL,
  error: null,
  data: [],
};

export default handleActions({
  [combineActions(
    getMarqueeListFlowRequest,
    updateMarqueeInfoRequest,
  )]: state => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_LOADING },
    error: { $set: initialState.error },
  }),
  [combineActions(
    getMarqueeListFlowSuccess,
  )]: (state, { payload }) => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_NORMAL },
    data: { $set: payload },
  }),
  [combineActions(
    getMarqueeListFlowFailure,
    updateMarqueeInfoFailure,
  )]: (state, { payload }) => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_ERROR },
    error: { $set: payload },
  }),
  [updateMarqueeInfoSuccess]: (state, { payload }) => {
    const {
      id,
    } = payload;
    return update(state, {
      reduxStatus: { $set: ReduxStatus.STATUS_NORMAL },
      data: {
        $set: R.pathOr([], ['data'], state).map((Marquee) => {
          if (Marquee.id === id) return payload;
          return Marquee;
        }),

      },
    });
  },
  [resetStatus]: state => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_INITIAL },
  }),
}, initialState);
