import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Table, Button } from 'antd';
import * as R from 'ramda';
import ReduxStatusVisualizedComponent from '../../../../components/ReduxStatusVisualizedComponent';
import BagInfoComponent from './BagInfoComponent';
import BagSearchPanelComponent from '../BagSearchPanelComponent';
import Type from '../../../../constants/Type';


const styles = {
  mainContainer: {
    margin: 20,
  },
  searchBarStyle: {
    margin: '30px 0',
    width: 300,
  },
  loadMoreButton: {
    margin: '20px 0',
    width: '100%',
  },
};

const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    width: 150,
  },
  {
    title: '名稱',
    dataIndex: 'name',
  },
  {
    title: '交易型態',
    dataIndex: 'typeId',
    render: (data) => {
      if (Type.typeId.rent === data) {
        return Type.transactionType.rent;
      } if (Type.typeId.buy === data) {
        return Type.transactionType.buy;
      }
      return Type.transactionType.rentBuy;
    },
  },
  {
    title: '標籤',
    render: data => R.pathOr([], ['tags'], data).map(({ name }) => (name || '')).join(', '),
  },
  {
    title: '狀態',
    dataIndex: ['bagStatus', 'name'],
  },
  {
    title: '修改售價要求',
    render: data => R.pathOr('-', ['modifiedBuyoutAmountRequest'], data),
  },
];

const BagManagementPage = ({
  reduxStatus,
  reduxError,
  data,
  modifiedBagNumbers,
  bagNumbers,
  updateBagInfo,
  deleteBag,
  createBag,
  onBagSelected,
  onJudeModifyBuyAmount,
  commodityType,
}) => {
  const [selectedBag, setSelectedBag] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    setSelectedBag(null);
  }, [data]);

  const onRow = record => ({
    onClick: () => {
      if (onBagSelected) onBagSelected(record);
      else setSelectedBag(record);
    },
  });

  const getRowKey = ({ id, name }) => (`${id}.${name}`);

  return (
    <div style={styles.mainContainer} key="baseContainer">

      <BagSearchPanelComponent
        page={currentPage}
        commodityType={commodityType}
      />

      <ReduxStatusVisualizedComponent
        reduxStatus={reduxStatus}
        showModalOnError
        modalFailureContent={reduxError}
      >

        <Button onClick={() => { setSelectedBag({}); }}>
          新增包包
        </Button>
        {` 目前包包總數:${bagNumbers}  提出修改包包價錢總數:${modifiedBagNumbers}`}
        <Table
          rowKey={getRowKey}
          columns={columns}
          childrenColumnName="bags"
          expandRowByClick
          dataSource={data}
          onRow={onRow}
          pagination={false}
        />

        <BagInfoComponent
          bagInfo={selectedBag}
          updateBagInfo={updateBagInfo}
          deleteBag={deleteBag}
          createBag={createBag}
          cancelSelectBag={() => setSelectedBag(null)}
          onJudeModifyBuyAmount={onJudeModifyBuyAmount}
        />

        <Button
          style={styles.loadMoreButton}
          onClick={() => setCurrentPage(currentPage + 1)}
          type="primary"
        >
          載入更多包包
        </Button>

      </ReduxStatusVisualizedComponent>

    </div>
  );
};

BagManagementPage.propTypes = {
  reduxStatus: PropTypes.string.isRequired,
  reduxError: PropTypes.string,
  data: PropTypes.array,
  bagNumbers: PropTypes.number,
  modifiedBagNumbers: PropTypes.number,
  updateBagInfo: PropTypes.func.isRequired,
  deleteBag: PropTypes.func.isRequired,
  createBag: PropTypes.func.isRequired,
  onBagSelected: PropTypes.func,
  onJudeModifyBuyAmount: PropTypes.func.isRequired,
  commodityType: PropTypes.number,
};

BagManagementPage.defaultProps = {
  data: [],
  reduxError: null,
  onBagSelected: null,
  bagNumbers: null,
  modifiedBagNumbers: null,
  commodityType: 1,
};

export default BagManagementPage;
