import React from 'react';
import PropTypes from 'prop-types';
import FormTableComponent from '../../../../components/FormTable/FormTableComponent';

const TagInfoComponent = ({
  tagInfo,
  tagCategories,
  updateTagInfo,
  deleteTag,
  createTag,
  cancelSelectTag,
}) => {
  const isCreating = Object.keys(tagInfo || {}).length === 0;

  const renderTableRows = renderField => [
    renderField({
      key: 'tagCategoryId',
      name: '類別',
      type: 'select',
      options: (tagCategories || []).map(({ id, name }) => ({ id, name })),
      disabled: !isCreating,
    }),
    renderField({ key: 'order', name: '順序' }),
    renderField({ key: 'name', name: '名稱' }),
  ];
  return (
    <FormTableComponent
      data={tagInfo}
      updateData={updateTagInfo}
      cancelSelection={cancelSelectTag}
      renderTableRows={renderTableRows}
      deleteData={deleteTag}
      createData={createTag}
      isCreating={isCreating}
    />
  );
};

TagInfoComponent.propTypes = {
  tagInfo: PropTypes.shape({}),
  tagCategories: PropTypes.array,
  updateTagInfo: PropTypes.func.isRequired,
  deleteTag: PropTypes.func.isRequired,
  createTag: PropTypes.func.isRequired,
  cancelSelectTag: PropTypes.func.isRequired,
};

TagInfoComponent.defaultProps = {
  tagInfo: null,
  tagCategories: [],
};

export default TagInfoComponent;
