import { handleActions, combineActions } from 'redux-actions';
import update from 'immutability-helper';
import * as R from 'ramda';
import ActionCreator from './ActionCreator';
import ReduxStatus from '../../constants/ReduxStatus';

const {
  resetStatus,
  getTagListFlowRequest,
  getTagListFlowSuccess,
  getTagListFlowFailure,
  updateTagInfoRequest,
  updateTagInfoSuccess,
  updateTagInfoFailure,
  deleteTagRequest,
  deleteTagSuccess,
  deleteTagFailure,
  createTagRequest,
  createTagSuccess,
  createTagFailure,
} = ActionCreator;

const initialState = {
  reduxStatus: ReduxStatus.STATUS_INITIAL,
  error: null,
  data: {},
};

export default handleActions({
  [combineActions(
    getTagListFlowRequest,
    updateTagInfoRequest,
    deleteTagRequest,
    createTagRequest,
  )]: state => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_LOADING },
    error: { $set: initialState.error },
  }),
  [combineActions(
    getTagListFlowSuccess,
  )]: (state, { payload }) => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_NORMAL },
    data: { $set: payload },
  }),
  [combineActions(
    getTagListFlowFailure,
    updateTagInfoFailure,
    deleteTagFailure,
    createTagFailure,
  )]: (state, { payload }) => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_ERROR },
    error: { $set: payload },
  }),
  [updateTagInfoSuccess]: (state, { payload }) => {
    const {
      tagCategoryId,
      id,
    } = payload;
    return update(state, {
      reduxStatus: { $set: ReduxStatus.STATUS_NORMAL },
      data: {
        tags: {
          $set: R.pathOr([], ['data', 'tags'], state).map((tag) => {
            if (tag.id === id) return payload;
            return tag;
          }),
        },
        categorizedTags: {
          $set: R.pathOr([], ['data', 'categorizedTags'], state).map((tagCategory) => {
            if (tagCategory.id !== tagCategoryId) return tagCategory;
            return {
              ...tagCategory,
              tags: R.pathOr([], ['tags'], tagCategory).map((tag) => {
                if (tag.id === id) return payload;
                return tag;
              }),
            };
          }),
        },
      },
    });
  },
  [deleteTagSuccess]: (state, { payload }) => {
    const {
      tagCategoryId,
      id,
    } = payload;
    return update(state, {
      reduxStatus: { $set: ReduxStatus.STATUS_NORMAL },
      data: {
        tags: {
          $set: R.pathOr([], ['data', 'tags'], state).map((tag) => {
            if (tag.id === id) return null;
            return tag;
          }).filter(d => d),
        },
        categorizedTags: {
          $set: R.pathOr([], ['data', 'categorizedTags'], state).map((tagCategory) => {
            if (tagCategory.id !== tagCategoryId) return tagCategory;
            return {
              ...tagCategory,
              tags: R.pathOr([], ['tags'], tagCategory).map((tag) => {
                if (tag.id === id) return null;
                return tag;
              }).filter(d => d),
            };
          }),
        },
      },
    });
  },
  [createTagSuccess]: (state, { payload }) => {
    const {
      tagCategoryId,
    } = payload;
    return update(state, {
      reduxStatus: { $set: ReduxStatus.STATUS_NORMAL },
      data: {
        tags: {
          $set: [...R.pathOr([], ['data', 'tags'], state), payload],
        },
        categorizedTags: {
          $set: R.pathOr([], ['data', 'categorizedTags'], state).map((tagCategory) => {
            if (tagCategory.id !== tagCategoryId) return tagCategory;
            return {
              ...tagCategory,
              tags: [...R.pathOr([], ['tags'], tagCategory), payload],
            };
          }),
        },
      },
    });
  },
  [resetStatus]: state => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_INITIAL },
  }),
}, initialState);
