import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';
import * as R from 'ramda';
import ActionCreator from './ActionCreator';
import API from './API';

const {
  getTagListRequest,
  getTagListSuccess,
  getTagListFailure,
  getTagCategoryListRequest,
  getTagCategoryListSuccess,
  getTagCategoryListFailure,
  updateTagInfoRequest,
  updateTagInfoSuccess,
  updateTagInfoFailure,
  deleteTagRequest,
  deleteTagSuccess,
  deleteTagFailure,
  createTagRequest,
  createTagSuccess,
  createTagFailure,

  getTagListFlowRequest,
  getTagListFlowSuccess,
  getTagListFlowFailure,
  updateTagInfoFlowRequest,
  updateTagInfoFlowSuccess,
  updateTagInfoFlowFailure,
  deleteTagFlowRequest,
  deleteTagFlowSuccess,
  deleteTagFlowFailure,
  createTagFlowRequest,
  createTagFlowSuccess,
  createTagFlowFailure,
} = ActionCreator;

// ---------- TASKS ----------
function* getTagList() {
  yield put(getTagListRequest());
  try {
    const result = yield call(API.getTagList);
    yield put(getTagListSuccess(result.data));
    return result;
  } catch (error) {
    yield put(getTagListFailure(error));
    throw (error);
  }
}

export function* getTagCategoryList() {
  yield put(getTagCategoryListRequest());
  try {
    const result = (yield call(API.getTagCategoryList));
    yield put(getTagCategoryListSuccess(result.data));
    return result;
  } catch (error) {
    yield put(getTagCategoryListFailure(error));
    throw error;
  }
}

function* updateTagInfo(data) {
  yield put(updateTagInfoRequest());
  try {
    const result = yield call(API.updateTagInfo, data);
    result.data.isTag = true;
    yield put(updateTagInfoSuccess(result.data));
    return result;
  } catch (error) {
    yield put(updateTagInfoFailure(error));
    throw (error);
  }
}

function* deleteTag(data) {
  yield put(deleteTagRequest());
  try {
    const result = yield call(API.deleteTag, data);
    yield put(deleteTagSuccess(data));
    return result;
  } catch (error) {
    yield put(deleteTagFailure(error));
    throw (error);
  }
}

function* createTag(data) {
  yield put(createTagRequest());
  try {
    const result = yield call(API.createTag, data);
    result.data.isTag = true;
    yield put(createTagSuccess(result.data));
    return result;
  } catch (error) {
    yield put(createTagFailure(error));
    throw (error);
  }
}


// ---------- FLOW ----------
export function* getTagListFlow({ payload }) {
  try {
    const tags = yield call(getTagList, payload);
    const tagCategories = yield call(getTagCategoryList, payload);

    // NOTE: Build the dictionary
    const categorizedTagsDictionary = {};
    tagCategories.data.forEach((tagCategory) => {
      const {
        name,
        order,
        id,
      } = tagCategory;
      categorizedTagsDictionary[id] = {
        id,
        name,
        order,
        tags: [],
        isTag: false,
      };
    });
    tags.data.forEach((tag) => {
      const {
        name,
        tagCategoryId,
        order,
        id,
      } = tag;
      (R.pathOr([], [tagCategoryId, 'tags'], categorizedTagsDictionary)).push({
        name,
        order,
        id,
        isTag: true,
        tagCategoryId,
      });
    });

    // NOTE: Sorting...
    const sortingMethod = (a, b) => ((R.pathOr(0, ['order'], a)) - (R.pathOr(0, ['order'], b)));
    const categorizedTags = Object.keys(categorizedTagsDictionary).map((tagCategoryId) => {
      const tagCategory = categorizedTagsDictionary[tagCategoryId];
      tagCategory.tags = tagCategory.tags.sort(sortingMethod);
      return tagCategory;
    }).sort(sortingMethod);

    yield put(getTagListFlowSuccess({
      categorizedTags,
      tags: tags.data,
    }));
  } catch (error) {
    yield put(getTagListFlowFailure(error));
  }
}

export function* updateTagInfoFlow({ payload }) {
  try {
    const result = yield call(updateTagInfo, payload);
    yield put(updateTagInfoFlowSuccess(result.data));
  } catch (error) {
    yield put(updateTagInfoFlowFailure(error));
  }
}


export function* deleteTagFlow({ payload }) {
  try {
    yield call(deleteTag, payload);
    yield put(deleteTagFlowSuccess(payload));
  } catch (error) {
    yield put(deleteTagFlowFailure(error));
  }
}

export function* createTagFlow({ payload }) {
  try {
    yield call(createTag, payload);
    yield put(createTagFlowSuccess(payload));
  } catch (error) {
    yield put(createTagFlowFailure(error));
  }
}

export default [
  takeLatest(getTagListFlowRequest, getTagListFlow),
  takeLatest(updateTagInfoFlowRequest, updateTagInfoFlow),
  takeLatest(deleteTagFlowRequest, deleteTagFlow),
  takeLatest(createTagFlowRequest, createTagFlow),
];
