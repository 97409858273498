import { createActions } from 'redux-actions';
import { createAsyncActionTypesArray } from '../../utils/createAsyncActionTypes';

export default createActions(
  // ----- TASKS -----
  ...createAsyncActionTypesArray('GET_IDENTIFICATION_LIST'),
  ...createAsyncActionTypesArray('GET_IDENTIFICATION_NUMBERS'),
  ...createAsyncActionTypesArray('SEARCH_BY_KEYWORD'),
  ...createAsyncActionTypesArray('UPDATE_IDENTIFICATION_INFO'),

  // ----- FLOWS -----
  ...createAsyncActionTypesArray('GET_IDENTIFICATION_LIST_FLOW'),
  ...createAsyncActionTypesArray('GET_IDENTIFICATION_NUMBERS_FLOW'),
  ...createAsyncActionTypesArray('SEARCH_BY_KEYWORD_FLOW'),
  ...createAsyncActionTypesArray('UPDATE_IDENTIFICATION_INFO_FLOW'),

  'RESET_STATUS',

  { prefix: 'IDENTIFICATION_MANAGEMENT' },
);
