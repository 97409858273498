import { all } from 'redux-saga/effects';
import authSaga from '../modules/Auth/saga';
import systemSaga from '../modules/System/saga';
import memberManagementSaga from '../modules/MemberManagement/saga';
import tagManagementSaga from '../modules/TagManagement/saga';
import bagManagementSaga from '../modules/BagManagement/saga';
import orderManagementSaga from '../modules/OrderManagement/saga';
import contactManagementSaga from '../modules/ContactManagement/saga';
import subscribeManagementSaga from '../modules/SubscribeManagement/saga';
import cartManagementSaga from '../modules/CartManagement/saga';
import themeManagementSaga from '../modules/ThemeManagement/saga';
import commentManagementSaga from '../modules/CommentManagement/saga';
import negotiationSaga from '../modules/NegotiationManagement/saga';
import reduceDepositSaga from '../modules/ReduceDepositManagement/saga';
import withdrawSaga from '../modules/WithdrawManagement/saga';
import clothesManagementSaga from '../modules/ClothesManagement/saga';
import PayByCardTokenSaga from '../modules/PayByTokenManagement/saga';
import couponSaga from '../modules/CouponManagement/saga';
import addressSaga from '../modules/AddressManagement/saga';
import MarqueeSaga from '../modules/MarqueeManagement/saga';
import IdentificationSaga from '../modules/IdentificationManagement/saga';

export default function* rootSaga() {
  yield all([
    ...systemSaga,
    ...authSaga,
    ...memberManagementSaga,
    ...tagManagementSaga,
    ...bagManagementSaga,
    ...orderManagementSaga,
    ...contactManagementSaga,
    ...subscribeManagementSaga,
    ...cartManagementSaga,
    ...themeManagementSaga,
    ...commentManagementSaga,
    ...negotiationSaga,
    ...reduceDepositSaga,
    ...withdrawSaga,
    ...clothesManagementSaga,
    ...PayByCardTokenSaga,
    ...couponSaga,
    ...addressSaga,
    ...MarqueeSaga,
    ...IdentificationSaga,
  ]);
}
