import { createActions } from 'redux-actions';
import { createAsyncActionTypesArray } from '../../utils/createAsyncActionTypes';

export default createActions(
  // ----- TASKS -----
  ...createAsyncActionTypesArray('GET_REDUCE_DEPOSIT_LIST'),
  ...createAsyncActionTypesArray('CREATE_REDUCE_DEPOSIT'),
  ...createAsyncActionTypesArray('DELETE_REDUCE_DEPOSIT'),
  // ----- FLOWS -----
  ...createAsyncActionTypesArray('GET_REDUCE_DEPOSIT_LIST_FLOW'),
  ...createAsyncActionTypesArray('CREATE_REDUCE_DEPOSIT_FLOW'),
  ...createAsyncActionTypesArray('DELETE_REDUCE_DEPOSIT_FLOW'),

  { prefix: 'REDUCE_DEPOSIT_MANAGEMENT' },
);
