import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';
import * as R from 'ramda';
import ActionCreator from './ActionCreator';
import API from './API';
import MemberAPI from '../MemberManagement/API';

const {
  getSubscribeListRequest,
  getSubscribeListSuccess,
  getSubscribeListFailure,
  updateSubscribeInfoRequest,
  updateSubscribeInfoSuccess,
  updateSubscribeInfoFailure,
  deleteSubscribeRequest,
  deleteSubscribeSuccess,
  deleteSubscribeFailure,
  sendNoticeToSubscriberRequest,
  sendNoticeToSubscriberSuccess,
  sendNoticeToSubscriberFailure,


  getSubscribeListFlowRequest,
  getSubscribeListFlowSuccess,
  getSubscribeListFlowFailure,
  updateSubscribeInfoFlowRequest,
  updateSubscribeInfoFlowSuccess,
  updateSubscribeInfoFlowFailure,
  deleteSubscribeFlowRequest,
  deleteSubscribeFlowSuccess,
  deleteSubscribeFlowFailure,
  searchFlowRequest,
  searchFlowSuccess,
  searchFlowFailure,
  sendNoticeToSubscriberFlowRequest,
  sendNoticeToSubscriberFlowSuccess,
  sendNoticeToSubscriberFlowFailure,

  getMemberSubscribeListRequest,
  getMemberSubscribeListSuccess,
  getMemberSubscribeListFailure,
  getMemberSubscribeListFlowRequest,
  getMemberSubscribeListFlowSuccess,
  getMemberSubscribeListFlowFailure,

} = ActionCreator;

// ---------- NORMAL FUNCTIONS ----------
const buildFilterClause = (filterCondition = {}) => {
  try {
    const {
      keyword,
      page = 0,
      ...otherCondition
    } = filterCondition;

    const pageLimit = 10;

    const where = {};
    if (keyword) {
      Object.assign(where, {
        email: {
          like: `%${keyword}%`,
        },
      });
    }

    if (otherCondition && Object.keys(otherCondition).length > 0) {
      Object.assign(where, otherCondition);
    }

    const assignedFilterCondition = {
      limit: pageLimit,
      skip: pageLimit * page,
      order: 'id DESC',
      where: {
        isSubscribe: true,
      },
      include: [
      ],
      ...(R.isEmpty(where) ? {} : { where }),
    };
    return assignedFilterCondition;
  } catch (error) {
    // eslint-disable-next-line
    console.error(error);
    return {};
  }
};

// ---------- TASKS ----------
function* getSubscribeList(filterCondition = {}) {
  try {
    yield put(getSubscribeListRequest());
    const result = yield call(API.getSubscribeList, buildFilterClause(filterCondition));
    yield put(getSubscribeListSuccess(result.data));
    return result;
  } catch (error) {
    yield put(getSubscribeListFailure(error));
    throw (error);
  }
}

function* getMemberSubscribeList(filterCondition = {}) {
  try {
    yield put(getMemberSubscribeListRequest());
    const result = yield call(MemberAPI.getMemberList, buildFilterClause(filterCondition));
    yield put(getMemberSubscribeListSuccess(result.data));
    return result;
  } catch (error) {
    yield put(getMemberSubscribeListFailure(error));
    throw (error);
  }
}

function* updateSubscribeInfo(data) {
  yield put(updateSubscribeInfoRequest());
  try {
    const {
      id: subscribeId,
      ...restOfData
    } = (data || {});

    if (!R.isEmpty(restOfData)) {
      yield call(API.updateSubscribeInfo, {
        ...restOfData,
        id: subscribeId,
      });
    }

    // NOTE: The update operation has been finished successfully.
    //       Now we fetch again the data and update to the redux store.
    const result = yield call(API.getSubscribeList, buildFilterClause({
      id: data.id,
    }));

    yield put(updateSubscribeInfoSuccess(result.data[0]));
    return result;
  } catch (error) {
    yield put(updateSubscribeInfoFailure(error));
    throw (error);
  }
}

function* deleteSubscribe(data) {
  yield put(deleteSubscribeRequest());
  try {
    const result = yield call(API.deleteSubscribe, data);
    yield put(deleteSubscribeSuccess(data));
    return result;
  } catch (error) {
    yield put(deleteSubscribeFailure(error));
    throw (error);
  }
}


function* sendNoticeToSubscriber(data) {
  yield put(sendNoticeToSubscriberRequest());
  try {
    yield call(API.sendNoticeToSubscriber, data);
    yield put(sendNoticeToSubscriberSuccess(data));
    return data;
  } catch (error) {
    yield put(sendNoticeToSubscriberFailure(error));
    throw (error);
  }
}


// ---------- FLOW ----------
export function* getSubscribeListFlow({ payload }) {
  try {
    const result = yield call(getSubscribeList, payload);
    yield put(getSubscribeListFlowSuccess({
      condition: payload,
      data: result.data,
    }));
  } catch (error) {
    yield put(getSubscribeListFlowFailure(error));
  }
}

export function* getMemberSubscribeListFlow({ payload }) {
  try {
    const result = yield call(getMemberSubscribeList, payload);
    yield put(getMemberSubscribeListFlowSuccess({
      condition: payload,
      data: result.data,
    }));
  } catch (error) {
    yield put(getMemberSubscribeListFlowFailure(error));
  }
}

export function* updateSubscribeInfoFlow({ payload }) {
  try {
    const result = yield call(updateSubscribeInfo, payload);
    yield put(updateSubscribeInfoFlowSuccess(result.data));
  } catch (error) {
    yield put(updateSubscribeInfoFlowFailure(error));
  }
}

export function* deleteSubscribeFlow({ payload }) {
  try {
    yield call(deleteSubscribe, payload);
    yield put(deleteSubscribeFlowSuccess(payload));
  } catch (error) {
    yield put(deleteSubscribeFlowFailure(error));
  }
}


export function* searchFlow({ payload }) {
  try {
    const { selectType } = payload;
    const result = selectType === 'member' ? yield call(getMemberSubscribeList, payload) : yield call(getSubscribeList, payload);
    yield put(searchFlowSuccess({
      condition: payload,
      data: result.data,
    }));
  } catch (error) {
    yield put(searchFlowFailure(error));
  }
}

export function* sendNoticeToSubscriberFlow({ payload }) {
  try {
    yield call(sendNoticeToSubscriber, payload);
    yield put(sendNoticeToSubscriberFlowSuccess(payload));
  } catch (error) {
    yield put(sendNoticeToSubscriberFlowFailure(error));
  }
}


export default [
  takeLatest(getSubscribeListFlowRequest, getSubscribeListFlow),
  takeLatest(getMemberSubscribeListFlowRequest, getMemberSubscribeListFlow),
  takeLatest(updateSubscribeInfoFlowRequest, updateSubscribeInfoFlow),
  takeLatest(deleteSubscribeFlowRequest, deleteSubscribeFlow),
  takeLatest(searchFlowRequest, searchFlow),
  takeLatest(sendNoticeToSubscriberFlowRequest, sendNoticeToSubscriberFlow),
];
