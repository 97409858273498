import PropTypes from 'prop-types';
import React from 'react';
import { List, Modal } from 'antd';
import { pathOr } from 'ramda';

const CartInfoComponent = ({
  memberInfo,
  onCloseDialog,
  cartList,
}) => (
  <Modal
    visible={memberInfo != null}
    onCancel={onCloseDialog}
    onOk={onCloseDialog}
    maskClosable={false}
  >
    <List
      dataSource={cartList || []}
      renderItem={item => (
        <List.Item>
          <div>{` ${pathOr('', ['bag', 'name'], item)} / ${pathOr('', ['bag', 'bagNo'], item)}`}</div>
        </List.Item>
      )}
    />
  </Modal>
);

CartInfoComponent.propTypes = {
  memberInfo: PropTypes.shape({}),
  onCloseDialog: PropTypes.func.isRequired,
  cartList: PropTypes.array,
};

CartInfoComponent.defaultProps = {
  memberInfo: null,
  cartList: [],
};

export default CartInfoComponent;
