import api from '../../lib/configAxios';

export default {

  getTagCategoryList: async (filter = { order: 'id DESC' }) => api.get(
    `TagCategories?filter=${encodeURIComponent(JSON.stringify(filter))}`,
  ),

  getTagList: async (filter = { order: 'id DESC' }) => api.get(
    `Tags?filter=${encodeURIComponent(JSON.stringify(filter))}`,
  ),

  updateTagInfo: async data => api.patch(
    `Tags/${data.id}`, data,
  ),

  deleteTag: async data => api.delete(
    `Tags/${data.id}`,
  ),

  createTag: async data => api.post(
    'Tags', data,
  ),
};
