import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Table, Button, Input } from 'antd';
import * as R from 'ramda';
import ReduxStatusVisualizedComponent from '../../../../components/ReduxStatusVisualizedComponent';
import ContactInfoComponent from './ContactInfoComponent';

const { Search } = Input;

const styles = {
  mainContainer: {
    margin: 20,
  },
  searchBarStyle: {
    margin: '30px 0',
    width: 300,
  },
  loadMoreButton: {
    margin: '20px 0',
    width: '100%',
  },
};

const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    width: 150,
  },
  {
    title: '姓名',
    dataIndex: 'name',
  },
  {
    title: 'E-mail',
    dataIndex: 'email',
  },
  {
    title: '聯絡日期',
    render: (data) => {
      const date = R.pathOr([], ['createTime'], data);
      return `${new Date(date).toLocaleString()}`;
    },
  },
  {
    title: '聯絡狀態',
    render: (data) => {
      const isReply = R.pathOr(null, ['isReply'], data);
      if (isReply) {
        return '已回覆';
      }
      return '尚未聯繫';
    },
  },
];

const ContactManagementPage = ({
  reduxStatus,
  reduxError,
  data,
  updateContactInfo,
  deleteContact,
  search,
  handleLoadMore,
  unReplyNumber,
}) => {
  const [selectedContact, setSelectedContact] = useState(null);

  useEffect(() => {
    setSelectedContact(null);
  }, [data]);

  const onRow = record => ({
    onClick: () => {
      setSelectedContact(record);
    },
  });

  const getRowKey = ({ id, name }) => (`${id}.${name}`);

  return (
    <div style={styles.mainContainer} key="baseContainer">
      <Search
        placeholder="搜尋"
        onSearch={value => search(value)}
        enterButton
        size="large"
        style={styles.searchBarStyle}
      />

      <ReduxStatusVisualizedComponent
        reduxStatus={reduxStatus}
        showModalOnError
        modalFailureContent={reduxError}
      >
        {`尚未聯絡筆數 ${unReplyNumber}`}

        <Table
          rowKey={getRowKey}
          columns={columns}
          childrenColumnName="Contacts"
          dataSource={data}
          expandRowByClick
          onRow={onRow}
          pagination={false}
        />

        <ContactInfoComponent
          contactInfo={selectedContact}
          updateContactInfo={updateContactInfo}
          deleteContact={deleteContact}
          cancelSelectContact={() => setSelectedContact(null)}
        />
        <Button
          style={styles.loadMoreButton}
          onClick={handleLoadMore}
          type="primary"
        >
          載入更多聯絡資料
        </Button>
      </ReduxStatusVisualizedComponent>

    </div>
  );
};

ContactManagementPage.propTypes = {
  reduxStatus: PropTypes.string.isRequired,
  reduxError: PropTypes.string,
  data: PropTypes.array,
  updateContactInfo: PropTypes.func.isRequired,
  deleteContact: PropTypes.func.isRequired,
  search: PropTypes.func.isRequired,
  handleLoadMore: PropTypes.func.isRequired,
  unReplyNumber: PropTypes.number,
};

ContactManagementPage.defaultProps = {
  data: [],
  reduxError: null,
  unReplyNumber: null,
};

export default ContactManagementPage;
