import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ActionCreators from '../../ActionCreator';
import OrderManagementPage from './OrderManagementPage';

import {
  orderListSelector,
  reduxStatusSelector,
  reduxErrorSelector,
  orderStatusListSelector,
  receiptTypeListSelector,
  donateOrganizationListSelector,
  orderNumberSelector,
} from '../../selector';

const OrderManagementPageContainer = ({
  actions: {
    getOrderListFlowRequest,
    getOrderStatusListFlowRequest,
    getDonateOrganizationListFlowRequest,
    getReceiptTypeListFlowRequest,
    updateOrderInfoFlowRequest,
    createOrderFlowRequest,
    deleteOrderFlowRequest,
    getOrderNumbersFlowRequest,
    filterOrderByKeywordFlowRequest,
  },
  data,
  orderStatusList,
  donateOrganizationList,
  receiptTypeList,
  reduxError,
  reduxStatus,
  orderNumber,
}) => {
  const [searchKeyword, setSearchKeyword] = useState(null);
  const [newData, setNewData] = useState(data);
  const [page, setPage] = useState(0);

  useEffect(() => {
    getOrderListFlowRequest({ page });
    getOrderStatusListFlowRequest();
    getDonateOrganizationListFlowRequest();
    getReceiptTypeListFlowRequest();
    getOrderNumbersFlowRequest();
  }, []);

  const renderId = () => {
    data.forEach(item => Object.assign(item, { newId: 501200 + item.id }));
  };

  useEffect(() => {
    renderId();
    setNewData(data);
  }, [data]);


  const handleSearch = (keyword) => {
    setSearchKeyword(keyword);
    setPage(0);
    filterOrderByKeywordFlowRequest({
      keyword,
      page: 0,
    });
  };

  const handleLoadMore = () => {
    const newPage = page + 1;
    setPage(newPage);
    filterOrderByKeywordFlowRequest({
      keyword: searchKeyword,
      page: newPage,
    });
  };

  return (
    <OrderManagementPage
      search={handleSearch}
      reduxStatus={reduxStatus}
      reduxError={reduxError}
      data={newData}
      orderStatusList={orderStatusList}
      receiptTypeList={receiptTypeList}
      donateOrganizationList={donateOrganizationList}
      updateOrderInfo={updateOrderInfoFlowRequest}
      createOrder={createOrderFlowRequest}
      deleteOrder={deleteOrderFlowRequest}
      handleLoadMore={handleLoadMore}
      orderNumber={orderNumber}
    />
  );
};

OrderManagementPageContainer.propTypes = {
  actions: PropTypes.shape({
    searchFlowRequest: PropTypes.func.isRequired,
    getOrderListFlowRequest: PropTypes.func.isRequired,
    getOrderStatusListFlowRequest: PropTypes.func.isRequired,
    updateOrderInfoFlowRequest: PropTypes.func.isRequired,
    createOrderFlowRequest: PropTypes.func.isRequired,
    getDonateOrganizationListFlowRequest: PropTypes.func.isRequired,
    getReceiptTypeListFlowRequest: PropTypes.func.isRequired,
    deleteOrderFlowRequest: PropTypes.func.isRequired,
    filterOrderByKeywordFlowRequest: PropTypes.func.isRequired,
    getOrderNumbersFlowRequest: PropTypes.func.isRequired,
  }).isRequired,
  reduxStatus: PropTypes.string,
  data: PropTypes.array,
  orderStatusList: PropTypes.array,
  reduxError: PropTypes.string,
  donateOrganizationList: PropTypes.array,
  receiptTypeList: PropTypes.array,
  orderNumber: PropTypes.number,
};

OrderManagementPageContainer.defaultProps = {
  data: [],
  reduxStatus: null,
  reduxError: '',
  orderStatusList: [],
  donateOrganizationList: [],
  receiptTypeList: [],
  orderNumber: null,
};

const mapStateToProps = state => ({
  data: orderListSelector(state),
  reduxStatus: reduxStatusSelector(state),
  reduxError: reduxErrorSelector(state),
  orderStatusList: orderStatusListSelector(state),
  donateOrganizationList: donateOrganizationListSelector(state),
  receiptTypeList: receiptTypeListSelector(state),
  orderNumber: orderNumberSelector(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    ...ActionCreators,
  }, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OrderManagementPageContainer);
