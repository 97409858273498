import React from 'react';
import PropTypes from 'prop-types';
import ReduxStatusVisualizedComponent from '../../../../components/ReduxStatusVisualizedComponent';
import Logo from '../../../../components/Logo';

const styles = {
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    position: 'absolute',
  },
  logoStyle: {
    width: 320,
    height: 60,
    color: 'black',
    margin: 20,
    fontSize: '50px',
  },
};

const LogoutScreen = ({
  reduxStatus,
}) => (
  <ReduxStatusVisualizedComponent
    reduxStatus={reduxStatus}
    containerStyle={styles.mainContainer}
  >
    <Logo style={styles.logoStyle} />

  </ReduxStatusVisualizedComponent>
);

LogoutScreen.propTypes = {
  reduxStatus: PropTypes.string,
};

LogoutScreen.defaultProps = {
  reduxStatus: null,
};

export default LogoutScreen;
