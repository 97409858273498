import { createActions } from 'redux-actions';
import { createAsyncActionTypesArray } from '../../utils/createAsyncActionTypes';

export default createActions(
  // ----- TASKS -----
  ...createAsyncActionTypesArray('GET_THEME_LIST'),
  ...createAsyncActionTypesArray('CREATE_THEME'),
  ...createAsyncActionTypesArray('UPDATE_THEME'),
  ...createAsyncActionTypesArray('DELETE_THEME'),
  ...createAsyncActionTypesArray('GET_THEME_BAG_LIST'),
  ...createAsyncActionTypesArray('LINK_THEME_BAG'),
  ...createAsyncActionTypesArray('UNLINK_THEME_BAG'),

  // ----- FLOWS -----
  ...createAsyncActionTypesArray('GET_THEME_LIST_FLOW'),
  ...createAsyncActionTypesArray('CREATE_THEME_FLOW'),
  ...createAsyncActionTypesArray('UPDATE_THEME_FLOW'),
  ...createAsyncActionTypesArray('DELETE_THEME_FLOW'),
  ...createAsyncActionTypesArray('GET_THEME_BAG_LIST_FLOW'),
  ...createAsyncActionTypesArray('LINK_THEME_BAG_FLOW'),
  ...createAsyncActionTypesArray('UNLINK_THEME_BAG_FLOW'),

  'RESET_STATUS',

  { prefix: 'THEME_MANAGEMENT' },
);
