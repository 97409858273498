import { handleActions } from 'redux-actions';
import update from 'immutability-helper';
import ActionCreator from './ActionCreator';
import ReduxStatus from '../../constants/ReduxStatus';

const {
  tappayPayByTokenFlowRequest,
  tappayPayByTokenSuccess,
  tappayPayByTokenFailure,
} = ActionCreator;

const initialState = {
  reduxStatus: ReduxStatus.STATUS_INITIAL,
  error: null,
};

export default handleActions({
  [tappayPayByTokenFlowRequest]: state => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_LOADING },
    error: { $set: initialState.error },
  }),

  [tappayPayByTokenSuccess]: state => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_NORMAL },
  }),
  [tappayPayByTokenFailure]: (state, { payload }) => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_ERROR },
    error: { $set: payload },
  }),
}, initialState);
