import {
  all,
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';
import * as R from 'ramda';
import ActionCreator from './ActionCreator';
import API from './API';
import BagSortOrder from '../../constants/BagSortOrder';
import Type from '../../constants/Type';

const {
  getBagListRequest,
  getBagListSuccess,
  getBagListFailure,
  getBagNumbersRequest,
  getBagNumbersSuccess,
  getBagNumbersFailure,
  getModifiedBuyAmountRequestNumberRequest,
  getModifiedBuyAmountRequestNumberSuccess,
  getModifiedBuyAmountRequestNumberFailure,
  updateBagInfoRequest,
  updateBagInfoSuccess,
  updateBagInfoFailure,
  deleteBagRequest,
  deleteBagSuccess,
  deleteBagFailure,
  createBagRequest,
  createBagSuccess,
  createBagFailure,

  getBagListFlowRequest,
  getBagListFlowSuccess,
  getBagListFlowFailure,
  getBagNumbersFlowRequest,
  getBagNumbersFlowSuccess,
  getBagNumbersFlowFailure,
  getModifiedBuyAmountRequestNumberFlowRequest,
  getModifiedBuyAmountRequestNumberFlowSuccess,
  getModifiedBuyAmountRequestNumberFlowFailure,
  updateBagInfoFlowRequest,
  updateBagInfoFlowSuccess,
  updateBagInfoFlowFailure,
  deleteBagFlowRequest,
  deleteBagFlowSuccess,
  deleteBagFlowFailure,
  createBagFlowRequest,
  createBagFlowSuccess,
  createBagFlowFailure,

  judgeModifyBuyAmountFlowRequest,
  judgeModifyBuyAmountFlowSuccess,
  judgeModifyBuyAmountFlowFailure,
  judgeModifyBuyAmountRequest,
  judgeModifyBuyAmountSuccess,
  judgeModifyBuyAmountFailure,
} = ActionCreator;

// ---------- NORMAL FUNCTIONS ----------
const buildFilterClause = (filterCondition = {}) => {
  try {
    const {
      keyword,
      tags,
      page = 0,
      bagStatus,
      sortOrder = 0,
      bagType,
      ownerId,
      commodityType,
      ...otherCondition
    } = filterCondition;

    const pageLimit = 10;

    const where = {};
    // bag is old data including null.
    if (commodityType !== Type.commodityTypeId.bag) {
      Object.assign(where, {
        commodityTypeId: commodityType,
      });
    } else {
      Object.assign(where, {
        and: [
          {
            or: [
              { commodityTypeId: null },
              { commodityTypeId: Type.commodityTypeId.bag },
            ],
          },
        ],
      });
    }

    // Object.assign(where, {
    //   and: [
    //     {
    //       or: [
    //         { commodityTypeId: null },
    //         { commodityTypeId: Type.commodityTypeId.bag },
    //       ],
    //     },
    //   ],
    // });

    if (keyword) {
      Object.assign(where, {
        name: {
          like: `%${keyword}%`,
        },
      });
    }

    if (tags) {
      Object.assign(where, {
        tags,
      });
    }
    if (ownerId) {
      Object.assign(where, {
        ownerId,
      });
    }

    if (bagStatus) {
      Object.assign(where, {
        bagStatusId: bagStatus,
      });
    }

    if (bagType) {
      Object.assign(where, {
        typeId: bagType,
      });
    }

    const order = R.pathOr(
      'id DESC',
      [sortOrder, 'order'],
      BagSortOrder,
    );


    if (otherCondition && Object.keys(otherCondition).length > 0) {
      Object.assign(where, otherCondition);
    }

    const assignedFilterCondition = {
      limit: pageLimit,
      skip: pageLimit * page,
      order,
      include: [
        {
          relation: 'frontPic',
          scope: { fields: 'uri' },
        },
        {
          relation: 'backPic',
          scope: { fields: 'uri' },
        },
        {
          relation: 'sidePic',
          scope: { fields: 'uri' },
        },
        {
          relation: 'bottomPic',
          scope: { fields: 'uri' },
        },
        {
          relation: 'zipperPic',
          scope: { fields: 'uri' },
        },
        'type',
        {
          relation: 'bagStatus',
          scope: { fields: 'name' },
        },
        'tags',
        {
          relation: 'owner',
          scope: { fields: ['name', 'email'] },
        },
        {
          relation: 'negotiator',
          scope: { fields: ['name', 'email'] },
        },
        'commodityType',
      ],
      ...(R.isEmpty(where) ? {} : { where }),
    };

    return assignedFilterCondition;
  } catch (error) {
    // eslint-disable-next-line
    console.error(error);
    return {};
  }
};

// ---------- TASKS ----------
function* getBagList(filterCondition = {}) {
  try {
    yield put(getBagListRequest());
    const result = yield call(API.getBagList, buildFilterClause(filterCondition));
    yield put(getBagListSuccess(result.data));
    return result;
  } catch (error) {
    yield put(getBagListFailure(error));
    throw (error);
  }
}

function* getBagNumbers() {
  try {
    yield put(getBagNumbersRequest());
    const result = yield call(API.getBagNumbers, {});
    yield put(getBagNumbersSuccess(result.data));
    return result;
  } catch (error) {
    yield put(getBagNumbersFailure(error));
    throw (error);
  }
}

function* getModifiedBuyAmountRequestNumber() {
  try {
    yield put(getModifiedBuyAmountRequestNumberRequest());
    const result = yield call(API.getBagNumbers, { modifiedBuyoutAmountRequest: { neq: null } });
    yield put(getModifiedBuyAmountRequestNumberSuccess(result.data));
    return result;
  } catch (error) {
    yield put(getModifiedBuyAmountRequestNumberFailure(error));
    throw (error);
  }
}

function* updateBagInfo(data) {
  yield put(updateBagInfoRequest());
  try {
    const {
      tags,
      id: bagId,
      frontPic,
      backPic,
      sidePic,
      bottomPic,
      zipperPic,
      owner,
      negotiator,
      ...restOfData
    } = (data || {});

    // NOTE: Updating tags (link or unlink tags)
    if (tags) {
      yield all(Object.keys(tags).map((tagId) => {
        const {
          action,
        } = tags[tagId];
        if (action === 'add') return call(API.linkBagTag, { tagId, bagId });
        if (action === 'remove') return call(API.unlinkBagTag, { tagId, bagId });
        return null;
      }).filter(d => d));
    }

    // NOTE: Updating pics
    if (frontPic) Object.assign(restOfData, { frontPicId: frontPic.id });
    if (backPic) Object.assign(restOfData, { backPicId: backPic.id });
    if (sidePic) Object.assign(restOfData, { sidePicId: sidePic.id });
    if (bottomPic) Object.assign(restOfData, { bottomPicId: bottomPic.id });
    if (zipperPic) Object.assign(restOfData, { zipperPicId: zipperPic.id });

    // NOTE: Updating owner
    if (owner) Object.assign(restOfData, { ownerId: owner.id });

    if (negotiator) Object.assign(restOfData, { negotiatorId: negotiator.id });

    // NOTE: Updating bag info
    if (!R.isEmpty(restOfData)) {
      yield call(API.updateBagInfo, {
        ...restOfData,
        id: bagId,
      });
    }

    // NOTE: The update operation has been finished successfully.
    //       Now we fetch again the data and update to the redux store.
    const result = yield call(API.getBagList, buildFilterClause({
      id: data.id,
    }));
    yield put(updateBagInfoSuccess(result.data[0]));
    return result;
  } catch (error) {
    yield put(updateBagInfoFailure(error));
    throw (error);
  }
}

function* deleteBag(data) {
  yield put(deleteBagRequest());
  try {
    const result = yield call(API.deleteBag, data);
    yield put(deleteBagSuccess(data));
    return result;
  } catch (error) {
    yield put(deleteBagFailure(error));
    throw (error);
  }
}

function* createBag(data) {
  yield put(createBagRequest());
  try {
    const {
      tags,
      frontPic,
      backPic,
      sidePic,
      bottomPic,
      zipperPic,
      owner,
      ...restOfData
    } = data;
    const {
      data: {
        id: bagId,
      } = {},
    } = yield call(API.createBag, restOfData) || {};
    if (tags) {
      yield call(updateBagInfo, {
        id: bagId,
        tags,
        frontPic,
        backPic,
        sidePic,
        bottomPic,
        zipperPic,
        owner,
      });
    }

    // NOTE: The create operation has been finished successfully.
    //       Now we fetch again the data and update to the redux store.
    const result = yield call(API.getBagList, buildFilterClause({
      id: bagId,
    }));

    yield put(createBagSuccess(result.data[0]));
    return result;
  } catch (error) {
    yield put(createBagFailure(error));
    throw (error);
  }
}

export function* judgeModifyBuyAmount(payload) {
  yield put(judgeModifyBuyAmountRequest());
  try {
    const {
      bagId,
    } = payload;

    yield call(API.modifiedBuyoutAmountJudge, payload);
    const result = yield call(API.getBagList, buildFilterClause({
      id: bagId,
    }));

    yield put(judgeModifyBuyAmountSuccess(result.data[0]));
    return result;
  } catch (error) {
    yield put(judgeModifyBuyAmountFailure(error));
    throw error;
  }
}


// ---------- FLOW ----------
export function* getBagListFlow({ payload }) {
  try {
    const result = yield call(getBagList, payload);
    yield put(getBagListFlowSuccess({
      filterCondition: payload,
      data: result.data,
    }));
  } catch (error) {
    yield put(getBagListFlowFailure(error));
  }
}

export function* getBagNumbersFlow({ payload }) {
  try {
    const result = yield call(getBagNumbers, payload);
    yield put(getBagNumbersFlowSuccess(result.data.count));
  } catch (error) {
    yield put(getBagNumbersFlowFailure(error));
  }
}

export function* getModifiedBuyAmountRequestNumberFlow({ payload }) {
  try {
    const result = yield call(getModifiedBuyAmountRequestNumber, payload);
    yield put(getModifiedBuyAmountRequestNumberFlowSuccess(result.data.count));
  } catch (error) {
    yield put(getModifiedBuyAmountRequestNumberFlowFailure(error));
  }
}

export function* updateBagInfoFlow({ payload }) {
  try {
    const result = yield call(updateBagInfo, payload);
    yield put(updateBagInfoFlowSuccess(result.data));
  } catch (error) {
    yield put(updateBagInfoFlowFailure(error));
  }
}

export function* deleteBagFlow({ payload }) {
  try {
    yield call(deleteBag, payload);
    yield put(deleteBagFlowSuccess(payload));
  } catch (error) {
    yield put(deleteBagFlowFailure(error));
  }
}

export function* createBagFlow({ payload }) {
  try {
    yield call(createBag, payload);
    yield put(createBagFlowSuccess(payload));
  } catch (error) {
    yield put(createBagFlowFailure(error));
  }
}

export function* judgeModifyBuyAmountFlow({ payload }) {
  try {
    const result = yield call(judgeModifyBuyAmount, payload);
    if (result.status === 200) {
      yield put(judgeModifyBuyAmountFlowSuccess());
      return;
    }

    yield put(judgeModifyBuyAmountFlowFailure());
  } catch (error) {
    yield put(judgeModifyBuyAmountFlowFailure());
  }
}

export default [
  takeLatest(getBagListFlowRequest, getBagListFlow),
  takeLatest(getBagNumbersFlowRequest, getBagNumbersFlow),
  takeLatest(getModifiedBuyAmountRequestNumberFlowRequest, getModifiedBuyAmountRequestNumberFlow),
  takeLatest(updateBagInfoFlowRequest, updateBagInfoFlow),
  takeLatest(deleteBagFlowRequest, deleteBagFlow),
  takeLatest(createBagFlowRequest, createBagFlow),
  takeLatest(judgeModifyBuyAmountFlowRequest, judgeModifyBuyAmountFlow),
];
