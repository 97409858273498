import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Table, Input, Button } from 'antd';
import ReduxStatusVisualizedComponent from '../../../../components/ReduxStatusVisualizedComponent';
import OrderInfoComponent from './OrderInfoComponent';

const { Search } = Input;

const styles = {
  mainContainer: {
    margin: 20,
  },
  searchBarStyle: {
    margin: '30px 0',
    width: 300,
  },
  loadMoreButton: {
    margin: '20px 0',
    width: '100%',
  },
};

const columns = [
  {
    title: '訂單編號',
    dataIndex: 'newId',
    width: 150,
  },
  {
    title: '交易型態',
    dataIndex: ['type', 'name'],
    render: name => (name === 'rent' ? '租賃' : '買斷'),
    width: 150,
  },
  {
    title: '包款',
    dataIndex: ['bag', 'name'],
  },
  {
    title: '訂單建立時間',
    dataIndex: 'createDate',
    render: data => `${new Date(data).toLocaleString()}`,
  },
  {
    title: '使用者',
    dataIndex: ['user', 'email'],
  },
  {
    title: '訂單狀態',
    dataIndex: ['orderStatus', 'name'],
  },
];

const OrderManagementPage = ({
  search,
  reduxStatus,
  reduxError,
  data,
  orderStatusList,
  donateOrganizationList,
  receiptTypeList,
  updateOrderInfo,
  createOrder,
  deleteOrder,
  handleLoadMore,
  orderNumber,
}) => {
  const [selectedOrder, setSelectedOrder] = useState(null);

  useEffect(() => {
    setSelectedOrder(null);
  }, [data]);

  const onRow = record => ({
    onClick: () => {
      setSelectedOrder(record);
    },
  });
  const getRowKey = ({ id, name }) => (`${id}.${name}`);
  return (
    <div style={styles.mainContainer} key="baseContainer">
      <Search
        placeholder="搜尋"
        onSearch={value => search(value)}
        enterButton
        size="large"
        style={styles.searchBarStyle}
      />
      <ReduxStatusVisualizedComponent
        reduxStatus={reduxStatus}
        showModalOnError
        modalFailureContent={reduxError}
      >
        <Button onClick={() => { setSelectedOrder({}); }}>
          新增訂單
        </Button>
        {` 已完成訂單筆數 ${orderNumber}`}
        <Table
          columns={columns}
          dataSource={data}
          rowKey={getRowKey}
          onRow={onRow}
          expandRowByClick
          pagination={false}
        />

        <Button
          style={styles.loadMoreButton}
          onClick={handleLoadMore}
          type="primary"
        >
          載入更多訂單
        </Button>
      </ReduxStatusVisualizedComponent>

      <OrderInfoComponent
        receiptTypeList={receiptTypeList}
        donateOrganizationList={donateOrganizationList}
        orderStatusList={orderStatusList}
        orderInfo={selectedOrder}
        updateOrderInfo={updateOrderInfo}
        createOrder={createOrder}
        deleteOrder={deleteOrder}
        cancelSelectOrder={() => setSelectedOrder(null)}
      />
    </div>
  );
};

OrderManagementPage.propTypes = {
  reduxStatus: PropTypes.string.isRequired,
  reduxError: PropTypes.string,
  data: PropTypes.array,
  orderStatusList: PropTypes.array,
  updateOrderInfo: PropTypes.func.isRequired,
  createOrder: PropTypes.func.isRequired,
  search: PropTypes.func.isRequired,
  deleteOrder: PropTypes.func.isRequired,
  donateOrganizationList: PropTypes.array,
  receiptTypeList: PropTypes.array,
  handleLoadMore: PropTypes.func.isRequired,
  orderNumber: PropTypes.number,
};

OrderManagementPage.defaultProps = {
  reduxError: null,
  data: [],
  orderStatusList: [],
  donateOrganizationList: [],
  receiptTypeList: [],
  orderNumber: null,
};

export default OrderManagementPage;
