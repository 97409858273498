import { createActions } from 'redux-actions';
import { createAsyncActionTypesArray } from '../../utils/createAsyncActionTypes';

export default createActions(
  // ----- TASKS -----
  ...createAsyncActionTypesArray('TAPPAY_PAY_BY_TOKEN'),
  // ----- FLOWS -----
  ...createAsyncActionTypesArray('TAPPAY_PAY_BY_TOKEN_FLOW'),

  { prefix: 'PAY_BY_TOKEN_MANAGEMENT' },
);
