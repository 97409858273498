import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ActionCreators from '../../ActionCreator';
import PayByTokenManagementPage from './PayByTokenManagementPage';
import {
  reduxStatusSelector,
  reduxErrorSelector,
} from '../../selector';

const PayByTokenManagementContainer = ({
  reduxStatus,
  reduxError,
  actions: {
    tappayPayByTokenFlowRequest,
  },
}) => {
  const onHandleTappayPayByTokenFlowRequest = (payload) => {
    tappayPayByTokenFlowRequest(payload);
  };


  return (
    <PayByTokenManagementPage
      reduxStatus={reduxStatus}
      reduxError={reduxError}
      onTapPayPayByToken={onHandleTappayPayByTokenFlowRequest}
    />
  );
};

PayByTokenManagementContainer.propTypes = {
  reduxStatus: PropTypes.string,
  reduxError: PropTypes.string,
  actions: PropTypes.shape({
    tappayPayByTokenFlowRequest: PropTypes.func.isRequired,
  }).isRequired,
};

PayByTokenManagementContainer.defaultProps = {
  reduxStatus: null,
  reduxError: null,
};

const mapStateToProps = state => ({
  reduxStatus: reduxStatusSelector(state),
  reduxError: reduxErrorSelector(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    ...ActionCreators,
  }, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PayByTokenManagementContainer);
